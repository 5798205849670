import { useQuery } from "@tanstack/react-query";
import { Layer, Source } from "react-map-gl";
import { runway_color } from "../../../colors";
import getData from "../../../requests/getData";

function ChangeRunwayLayer({ layerId, beforeId, runwayUrl }) {
  const { isError, data, error } = useQuery({
    queryKey: ["runways", runwayUrl],
    queryFn: async () => {
      const data = await getData(runwayUrl);
      return data;
    },
    cacheTime: "Infinity",
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    placeholderData: {
      type: "FeatureCollection",
      features: [],
    },
  });

  if (isError) {
    console.error(error);
    return <></>;
  }

  // Set the source id
  const sourceId = layerId + "-data";

  return (
    <Source id={sourceId} type="geojson" data={data}>
      <Layer
        source={sourceId}
        id={layerId}
        beforeId={beforeId}
        type="fill"
        minzoom={0}
        paint={{
          "fill-color": runway_color, // red color fill
          "fill-opacity": 1,
        }}
      />
    </Source>
  );
}

export default ChangeRunwayLayer;
