import { useQueries } from "@tanstack/react-query";
import booleanPointInPolygon from "@turf/boolean-point-in-polygon";
import { point, polygon } from "@turf/helpers";
import pointToLineDistance from "@turf/point-to-line-distance";
import polygonToLine from "@turf/polygon-to-line";
import i18next from "i18next";
import { useEffect } from "react";
import { Trans } from "react-i18next";
import arrivalIcon from "../../../images/icons/arrival.png";
import departureIcon from "../../../images/icons/departure.png";
import getData from "../../../requests/getData";
import {
  airportCombinations,
  airportPattern,
} from "../../../requests/register";
import "../../../styles/map/MapComponent.css";
import { AltitudeSummary } from "./AltitudeSummary";
import { HighlightTrackButton } from "./HighlightTrackButton";
import Loader from "./Loader";
import { ShowContourButton } from "./ShowContourButton";
import SwitchContourMetric from "./SwitchContourMetric";
import { UsageSummary } from "./UsageSummary";
import { getUniqueTracks } from "./getUniqueTracks";

function AirportChangeSearch({
  lon,
  lat,
  filterSelection,
  setSelectedNonWsiSwathes,
  selectedNonWsiContour,
  setSelectedNonWsiContour,
}) {
  // Get the appropriate register
  const register = airportCombinations;
  const registerPattern = airportPattern;

  // Create the combination id
  let combination_id = registerPattern
    .map((key) => filterSelection[key])
    .join("_");

  // Get the combination
  let combination = register[combination_id];

  // Create a point
  const searchPoint = point([lon, lat]);

  return (
    <SwatheSearch
      searchPoint={searchPoint}
      combination={combination}
      setSelectedNonWsiSwathes={setSelectedNonWsiSwathes}
      selectedNonWsiContour={selectedNonWsiContour}
      setSelectedNonWsiContour={setSelectedNonWsiContour}
    />
  );
}

function SwatheSearch({
  searchPoint,
  combination,
  setSelectedNonWsiSwathes,
  selectedNonWsiContour,
  setSelectedNonWsiContour,
}) {
  // Get the non-WSI swathes to check if the address is under any of them

  // Get the swathe and backbone urls
  const currentTrackUrl = combination["currentTrack"];
  const proposedTrackUrl = combination["proposedTrack"];
  const currentSwatheUrl = combination["currentSwathe"];
  const proposedSwatheUrl = combination["proposedSwathe"];
  const geometryUrls = [
    ["changeTrack", currentTrackUrl],
    ["changeTrack", proposedTrackUrl],
    ["changeSwathe", currentSwatheUrl],
    ["changeSwathe", proposedSwatheUrl],
  ].filter(([, element]) => element !== undefined);

  // Get all the necessary flight data
  const geometryQueries = useQueries({
    queries: geometryUrls.flatMap(([geometryType, geometryUrl]) => {
      return {
        queryKey: [geometryType, geometryUrl],
        queryFn: async () => {
          const data = await getData(geometryUrl);
          return data;
        },
        cacheTime: "Infinity",
        refetchOnMount: false,
        refetchOnWindowFocus: false,
      };
    }),
  });

  console.log(geometryQueries, "geometryQueries");

  if (geometryQueries.some((obj) => obj.isLoading === true)) {
    return <Loader />;
  }

  return (
    <SwatheSearchCheck
      searchPoint={searchPoint}
      combination={combination}
      swathes={geometryQueries.map((obj) => obj.data)}
      setSelectedNonWsiSwathes={setSelectedNonWsiSwathes}
      selectedNonWsiContour={selectedNonWsiContour}
      setSelectedNonWsiContour={setSelectedNonWsiContour}
    />
  );
}

function VectoringAreaSearch({
  searchPoint,
  combination,
  swathes,
  setSelectedNonWsiSwathes,
  selectedNonWsiContour,
  setSelectedNonWsiContour,
}) {
  // Get the non-WSI vectoring areas to check if the address is under any of them

  // Get the vectoring area urls
  const currentVectoringUrl = combination["currentVectoring"];
  const proposedVectoringUrl = combination["proposedVectoring"];
  const vectoringUrls = [currentVectoringUrl, proposedVectoringUrl].filter(
    (element) => element !== undefined
  );

  // Get all the necessary flight data
  const vectoringQueries = useQueries({
    queries: vectoringUrls.flatMap((vectoringUrl) => {
      return {
        queryKey: ["changeVectoring", vectoringUrl],
        queryFn: async () => {
          const data = await getData(vectoringUrl);
          return data;
        },
        cacheTime: "Infinity",
        refetchOnMount: false,
        refetchOnWindowFocus: false,
      };
    }),
  });

  if (vectoringQueries.some((obj) => obj.isLoading === true)) {
    return <Loader />;
  }

  return (
    <VectoringAreaSearchCheck
      searchPoint={searchPoint}
      combination={combination}
      vectorings={vectoringQueries.map((obj) => obj.data)}
      swathes={swathes}
      setSelectedNonWsiSwathes={setSelectedNonWsiSwathes}
      selectedNonWsiContour={selectedNonWsiContour}
      setSelectedNonWsiContour={setSelectedNonWsiContour}
    />
  );
}

function getPolygon(feature) {
  let poly;
  if (feature.geometry.type === "Polygon") {
    poly = feature.geometry.coordinates;
  } else if (feature.geometry.type === "MultiPolygon") {
    poly = feature.geometry.coordinates[0];
  }
  return polygon(poly);
}

function SwatheSearchCheck({
  searchPoint,
  combination,
  swathes,
  setSelectedNonWsiSwathes,
  selectedNonWsiContour,
  setSelectedNonWsiContour,
}) {
  // Check if the address is under any of the non-WSI swathes

  // Get the track ids of the selected features
  const trackProperties = swathes.flatMap((procedureGroup) =>
    procedureGroup.features
      .filter((feature) => feature.geometry.type.endsWith("Polygon"))
      .filter((feature) => {
        const turfPolygon = getPolygon(feature);
        return booleanPointInPolygon(searchPoint, turfPolygon);
      })
      .flatMap((feature) => feature.properties)
  );

  // Not under any of the swathes, check if the address is under any of the vectoring areas
  if (trackProperties.length < 1) {
    return (
      <VectoringAreaSearch
        searchPoint={searchPoint}
        combination={combination}
        swathes={swathes}
        selectedNonWsiContour={selectedNonWsiContour}
        setSelectedNonWsiSwathes={setSelectedNonWsiSwathes}
        setSelectedNonWsiContour={setSelectedNonWsiContour}
      />
    );
  }

  return (
    <SwatheSearchResult
      combination={combination}
      trackProperties={trackProperties}
      setSelectedNonWsiSwathes={setSelectedNonWsiSwathes}
      selectedNonWsiContour={selectedNonWsiContour}
      setSelectedNonWsiContour={setSelectedNonWsiContour}
    />
  );
}

function VectoringAreaSearchCheck({
  searchPoint,
  combination,
  vectorings,
  swathes,
  setSelectedNonWsiSwathes,
  selectedNonWsiContour,
  setSelectedNonWsiContour,
}) {
  // Check if the address is under any of the non-WSI vectoring areas

  // Get the track properties of the selected features
  const trackProperties = vectorings.flatMap((procedureGroup) =>
    procedureGroup.features
      .filter((feature) => {
        const poly = feature.geometry.coordinates[0];
        const turfPolygon = polygon(poly);
        return booleanPointInPolygon(searchPoint, turfPolygon);
      })
      .flatMap((feature) => feature.properties)
  );

  // Not under any of the vectoring areas, check distance to the closest swathe
  if (trackProperties.length < 1) {
    return <NoSearchResult searchPoint={searchPoint} swathes={swathes} />;
  }

  return (
    <VectoringAreaSearchResult
      combination={combination}
      trackProperties={trackProperties}
      setSelectedNonWsiSwathes={setSelectedNonWsiSwathes}
      selectedNonWsiContour={selectedNonWsiContour}
      setSelectedNonWsiContour={setSelectedNonWsiContour}
    />
  );
}

function NoSearchResult({ searchPoint, swathes }) {
  // Get the distance to the non-WSI swathes

  // Get the distances to the selected features
  const trackProperties = swathes.flatMap((procedureGroup) =>
    procedureGroup.features
      .filter((feature) => feature.geometry.type.endsWith("Polygon"))
      .filter((feature) => feature.properties["track_type"] === "Proposed")
      .flatMap((feature) => {
        // Create a polygon
        const turfPolygon = getPolygon(feature);

        // Convert to line
        const turfLine = polygonToLine(turfPolygon);

        // Determine the distance of the selected address to the flight path
        let distance = pointToLineDistance(searchPoint, turfLine, {
          units: "kilometers",
        });

        // Round to 1 decimal
        distance = Math.round(distance * 10) / 10;

        return { distance: distance, ...feature.properties };
      })
  );

  // Get the closest swathe
  const closest = trackProperties.reduce((a, b) =>
    a.distance < b.distance ? a : b
  );

  // Get the properties of the result
  const airportCode = closest["airport"].toLowerCase();

  return (
    <>
      <p className="copy-emphasize">
        <Trans
          i18nKey={"popup.airportChange.underNone.title"}
          values={{
            airport: i18next.t("iata." + airportCode),
          }}
        />
      </p>
      <p className="popup-text">
        {i18next.t("popup.airportChange.underNone.footer")}
      </p>
    </>
  );
}

function SwatheSearchResult({
  combination,
  trackProperties,
  setSelectedNonWsiSwathes,
  selectedNonWsiContour,
  setSelectedNonWsiContour,
}) {
  // Get the properties of the first result
  const properties = trackProperties[0];
  const airportCode = properties["airport"].toLowerCase();
  const flightType = properties["movement_type"].toLowerCase();

  // Highlight the tracks by setting the selected non-WSI swathes
  useEffect(() => {
    // Update the states
    setSelectedNonWsiSwathes(trackProperties);
    // eslint-disable-next-line
  }, [setSelectedNonWsiSwathes]);

  // Get the unique track ids to selected
  const uniqueTracks = getUniqueTracks(trackProperties);

  // Check if contours are available
  const hasContours =
    combination["currentContours"] !== undefined ||
    combination["proposedContours"] !== undefined;

  return (
    <>
      <p className="copy-emphasize">
        <Trans
          i18nKey={"popup.airportChange.underSwathe.title"}
          components={{ italic: <i />, bold: <strong /> }}
          values={{
            airport: i18next.t("iata." + airportCode),
            flightType: flightType,
          }}
        />
      </p>
      <p className="popup-text">
        {i18next.t("popup.airportChange.underSwathe.description")}
      </p>
      <p className="popup-text">
        {
          <Trans
            i18nKey={"popup.airportChange.underSwathe.footer"}
            // eslint-disable-next-line
            components={{ italic: <i />, bold: <strong /> }}
          />
        }
      </p>
      <div className="popup-flight-btn-wrapper">
        {Object.entries(uniqueTracks).map(([k, track]) =>
          hasContours ? (
            <ShowContourButton
              key={k}
              trackId={track.id}
              trackName={track.name}
              selectedNonWsiContour={selectedNonWsiContour}
              setSelectedNonWsiContour={setSelectedNonWsiContour}
            />
          ) : (
            <HighlightTrackButton
              key={k}
              trackId={track.id}
              trackName={track.name}
              selectedNonWsiContour={selectedNonWsiContour}
              setSelectedNonWsiContour={setSelectedNonWsiContour}
            />
          )
        )}
      </div>
      <p className="popup-text">
        {hasContours
          ? i18next.t("popup.airportChange.explainShowContour")
          : i18next.t("popup.airportChange.explainHighlightTrack")}
      </p>
      {hasContours && (
        <SwitchContourMetric
          enabled={selectedNonWsiContour["track_id"] !== null}
          selectedNonWsiContour={selectedNonWsiContour}
          setSelectedNonWsiContour={setSelectedNonWsiContour}
        />
      )}
      {selectedNonWsiContour["track_id"] !== null && (
        <>
          {/* todo: Add usage summary for selected track (#362) */}
          <UsageSummary trackId={selectedNonWsiContour["track_id"]} />
          {/* todo: Add altitude summary for selected track (#362) */}
          <AltitudeSummary trackId={selectedNonWsiContour["track_id"]} />
        </>
      )}
    </>
  );
}
function VectoringAreaSearchResult({
  combination,
  trackProperties,
  setSelectedNonWsiSwathes,
  selectedNonWsiContour,
  setSelectedNonWsiContour,
}) {
  // Get the properties of the first (and only result)
  const properties = trackProperties[0];
  const airport = properties["airport"];
  const flightType = properties["movement_type"].toLowerCase();
  // const trackName = properties["track_id"];
  const flightPathName = properties["Reference Name"];
  const showArrival = flightType === "arrival";

  // Highlight the tracks by setting the selected non-WSI swathes
  useEffect(() => {
    // Update the states
    setSelectedNonWsiSwathes(trackProperties);
    // eslint-disable-next-line
  }, [setSelectedNonWsiSwathes]);

  // Get the unique track ids to selected
  const uniqueTracks = getUniqueTracks(trackProperties);

  // Check if contours are available
  const hasContours =
    combination["currentContours"] !== undefined ||
    combination["proposedContours"] !== undefined;

  return (
    <>
      <p className="copy-emphasize">
        <Trans
          i18nKey={"popup.airportChange.underVector.title"}
          components={{ italic: <i />, bold: <strong /> }}
          values={{
            airport: airport,
            flightType: flightType,
          }}
        />
      </p>
      <div className="popup-icon-text-wrapper">
        {showArrival ? (
          <img
            src={arrivalIcon}
            alt="an icon of a flight coming down."
            className="pop-up-flight-icon"
          />
        ) : (
          <img
            src={departureIcon}
            alt="an icon of a flight taking off."
            className="pop-up-flight-icon"
          />
        )}
        <Trans
          i18nKey={"popup.airportChange.underVector." + flightType}
          values={{
            airport: airport,
            flightPathName: flightPathName,
          }}
        />
      </div>
      <p className="popup-text">
        {i18next.t("popup.airportChange.underVector.description")}
      </p>
      <p className="popup-text">
        {
          <Trans
            i18nKey={"popup.airportChange.underVector.footer"}
            // eslint-disable-next-line
            components={{ italic: <i />, bold: <strong /> }}
          />
        }
      </p>
      <div className="popup-flight-btn-wrapper">
        {Object.entries(uniqueTracks).map(([k, track]) =>
          hasContours ? (
            <ShowContourButton
              key={k}
              trackId={track.id}
              trackName={track.name}
              selectedNonWsiContour={selectedNonWsiContour}
              setSelectedNonWsiContour={setSelectedNonWsiContour}
            />
          ) : (
            <HighlightTrackButton
              key={k}
              trackId={track.id}
              trackName={track.name}
              selectedNonWsiContour={selectedNonWsiContour}
              setSelectedNonWsiContour={setSelectedNonWsiContour}
            />
          )
        )}
      </div>
      <p className="popup-text">
        {hasContours
          ? i18next.t("popup.airportChange.explainShowContour")
          : i18next.t("popup.airportChange.explainHighlightTrack")}
      </p>
      {hasContours && (
        <SwitchContourMetric
          enabled={selectedNonWsiContour["track_id"] !== null}
          selectedNonWsiContour={selectedNonWsiContour}
          setSelectedNonWsiContour={setSelectedNonWsiContour}
        />
      )}
      {selectedNonWsiContour["track_id"] !== null && (
        <>
          {/* todo: Add usage summary for selected track (#362) */}
          <UsageSummary trackId={selectedNonWsiContour["track_id"]} />
          {/* todo: Add altitude summary for selected track (#362) */}
          <AltitudeSummary trackId={selectedNonWsiContour["track_id"]} />
        </>
      )}
    </>
  );
}

export default AirportChangeSearch;
