import licenceImage from "../images/LandingPage/CC BY-NC-ND.png";

function Issues() {
  return (
    <>
      <h2>Troubleshooting Support</h2>
    </>
  );
}

function Legal() {
  return (
    <>
      <p>
        The Aircraft Overflight Noise Tool has been updated with a new report
        generation feature summarising WSI flight path and noise forecasts for a
        specific location in a downloadable PDF file. If no results are shown
        after selecting an address and clicking ‘Generate property report’,
        check your device and browser’s default download location for the
        report. Additional information has been added to support the release of
        the draft Environmental Impact Statement (EIS) for the preliminary
        flight paths for WSI. View the draft EIS at the Online Community Portal{" "}
        <a
          href="https://www.wsiflightpaths.gov.au"
          target="_blank"
          rel="noopener noreferrer"
        >
          wsiflightpaths.gov.au
        </a>
        . View the Changelog to see what has changed. The Changelog can be found
        by clicking on the question mark icon on the bottom left of the tool
        after you click ‘Get Started’ at the bottom of this page. If you are
        having difficulty seeing the updated data you should try clearing your
        device’s browser cache and stored data, and then opening a new browser
        window. Manufacturer instructions to do this for common browser types
        are as follows:{" "}
        <a
          href="https://support.microsoft.com/en-us/microsoft-edge/view-and-delete-browser-history-in-microsoft-edge-00cf7943-a9e1-975a-a33d-ac10ce454ca4"
          target="_blank"
          rel="noreferrer"
        >
          Microsoft Edge
        </a>
        ;{" "}
        <a
          href="https://support.apple.com/en-au/guide/iphone/iphacc5f0202/15.0/ios/15.0"
          target="_blank"
          rel="noreferrer"
        >
          Safari (iPhone)
        </a>
        ;{" "}
        <a
          href="https://support.apple.com/en-au/guide/safari/sfri47acf5d6/mac"
          target="_blank"
          rel="noreferrer"
        >
          Safari (Mac)
        </a>
        ;{" "}
        <a
          href="https://support.google.com/accounts/answer/32050?hl=en&amp;co=GENIE.Platform=Desktop"
          target="_blank"
          rel="noreferrer"
        >
          Google Chrome
        </a>
        ;{" "}
        <a
          href="https://support.mozilla.org/en-US/kb/how-clear-firefox-cache"
          target="_blank"
          rel="noreferrer"
        >
          Firefox.
        </a>
      </p>
      <h2>Terms of Use</h2>
      <p>
        This tool reflects preliminary flights paths for the single runway
        operations at WSI which are included in the draft EIS. These preliminary
        flight paths and associated noise contours are different to those which
        have been released previously. The actual flight paths, aircraft
        movements, noise contours and actual aircraft noise over specific
        addresses may be different to that shown in the tool.
      </p>
      <p>
        The information communicated in the tool is for consultation purposes
        only. There is additional information in the draft EIS that may be
        relevant to flight paths and noise. The information is not in the nature
        of advice and should not be relied upon for personal, legal or financial
        decisions. You should make your own inquiries and seek independent
        advice in relation to any such personal, legal or financial decisions.
        As the tool is for consultation on preliminary flight paths, the
        Commonwealth accepts no legal liability for any loss or damage arising
        from the use of, or reliance upon, the information accessed or
        communicated via the tool.
      </p>
      <h2>Privacy Statement</h2>
      <p>
        The Department of Infrastructure, Transport, Regional Development,
        Communications and the Arts (the Department) is displaying the
        preliminary flight paths for Western Sydney International (Nancy-Bird
        Walton) Airport via an online Aircraft Overflight Noise Tool.
      </p>
      <p>
        To use the Aircraft Overflight Noise Tool, you can input address details
        to view the preliminary flight paths for the airport and forecast
        aircraft noise exposure levels in relation to a property or place. The
        Department will collect your postcode information for the purpose of
        understanding public interest in the preliminary flight paths. The
        Department will store this information securely. This data will be
        collected and stored in accordance with the{" "}
        <cite>Privacy Act 1988</cite> (Cth).
      </p>
      <p>
        The Department’s{" "}
        <a href="http://www.infrastructure.gov.au/department/about/privacy-policy.aspx">
          privacy policy
        </a>{" "}
        contains information regarding complaint handling processes and how to
        access and/or seek correction of personal information held by the
        Department. The{" "}
        <a href="mailto:privacy@infrastructure.gov.au">Privacy Officer</a> can
        be contacted on (02) 6274 6495 or by email:{" "}
        <a href="mailto:privacy@infrastructure.gov.au">
          privacy@infrastructure.gov.au
        </a>
        .
      </p>
      <h2>Creative Commons licence</h2>
      <p>
        <b>
          With the exception of (a) the Coat of Arms; and (b) any third party
          material, and where otherwise stated, copyright in the material,
          content, information, data and any work or other subject matter
          protected by copyright that is communicated by use of the noise tool
          (Content) is licensed under a Creative Commons
          Attribution-NonCommercial-NoDerivs 3.0 Australia Licence (CC BY-NC-ND
          Licence).
        </b>
      </p>
      <p>
        <img
          src={licenceImage}
          alt="Creative Commons Attribution-NonCommercial-NoDerivs 3.0 Australia Licence (CC BY-NC-ND Licence)"
        />
      </p>
      <p>
        The CC BY-NC-ND Licence allows you to copy and distribute this Content
        in any medium or format in unadapted form only, for non-commercial
        purposes only, provided that you attribute the Content to the
        Commonwealth, and that you abide by the other licence terms:
        <br />
        <a href="https://creativecommons.org/licenses/by-nc-nd/3.0/au/">
          https://creativecommons.org/licenses/by-nc-nd/3.0/au/
        </a>
        .
      </p>
      <p>
        Further information on the licence terms is available from{" "}
        <a href="https://creativecommons.org/licenses/by-nc-nd/3.0/au/legalcode">
          https://creativecommons.org/licenses/by-nc-nd/3.0/au/legalcode
        </a>
      </p>
      <p>
        The Content communicated using the noise tool should be attributed in
        the following way:
      </p>
      <blockquote>© Commonwealth of Australia 2023</blockquote>
      <p>
        For enquiries regarding the licence terms and any use of the Content,
        please contact:
      </p>
      <blockquote>
        Director – Creative Services
        <br />
        Communication Branch
        <br />
        Department of Infrastructure, Transport, Regional Development,
        Communications and the Arts
        <br />
        GPO Box 594
        <br />
        Canberra ACT 2601
        <br />
        Australia
      </blockquote>
      <blockquote>
        Email:{" "}
        <a href="mailto:WSIflightpaths@infrastructure.gov.au">
          WSIflightpaths@infrastructure.gov.au
        </a>
      </blockquote>
      <blockquote>
        Website:{" "}
        <a href="https://wsiflightpaths.gov.au">wsiflightpaths.gov.au</a>{" "}
      </blockquote>
    </>
  );
}

export { Issues, Legal };
