import { useQuery } from "@tanstack/react-query";
import { Layer, Source } from "react-map-gl";
import {
  current_swathe_color,
  current_swathe_opacity,
  proposed_swathe_color,
  proposed_swathe_opacity,
} from "../../../colors";
import getData from "../../../requests/getData";

function ChangeSwatheLayer({
  layerId,
  beforeId,
  swatheUrl,
  changeType,
  selectedNonWsiSwathes,
  selectedNonWsiContour,
  hasContours,
}) {
  const currentType = changeType === "current";
  const proposedType = changeType === "proposed";

  // Get the selected contour
  const contourId = selectedNonWsiContour["track_id"];

  // Get the selected track ids
  const selectedTrackIds = selectedNonWsiSwathes
    .map((f) => f["track_id"])
    .filter((track_id) => contourId === null || contourId === track_id);

  if (!(currentType || proposedType)) {
    console.error(
      changeType +
        " (changeType) is not valid. It should be 'current' or 'proposed'."
    );
  }

  // Get the colors
  const swathe_color = currentType
    ? current_swathe_color
    : proposed_swathe_color;

  // Get the opacities
  const swathe_opacities = currentType
    ? current_swathe_opacity
    : proposed_swathe_opacity;
  const selected_swathe_opacity =
    contourId !== null
      ? hasContours
        ? swathe_opacities[3]
        : swathe_opacities[4]
      : swathe_opacities[1];
  const default_swathe_opacity =
    selectedNonWsiSwathes.length > 0
      ? swathe_opacities[2]
      : swathe_opacities[0];

  // Get the swathe data
  const { isError, data, error } = useQuery({
    queryKey: ["changeSwathe", swatheUrl],
    queryFn: async () => {
      const data = await getData(swatheUrl);
      return data;
    },
    cacheTime: "Infinity",
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    placeholderData: {
      type: "FeatureCollection",
      features: [],
    },
    onError: (error) => {
      console.error(error);
    },
  });

  if (isError) {
    console.error(error);
    return <></>;
  }

  // Set the source id
  const sourceId = layerId + "-data";

  return (
    <Source id={sourceId} type="geojson" data={data}>
      <Layer
        source={sourceId}
        id={layerId}
        beforeId={beforeId}
        type="fill"
        paint={{
          "fill-color": [
            "case",
            ["==", ["get", "movement_type"], ["literal", "Arrival"]],
            swathe_color["arrival"],
            swathe_color["departure"],
          ],
          "fill-opacity": [
            "case",
            [
              "boolean",
              ["in", ["get", "track_id"], ["literal", selectedTrackIds]],
              false,
            ],
            selected_swathe_opacity,
            default_swathe_opacity,
          ],
        }}
      />
    </Source>
  );
}

export default ChangeSwatheLayer;
