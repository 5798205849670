import booleanPointInPolygon from "@turf/boolean-point-in-polygon";
import pointToLineDistance from "@turf/point-to-line-distance";
import { point, lineString } from "@turf/helpers";
import {
  trackCombinations,
  altitudeSegments,
} from "../../../requests/register";
import getData from "../../../requests/getData";
import { useQueries } from "@tanstack/react-query";
import i18next from "i18next";
import { Trans } from "react-i18next";
import filterConfigurations from "../../../static-data/filter_data.json";
// styling imports
import "../../../styles/map/MapComponent.css";
import arrivalIcon from "../../../images/icons/arrival.png";
import departureIcon from "../../../images/icons/departure.png";
import playIcon from "../../../images/popups/playIcon.svg";
import Loader from "./Loader";

function filterFeatures(data, layerPattern) {
  if (data === null) {
    return null;
  }

  let filteredFeatures = data.features.filter((feature) => {
    return feature.properties.layer.endsWith(layerPattern);
  });

  if (filteredFeatures.length === 0) {
    return null;
  }

  let newData = {
    ...data,
    features: filteredFeatures,
  };

  return newData;
}

function FlightSearch({
  lon,
  lat,
  filterSelection,
  setVideoOpen,
  findVideoUrl,
  selectedArrivalVectoring,
  selectedDepartureVectoring,
}) {
  // Get all the necessary flight data
  const trackQueries = useQueries({
    queries: Object.entries(trackCombinations).flatMap(([, v]) =>
      Object.entries(v)
        .filter(
          ([flightType]) =>
            flightType === "departures" || flightType === "arrivals"
        )
        .flatMap(([, tracksUrl]) => {
          return {
            queryKey: ["tracks", tracksUrl],
            queryFn: async () => {
              const data = await getData(tracksUrl);
              return data;
            },
            cacheTime: "Infinity",
            refetchOnMount: false,
            refetchOnWindowFocus: false,
          };
        })
    ),
  });

  if (trackQueries.some((obj) => obj.isLoading === true)) {
    return <Loader />;
  }

  return (
    <FlightSearchInternals
      lon={lon}
      lat={lat}
      filterSelection={filterSelection}
      setVideoOpen={setVideoOpen}
      findVideoUrl={findVideoUrl}
      selectedArrivalVectoring={selectedArrivalVectoring}
      selectedDepartureVectoring={selectedDepartureVectoring}
      tracks={trackQueries.map((obj) => obj.data)}
    />
  );
}
function FlightSearchInternals({
  lon,
  lat,
  filterSelection,
  setVideoOpen,
  findVideoUrl,
  selectedArrivalVectoring,
  selectedDepartureVectoring,
  tracks,
}) {
  // Set the default/initial values
  let initialInfo = {
    overflightsObject: {},
    overflights: { arrivals: [], departures: [] },
    modeOverflights: { arrivals: [], departures: [] },
    avgMovements: { arrivals: 0, departures: 0 },
    maxMovements: { arrivals: 0, departures: 0 },
    closestFlightpaths: { arrivals: [], departures: [] },
    closestDistances: { arrivals: 99999, departures: 99999 },
    addedNestedTracks: [],
    boundChecks: [],
  };

  const checkOperatingMode = (TrackOperatingMode) => {
    const trackModeDetails = TrackOperatingMode.split("_");
    const trackModeRunway = trackModeDetails[2];
    const trackModePeriod = trackModeDetails[1];
    return (
      filterSelection.time === trackModePeriod &&
      filterSelection.runway.includes(trackModeRunway)
    );
  };

  const addVectoringAreaMovements = (
    vectoringAreas,
    flightType,
    currentInfo
  ) => {
    if (vectoringAreas !== null) {
      currentInfo.avgMovements[flightType] +=
        vectoringAreas.features[0].properties.movements_average;
      currentInfo.maxMovements[flightType] +=
        vectoringAreas.features[0].properties.movements_max;
      currentInfo.addedNestedTracks.push(
        vectoringAreas.features[0].properties.nested_track
      );
    }
    return currentInfo;
  };

  // Add additional filter to only include vectoring areas that belong to the selected filter
  const layerPattern = `_${filterSelection["time"]}_${filterSelection["runway"]}`;
  let filteredArrivalVectoring = filterFeatures(
    selectedArrivalVectoring,
    layerPattern
  );
  let filteredDepartureVectoring = filterFeatures(
    selectedDepartureVectoring,
    layerPattern
  );

  // Add movements from vectoring areas
  initialInfo = addVectoringAreaMovements(
    filteredArrivalVectoring,
    "arrivals",
    initialInfo
  );
  initialInfo = addVectoringAreaMovements(
    filteredDepartureVectoring,
    "departures",
    initialInfo
  );

  const turfPoint = point([lon, lat]);

  // Iterate over all tracks
  let finalInfo = tracks.reduce((currentInfo, track) => {
    let initialTrackInfo = {
      closestDist: 99999,
      closestPaths: [],
      overflightsLocal: [],
      modeOverflightsLocal: [],
      flightType: null,
      avgMovements: 0,
      maxMovements: 0,
      addedNestedTracks: currentInfo.addedNestedTracks,
      boundChecks: currentInfo.boundChecks,
    };

    let finalTrackInfo = track.features.reduce((currentTrackInfo, feature) => {
      const poly = feature.geometry.coordinates[0];
      const subtrackId = feature.properties.subtrack_id;
      const name = feature.properties.reference_name;
      const average = feature.properties.movements_average;
      const max = feature.properties.movements_max;
      const nestedTrack = feature.properties.nested_track;
      const operatingMode = feature.properties.layer;
      const operatingModeSplit = operatingMode.split("_");
      const turfLine = lineString(poly);
      const turfPolygon = feature;
      const flightType = operatingModeSplit[0];

      currentTrackInfo.flightType = flightType;

      // Check if selected address is under the swathe of the flight path
      if (booleanPointInPolygon(turfPoint, turfPolygon)) {
        currentTrackInfo.closestDist = 0;
        currentTrackInfo.overflightsLocal.push(name);
        currentInfo.overflightsObject[subtrackId] = name;

        // Add flights currently visible to a separate variable
        if (checkOperatingMode(operatingMode)) {
          currentTrackInfo.modeOverflightsLocal.push(name);
        }

        // Only count movements if track is not nested with other tracks or, for the tested
        // tracks, if the movements of the groups haven't been counted yet
        if (
          (nestedTrack === null ||
            (nestedTrack !== null &&
              currentInfo.addedNestedTracks.indexOf(nestedTrack) === -1)) &&
          checkOperatingMode(operatingMode)
        ) {
          currentTrackInfo.avgMovements += average;
          currentTrackInfo.maxMovements += max;
          // Add nested group to the list of added groups
          if (nestedTrack !== null) {
            currentTrackInfo.addedNestedTracks.push(nestedTrack);
          }
        }
        // Only check altitude segments for the current operating mode
        if (checkOperatingMode(operatingMode)) {
          // Get altitude segments for the current track
          const segmentsUrl = altitudeSegments[`subtrack_id_${subtrackId}`];

          // Add the combination to the list for further processing
          currentTrackInfo.boundChecks.push({
            flightType: flightType,
            turfPoint: turfPoint,
            segmentsUrl: segmentsUrl,
          });
        }
      } else if (
        currentTrackInfo.closestDist > 0 &&
        checkOperatingMode(operatingMode)
      ) {
        // Determine the distance of the selected address to the flight path
        let currentDistance = pointToLineDistance(turfPoint, turfLine, {
          units: "kilometers",
        });
        // Round to 1 decimal
        currentDistance = Math.round(currentDistance * 10) / 10;
        // If it's closer than other flight paths, mark it as the closest
        if (currentDistance < currentTrackInfo.closestDist) {
          currentTrackInfo.closestDist = currentDistance;
          currentTrackInfo.closestPaths = [name];
          // Or add it to the list if it's as close as the other
        } else if (currentDistance === currentTrackInfo.closestDist) {
          currentTrackInfo.closestPaths.push(name);
        }
      }
      return currentTrackInfo;
    }, initialTrackInfo);

    const flightType = finalTrackInfo.flightType;

    // Update the movement counts
    currentInfo.avgMovements[flightType] += finalTrackInfo.avgMovements;
    currentInfo.maxMovements[flightType] += finalTrackInfo.maxMovements;

    // Update the overflights for flightType
    currentInfo.overflights[flightType] = [
      ...currentInfo.overflights[flightType],
      ...finalTrackInfo.overflightsLocal,
    ];
    // Update the overflights of the current mode for flightType
    currentInfo.modeOverflights[flightType] = [
      ...currentInfo.modeOverflights[flightType],
      ...finalTrackInfo.modeOverflightsLocal,
    ];
    if (currentInfo.modeOverflights[flightType].length === 0) {
      if (
        finalTrackInfo.closestDist < currentInfo.closestDistances[flightType]
      ) {
        currentInfo.closestDistances[flightType] = finalTrackInfo.closestDist;
        currentInfo.closestFlightpaths[flightType] =
          finalTrackInfo.closestPaths;
      }
    }

    // Update the overflights, closest distances, altitude and movements information
    return {
      overflightsObject: currentInfo.overflightsObject,
      overflights: currentInfo.overflights,
      modeOverflights: currentInfo.modeOverflights,
      avgMovements: currentInfo.avgMovements,
      maxMovements: currentInfo.maxMovements,
      closestDistances: currentInfo.closestDistances,
      closestFlightpaths: currentInfo.closestFlightpaths,
      addedNestedTracks: finalTrackInfo.addedNestedTracks,
      boundChecks: finalTrackInfo.boundChecks,
    };
  }, initialInfo);

  return (
    <FlightSearchComponent
      filterSelection={filterSelection}
      setVideoOpen={setVideoOpen}
      findVideoUrl={findVideoUrl}
      selectedArrivalVectoring={filteredArrivalVectoring}
      selectedDepartureVectoring={filteredDepartureVectoring}
      boundChecks={finalInfo.boundChecks}
      overflightsObject={finalInfo.overflightsObject}
      avgMovements={finalInfo.avgMovements}
      maxMovements={finalInfo.maxMovements}
      closestDistances={finalInfo.closestDistances}
      modeOverflights={finalInfo.modeOverflights}
      closestFlightpaths={finalInfo.closestFlightpaths}
      overflights={finalInfo.overflights}
    />
  );
}

function FlightSearchComponent({
  filterSelection,
  setVideoOpen,
  findVideoUrl,
  selectedArrivalVectoring,
  selectedDepartureVectoring,
  boundChecks,
  overflightsObject,
  avgMovements,
  maxMovements,
  closestDistances,
  modeOverflights,
  closestFlightpaths,
  overflights,
}) {
  const checkClosestAltitudeSegment = (turfPoint, trackAltitudeSegments) => {
    let closestDistance = 99999;
    let closestLabel = null;
    let closestLabelNext = null;

    // Check all altitude segments linked to the track
    trackAltitudeSegments.features.forEach((segment) => {
      let currentDistance = pointToLineDistance(turfPoint, segment, {
        units: "kilometers",
      });

      // If the distance from the selected point to the current altitude segment is shorter than
      // the current minimum distance, it is assumed as the closest distance for this track
      if (currentDistance <= closestDistance) {
        closestLabel = segment.properties.value;
        closestLabelNext = segment.properties.next_value;
        closestDistance = currentDistance;
      }
    });

    return [Number(closestLabel), Number(closestLabelNext)];
  };

  // Get all the necessary flight data
  const segmentQueries = useQueries({
    queries: boundChecks.map((bound) => {
      return {
        queryKey: ["segments", bound.segmentsUrl],
        queryFn: async () => {
          const data = await getData(bound.segmentsUrl);
          return data;
        },
        cacheTime: "Infinity",
        refetchOnMount: false,
        refetchOnWindowFocus: false,
      };
    }),
  });

  if (segmentQueries.some((obj) => obj.isLoading === true)) {
    return <Loader />;
  }

  let lowerBoundFt = { arrivals: 99999, departures: 99999 };
  let upperBoundFt = { arrivals: 99999, departures: 99999 };

  boundChecks.forEach((boundCheck, index) => {
    const boundCheckQuery = segmentQueries[index];

    // Find closest altitude segment to the selected point
    const closestAlt = checkClosestAltitudeSegment(
      boundCheck.turfPoint,
      boundCheckQuery.data
    );

    const lowerBound = closestAlt[0];
    const upperBound = closestAlt[1];

    // If the altitude of the current track is lower than the current minimum, it is
    // assumed to be the global lowest
    if (lowerBound < lowerBoundFt[boundCheck.flightType]) {
      lowerBoundFt[boundCheck.flightType] = lowerBound;
      upperBoundFt[boundCheck.flightType] = upperBound;
    }
  });

  // Get the time component
  const time = i18next.t(
    filterConfigurations.time.options[filterSelection.time].title
  );

  // Determine the type of popup content
  let overflightType = "underNone";
  let flightType;
  let flightPathName;
  if (
    modeOverflights["arrivals"].length === 1 &&
    modeOverflights["departures"].length === 0 &&
    modeOverflights["arrivals"][0].includes("Hot")
  ) {
    overflightType = "underSingleHot";
  } else if (
    modeOverflights["departures"].length === 1 &&
    modeOverflights["arrivals"].length === 0 &&
    modeOverflights["departures"][0].includes("Hot")
  ) {
    overflightType = "underSingleHot";
  } else if (
    modeOverflights["arrivals"].length > 0 &&
    modeOverflights["departures"].length > 0
  ) {
    overflightType = "underMulti";
  } else if (modeOverflights["arrivals"].length > 0) {
    overflightType = "underSingle";
    flightType = "arrival";
  } else if (modeOverflights["departures"].length > 0) {
    overflightType = "underSingle";
    flightType = "departure";
  } else if (selectedArrivalVectoring !== null) {
    flightPathName =
      selectedArrivalVectoring.features[0].properties.reference_name;
    overflightType = "underVector";
    flightType = "arrival";
  } else if (selectedDepartureVectoring !== null) {
    flightPathName =
      selectedDepartureVectoring.features[0].properties.reference_name;
    overflightType = "underVector";
    flightType = "departure";
  } else if (
    overflights["arrivals"].length > 0 ||
    overflights["departures"].length > 0
  ) {
    overflightType = "underOther";
  }

  // Determine the arrival/departure content
  let arrivalContent, departureContent;
  if (modeOverflights["arrivals"].length > 0) {
    arrivalContent = "arrival";
  }
  if (modeOverflights["departures"].length > 0) {
    departureContent = "departure";
  }

  let underArrivalsText = "popup.flightpaths.underArrival";
  if (lowerBoundFt["arrivals"] === upperBoundFt["arrivals"]) {
    underArrivalsText = underArrivalsText + ".last";
  }

  let underDeparturesText = "popup.flightpaths.underDeparture";
  if (lowerBoundFt["departures"] === upperBoundFt["departures"]) {
    underDeparturesText = underDeparturesText + ".last";
  }

  // Determine the closest flight path
  const [[closestFlighttype, closestDistance]] = Object.entries(
    closestDistances
  ).sort(function ([, valA], [, valB]) {
    return valA - valB;
  });
  const closestFlightpath = closestFlightpaths[closestFlighttype];

  return (
    <>
      <p className="copy-emphasize">
        <Trans
          i18nKey={"popup.flightpaths." + overflightType + ".title"}
          values={{
            flightType: flightType,
          }}
          components={{ italic: <i />, bold: <strong /> }}
        />
      </p>
      {arrivalContent && (
        <div className="popup-icon-text-wrapper">
          <img
            src={arrivalIcon}
            alt="an airplane descending."
            className="pop-up-flight-icon"
          />
          <p className="popup-paragraph">
            <Trans
              i18nKey={underArrivalsText}
              values={{
                lowerBoundFt: lowerBoundFt["arrivals"],
                lowerBoundM: Math.round(lowerBoundFt["arrivals"] * 0.3048),
                upperBoundFt: upperBoundFt["arrivals"],
                upperBoundM: Math.round(upperBoundFt["arrivals"] * 0.3048),
              }}
            />
          </p>
        </div>
      )}
      {departureContent && (
        <div className="popup-icon-text-wrapper">
          <img
            src={departureIcon}
            alt="an airplane ascending."
            className="pop-up-flight-icon"
          />

          <p className="popup-paragraph">
            <Trans
              i18nKey={underDeparturesText}
              values={{
                lowerBoundFt: lowerBoundFt["departures"],
                lowerBoundM: Math.round(lowerBoundFt["departures"] * 0.3048),
                upperBoundFt: upperBoundFt["departures"],
                upperBoundM: Math.round(upperBoundFt["departures"] * 0.3048),
              }}
            />
          </p>
        </div>
      )}
      {overflightType === "underSingle" && (
        <p className="copy-emphasize">
          <Trans
            i18nKey="popup.flightpaths.underSingle.summary"
            values={{
              flightType: flightType,
              time: time,
              movements: avgMovements[`${flightType}s`],
              maxMovements: maxMovements[`${flightType}s`],
            }}
            components={{ italic: <i />, bold: <strong /> }}
          />
        </p>
      )}
      {overflightType === "underMulti" && (
        <p className="copy-emphasize">
          {
            <Trans
              i18nKey={"popup.flightpaths.underMulti.summary"}
              values={{
                flightType: flightType,
                time: time,
                departures: avgMovements["departures"],
                arrivals: avgMovements["arrivals"],
                maxDepartures: maxMovements["departures"],
                maxArrivals: maxMovements["arrivals"],
              }}
              components={{ italic: <i />, bold: <strong /> }}
            />
          }
        </p>
      )}
      {overflightType === "underVector" && (
        <>
          <p className="copy-emphasize">
            {i18next.t(`popup.flightpaths.underVector.${flightType}`, {
              flightPathName: flightPathName,
            })}
          </p>
          <p className="copy-emphasize">
            {
              <Trans
                i18nKey={"popup.flightpaths.underVector.summary"}
                values={{
                  flightType: flightType,
                  time: time,
                  movements: avgMovements[`${flightType}s`],
                  maxMovements: maxMovements[`${flightType}s`],
                }}
                components={{ italic: <i />, bold: <strong /> }}
              />
            }
          </p>
        </>
      )}
      {overflightType === "underOther" && (
        <p className="popup-text">
          {i18next.t(`popup.flightpaths.underOther.footer`)}
        </p>
      )}
      {overflightType === "underSingleHot" && (
        <p className="copy-emphasize">
          {i18next.t("popup.flightpaths.underSingleHot.summary")}
        </p>
      )}
      {/* scrollable part */}
      <div className="popup-flight-wrapper">
        {overflightType !== "underNone" && (
          <>
            <p className="popup-text">
              {i18next.t("popup.flightpaths.disclaimer")}
            </p>
            {Object.keys(overflightsObject).length !== 0 && (
              <>
                <p className="popup-text">
                  {
                    <Trans
                      i18nKey={"popup.flightpaths.videos.title"}
                      components={{ italic: <i />, bold: <strong /> }}
                    />
                  }
                </p>
                <div className="popup-flight-btn-wrapper">
                  {Object.entries(overflightsObject).map(
                    ([k, flightpathName]) => (
                      <button
                        key={k}
                        className="popup-flight-btn"
                        onClick={() => {
                          setVideoOpen(true);
                          findVideoUrl(k);
                        }}
                      >
                        <img
                          src={playIcon}
                          alt="video play icon. Basically a triangle that has been rotated to right by 90 degrees."
                          className="popup-play-icon"
                        />
                        <div className="w-100">{flightpathName}</div>
                      </button>
                    )
                  )}
                </div>
                <p className="popup-text">
                  {i18next.t("popup.flightpaths.videos.footer")}
                </p>
              </>
            )}
          </>
        )}
        {overflightType === "underNone" && (
          <>
            <p className="popup-text">
              {i18next.t("popup.flightpaths.underNone.closestPath", {
                distanceKm: closestDistance,
                closestPath: closestFlightpath,
              })}
            </p>
            <p className="popup-text">
              {i18next.t("popup.flightpaths.underNone.footer")}
            </p>
          </>
        )}
      </div>
    </>
  );
}

export default FlightSearch;
