import React from "react";
import { Trans } from "react-i18next";
// styling imports
import "../../styles/Filters.css";
import FilterItem from "./FilterItem";
import { airportCombinations, airportPattern } from "../../requests/register";

function FlightFilterGroup({
  filterConfigurations,
  filterSelection,
  setFilterSelection,
  activeIndex,
  toggleText,
  setSelectedNonWsiSwathes,
  setSelectedNonWsiContour,
}) {
  function configureFilter(filterName, filterValue) {
    const filterSelectionCopy = { ...filterSelection };
    filterSelectionCopy[filterName] = filterValue;
    setFilterSelection(filterSelectionCopy);
    setSelectedNonWsiSwathes([]);
    setSelectedNonWsiContour({ track_id: null, metric: "N60" });
  }

  function configureAirportChange(filterValue) {
    // Get the configuration of the selected airportChange
    let airportChangeConfig =
      filterConfigurations.airportChange.options[filterValue];

    if (airportChangeConfig.hasOwnProperty("defaultProcedureGroup")) {
      const filterSelectionCopy = { ...filterSelection };
      filterSelectionCopy["airportChange"] = filterValue;
      filterSelectionCopy["procedureGroup"] =
        airportChangeConfig.defaultProcedureGroup;
      setFilterSelection(filterSelectionCopy);
      setSelectedNonWsiSwathes([]);
      setSelectedNonWsiContour({ track_id: null, metric: "N60" });
    } else {
      console.error(
        filterValue +
          " does not have defaultProcedureGroup in its configuration"
      );
    }
  }

  return (
    <>
      <p className="info-persistent">
        <Trans
          i18nKey="filters.airportChange.info.persistent"
          // eslint-disable-next-line
          components={{ hyperlink: <a /> }}
        />
      </p>
      <FilterItem
        filterKey={"airportChange"}
        configuration={filterConfigurations["airportChange"]}
        selectValue={configureAirportChange}
        filterSelection={filterSelection}
        filterCombinations={airportCombinations}
        filterCombinationsPattern={airportPattern}
        enabled={true}
        forceEnabled={true}
        isActive={activeIndex === 3}
        toggleText={() => toggleText(3)}
      />
      <FilterItem
        filterKey={"procedureGroup"}
        configuration={filterConfigurations["procedureGroup"]}
        selectValue={(v) => configureFilter("procedureGroup", v)}
        filterSelection={filterSelection}
        filterCombinations={airportCombinations}
        filterCombinationsPattern={airportPattern}
        enabled={true}
        isActive={activeIndex === 3}
        toggleText={() => toggleText(3)}
        hideWhenDisabled={true}
        customClassName={"procedure-group"}
      />
    </>
  );
}

export default FlightFilterGroup;
