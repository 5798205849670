import React from "react";
import WsiTracksPopup from "./contents/WsiTracksPopup";
import NonWsiTracksPopup from "./contents/NonWsiTracksPopup";

function TracksPopup({
  handleClosePopupTracks,
  selectedArrivals,
  selectedDepartures,
  setVideoOpen,
  findVideoUrl,
  mapType,
  selectedNonWsiSwathes,
  selectedNonWsiContour,
  setSelectedNonWsiContour,
}) {
  // Determine which content to show
  const showAirportChanges = mapType === "airport";
  const showFlight = mapType === "flight";

  if (showAirportChanges) {
    return (
      <NonWsiTracksPopup
        handleClosePopupTracks={handleClosePopupTracks}
        selectedNonWsiSwathes={selectedNonWsiSwathes}
        selectedNonWsiContour={selectedNonWsiContour}
        setSelectedNonWsiContour={setSelectedNonWsiContour}
      />
    );
  }

  if (showFlight) {
    return (
      <WsiTracksPopup
        handleClosePopupTracks={handleClosePopupTracks}
        selectedArrivals={selectedArrivals}
        selectedDepartures={selectedDepartures}
        setVideoOpen={setVideoOpen}
        findVideoUrl={findVideoUrl}
      />
    );
  }

  return <></>;
}

export default TracksPopup;
