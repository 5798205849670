import React from "react";
// styling imports
import "../../styles/Filters.css";
import FilterItem from "./FilterItem";
import { trackCombinations, trackPattern } from "../../requests/register";
import { Trans } from "react-i18next";

function FlightFilterGroup({
  filterConfigurations,
  filterSelection,
  setFilterSelection,
  activeIndex,
  toggleText,
  setSelectedArrivals,
  setSelectedDepartures,
  setSelectedArrivalVectoring,
  setSelectedDepartureVectoring
}) {
  function configureFilter(filterName, filterValue) {
    const filterSelectionCopy = { ...filterSelection };
    filterSelectionCopy[filterName] = filterValue;
    setFilterSelection(filterSelectionCopy);
    setSelectedArrivals([]);
    setSelectedDepartures([]);
    setSelectedArrivalVectoring(null);
    setSelectedDepartureVectoring(null);
  }

  return (
    <>
      <p className="info-persistent">
        <Trans
          i18nKey="filters.flightpaths.info.persistent"
          // eslint-disable-next-line
          components={{ hyperlink: <a />, italic: <i />, bold: <strong /> }}
        />
      </p>

      <FilterItem
        filterKey={"runway"}
        configuration={filterConfigurations.runway}
        selectValue={(v) => configureFilter("runway", v)}
        filterSelection={filterSelection}
        filterCombinations={trackCombinations}
        filterCombinationsPattern={trackPattern}
        enabled={true}
        isActive={activeIndex === 1}
        toggleText={() => toggleText(1)}
      />

      <FilterItem
        filterKey={"time"}
        configuration={filterConfigurations.time}
        selectValue={(v) => configureFilter("time", v)}
        filterSelection={filterSelection}
        filterCombinations={trackCombinations}
        filterCombinationsPattern={trackPattern}
        enabled={true}
        isActive={activeIndex === 2}
        toggleText={() => toggleText(2)}
      />
    </>
  );
}

export default FlightFilterGroup;
