import React from "react";
import { Text } from "@react-pdf/renderer";
import styles from "../../documentStyles";

const PageFooter = () => {
  const options = {
    timeZone: "Etc/GMT-10",
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  };

  const currentTime = new Date().toLocaleString("en-AU", options);

  return (
    <>
      <Text style={styles.footerWrapper} fixed>
        Generated on {currentTime} AEST
      </Text>
    </>
  );
};

export default PageFooter;
