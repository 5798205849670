import { StyleSheet, Font } from "@react-pdf/renderer";
import MulishBold from "../../../fonts/mulish/Mulish-Bold.ttf";
import MulishRegular from "../../../fonts/mulish/Mulish-Regular.ttf";
import MulishItalic from "../../../fonts/mulish/Mulish-Italic.ttf";

export const noiseTableCellWidth = "25%";
export const trackTableCellWidth = "50%";

Font.register({
  family: "Mulish",
  fonts: [
    {
      src: MulishRegular,
    },
    {
      src: MulishItalic,
      fontStyle: "italic",
    },
    {
      src: MulishBold,
      fontWeight: "bold",
    },
  ],
});

Font.registerHyphenationCallback((word) => {
  // Return entire word as unique part
  return [word];
});

const spacings = {
  small: "7px",
  medium: "13px",
  large: "18px",
};

const fontSizes = {
  table: 9,
  text: 11,
  small: 12,
  medium: 14,
  large: 17,
};

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    marginBottom: spacings.large,
    overflow: "initial",
  },
  image: {
    height: 110,
    width: "100%",
  },
  titleWrapper: {
    width: "85%",
    paddingTop: "5px",
    display: "flex",
    flexDirection: "column",
    overflow: "initial",
    alignSelf: "center",
  },
  title: {
    fontFamily: "Mulish",
    fontWeight: "bold",
    fontSize: fontSizes.large,
    marginBottom: spacings.small,
    color: "#02797e",
    width: "50%",
  },
  subtitle: {
    fontFamily: "Mulish",
    fontSize: fontSizes.medium,
    marginBottom: spacings.small,
  },
  address: {
    fontFamily: "Mulish",
    fontStyle: "italic",
    fontSize: fontSizes.small,
    marginBottom: spacings.large,
  },
  pageTitle: {
    fontFamily: "Mulish",
    fontWeight: "bold",
    fontSize: fontSizes.medium,
    marginBottom: spacings.small,
  },
  pageDescription: {
    fontFamily: "Mulish",
    fontSize: fontSizes.text,
    marginBottom: spacings.large,
  },
  sectionWrapper: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  section: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  sectionContent: {
    width: "90%",
    display: "flex",
    flexDirection: "column",
  },
  sectionTitle: {
    fontFamily: "Mulish",
    fontWeight: "bold",
    fontSize: fontSizes.large,
    width: "100%",
    marginTop: spacings.small,
  },
  sectionSubtitle: {
    fontFamily: "Mulish",
    fontSize: fontSizes.text,
    width: "100%",
  },
  sectionSubtitleBody: {
    fontFamily: "Mulish",
    fontWeight: "bold",
    fontSize: fontSizes.small,
    width: "100%",
    marginTop: spacings.medium,
    marginBottom: spacings.small,
  },
  sectionDescription: {
    fontFamily: "Mulish",
    fontSize: fontSizes.text,
    width: "100%",
    marginBottom: spacings.medium,
  },
  tableTitle: {
    fontFamily: "Mulish",
    fontWeight: "bold",
    fontSize: fontSizes.text,
    width: "100%",
  },
  table: {
    marginBottom: spacings.medium,
    marginTop: 10,
    flexDirection: "column",
    alignItems: "center",
    borderRight: "1px solid black",
    borderBottom: "1px solid black",
    width: "100%",
  },
  tableRow: {
    flexDirection: "row",
  },
  tableHeaderCell: {
    padding: 3,
    fontFamily: "Mulish",
    fontSize: fontSizes.table,
    textAlign: "center",
    borderTop: "1px solid black",
    borderLeft: "1px solid black",
  },
  tableCell: {
    padding: 3,
    fontFamily: "Mulish",
    fontSize: fontSizes.table,
    textAlign: "center",
    borderTop: "1px solid black",
    borderLeft: "1px solid black",
  },
  footerWrapper: {
    position: "absolute",
    height: spacings.large,
    left: 0,
    right: 0,
    bottom: 0,
    textAlign: "center",
    fontFamily: "Mulish",
    fontSize: fontSizes.text,
  },
});

export default styles;
