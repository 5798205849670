import axios from "axios";
import {
  runway_url,
  noticeability_url,
  airport_boundaries_url,
} from "./register";

async function findElectorate(lon, lat) {
  let url =
    "https://api.wsiflightpaths.aerlabs.com/find_electorate";
  try {
    const response = await axios.post(url, { lat: lat, lon: lon });
    return response.data;
  } catch (err) {
    if (url) {
      console.error(err);
    }
  }
}

async function getData(url) {
  try {
    const response = await axios.get(url);
    return response.data;
  } catch (err) {
    if (url) {
      console.error(err);
    }
  }
}

async function getNoticeability() {
  try {
    const response = await axios.get(noticeability_url);
    return response.data;
  } catch (err) {
    if (noticeability_url) {
      console.error(err);
    }
  }
}

async function getRunway() {
  return getData(runway_url);
}

async function getAirportBoundaries() {
  return getData(airport_boundaries_url);
}

export { getRunway, getAirportBoundaries, getNoticeability, findElectorate };

export default getData;
