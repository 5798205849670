import i18next from "i18next";
import altitudeIcon from "../../../images/popups/altitude-icon.png";

export function AltitudeSummary({ trackId }) {
  return (
    <div className="popup-icon-summary-text-wrapper">
      <img
        src={altitudeIcon}
        alt="a plance icon with 4 circles below it reaching to a straight line."
        className="pop-up-flight-icon"
      />
      <p className="popup-paragraph">
      <strong>{i18next.t("popup.airportChange.summary.altitude")}</strong> -{" "}
        {i18next.t("popup.airportChange." + trackId + ".altitude")}
      </p>
    </div>
  );
}
