import { Oval } from "react-loader-spinner";

function PdfLoader() {
  return (
    <div>
      <Oval
        height={25}
        width={25}
        color="#4fa94d"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel="oval-loading"
        secondaryColor="#4fa94d"
        strokeWidth={4}
        strokeWidthSecondary={4}
      />
    </div>
  );
}

export default PdfLoader;
