import React from "react";
import i18next from "../../../i18n";
import closingIcon from "../../../images/Map/closing-icon.svg";
import arrivalIcon from "../../../images/icons/arrival.png";
import departureIcon from "../../../images/icons/departure.png";
import "../../../styles/popups/TracksPopup.css";
import { AltitudeSummary } from "./AltitudeSummary";
import { HighlightTrackButton } from "./HighlightTrackButton";
import { ShowContourButton } from "./ShowContourButton";
import SwitchContourMetric from "./SwitchContourMetric";
import { UsageSummary } from "./UsageSummary";
import { getUniqueTracks } from "./getUniqueTracks";

function NonWsiTracksPopup({
  handleClosePopupTracks,
  selectedNonWsiSwathes,
  selectedNonWsiContour,
  setSelectedNonWsiContour,
}) {
  // Show nothing when no non-WSI swathes are selected
  if (selectedNonWsiSwathes.length < 1) {
    return <></>;
  }

  // Extract the properties
  const swatheProperties = selectedNonWsiSwathes[0];
  const flightType = swatheProperties["movement_type"].toLowerCase();

  const showArrival = flightType === "arrival";

  // Get the unique track ids to selected
  const uniqueTracks = getUniqueTracks(selectedNonWsiSwathes);

  // Check whether contours are available or not
  const hasContours = selectedNonWsiSwathes.some(
    (track) => track["has_contours"] === true
  );

  return (
    <div className="popup-wrapper">
      <section className="d-flex justify-content-between">
        <span className="d-flex flex-column justify-content-center w-100">
          <span className="d-flex justify-content-between">
            <h6 className="popup-title">
              {showArrival ? i18next.t("Arrivals") : i18next.t("Departures")}
            </h6>
            <img
              src={closingIcon}
              alt="closing icon"
              className="address-loc-marker"
              onClick={() => {
                handleClosePopupTracks();
              }}
            />
          </span>
          <div className="d-flex align-items-center">
            {showArrival ? (
              <img
                src={arrivalIcon}
                alt="an icon of a flight coming down."
                className="pop-up-flight-icon"
              />
            ) : (
              <img
                src={departureIcon}
                alt="an icon of a flight taking off."
                className="pop-up-flight-icon"
              />
            )}
            <div className="track-popup-btn-wrapper">
              {Object.entries(uniqueTracks).map(([k, track]) =>
                hasContours ? (
                  <ShowContourButton
                    key={k}
                    trackId={track.id}
                    trackName={track.name}
                    selectedNonWsiContour={selectedNonWsiContour}
                    setSelectedNonWsiContour={setSelectedNonWsiContour}
                  />
                ) : (
                  <HighlightTrackButton
                    key={k}
                    trackId={track.id}
                    trackName={track.name}
                    selectedNonWsiContour={selectedNonWsiContour}
                    setSelectedNonWsiContour={setSelectedNonWsiContour}
                  />
                )
              )}
            </div>
          </div>
          <p className="popup-text">
            {hasContours
              ? i18next.t("popup.airportChange.explainShowContour")
              : i18next.t("popup.airportChange.explainHighlightTrack")}
          </p>
          {hasContours && (
            <SwitchContourMetric
              enabled={selectedNonWsiContour["track_id"] !== null}
              selectedNonWsiContour={selectedNonWsiContour}
              setSelectedNonWsiContour={setSelectedNonWsiContour}
            />
          )}
          {selectedNonWsiContour["track_id"] !== null && (
            <>
              {/* todo: Add usage summary for selected track (#362) */}
              <UsageSummary trackId={selectedNonWsiContour["track_id"]} />
              {/* todo: Add altitude summary for selected track (#362) */}
              <AltitudeSummary trackId={selectedNonWsiContour["track_id"]} />
            </>
          )}
        </span>
      </section>
    </div>
  );
}

export default NonWsiTracksPopup;
