import { Source, Layer } from "react-map-gl";
import getData from "../../../requests/getData";
import { useQuery } from "@tanstack/react-query";

function DepartureArrowLayer({
  layerId,
  beforeId,
  departureArrowUrl,
  selectedArrivalNumbers,
  selectedDepartureNumbers,
}) {
  // Check if there are any selected tracks
  let noSelection =
    selectedArrivalNumbers.length === 0 &&
    selectedDepartureNumbers.length === 0;

  // Get the arrival data
  const { isError, data, error } = useQuery({
    queryKey: ["departureArrows", departureArrowUrl],
    queryFn: async () => {
      const data = await getData(departureArrowUrl);
      return data;
    },
    cacheTime: "Infinity",
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    placeholderData: {
      type: "FeatureCollection",
      features: [],
    },
    onError: (error) => {
      console.error(error);
    },
  });

  if (isError) {
    console.error(error);
    return <></>;
  }

  return (
    <Source
      id="departure-track-arrows"
      type="geojson"
      lineMetrics={true}
      data={data}
    >
      <Layer
        source="departure-track-arrows"
        id={layerId}
        beforeId={beforeId}
        type="symbol"
        paint={{
          "icon-opacity": 1,
        }}
        layout={{
          "text-rotation-alignment": "map",
          "symbol-placement": "line",
          "symbol-spacing": 50,
          "icon-rotate": -90,
          "icon-image": "arrow",
        }}
        filter={[
          "any",
          noSelection,
          ["in", ["get", "SUBTRACK_ID"], ["literal", selectedDepartureNumbers]],
        ]}
      />
    </Source>
  );
}

export default DepartureArrowLayer;
