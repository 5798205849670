function SwitchButton({
  contourMetric,
  enabled,
  selectedMetric,
  selectMetric,
}) {
  return (
    <button
      className={selectedMetric === contourMetric ? "metric-selected" : ""}
      onClick={() => selectMetric(contourMetric)}
      disabled={!enabled}
    >
      {contourMetric}
    </button>
  );
}

export default function SwitchselectedMetric({
  enabled,
  selectedNonWsiContour,
  setSelectedNonWsiContour,
}) {
  // Get the selected metric
  const selectedMetric = selectedNonWsiContour["metric"];

  function selectMetric(metric) {
    setSelectedNonWsiContour({
      ...selectedNonWsiContour,
      metric: metric,
    });
  }

  return (
    <div className="popup-noise-metric">
      <SwitchButton
        contourMetric="N60"
        enabled={enabled}
        selectedMetric={selectedMetric}
        selectMetric={selectMetric}
      />
      <SwitchButton
        contourMetric="N70"
        enabled={enabled}
        selectedMetric={selectedMetric}
        selectMetric={selectMetric}
      />
    </div>
  );
}
