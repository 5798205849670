import React from "react";
import { Text, Image, View } from "@react-pdf/renderer";
import reportHeader from "../../../../../images/popups/report_header.png";
import styles from "../../documentStyles";

const HeaderImage = () => {
  return <Image src={reportHeader} style={styles.image} fixed />;
};

const PageHeader = ({ addressName, pageTitleText, pageDescriptionText }) => {
  return (
    <>
      <HeaderImage />
      <View style={styles.titleWrapper} fixed>
        <Text style={styles.title}>
          Western Sydney International (Nancy-Bird Walton) Airport
        </Text>

        <Text style={styles.subtitle}>
          WSI Preliminary Flight Paths and Aircraft Noise Local Summary Report
        </Text>
        <Text style={styles.address}>{addressName}</Text>
        <Text style={styles.pageTitle}>{pageTitleText}</Text>
        <Text style={styles.pageDescription}>{pageDescriptionText}</Text>
      </View>
    </>
  );
};

export const GlossaryPageHeader = () => {
  return <HeaderImage />;
};

export const TrackPageHeader = ({ adressInfo }) => {
  const addressName = adressInfo.address;
  const pageTitleText = "WSI Preliminary Flight Paths";
  const pageDescriptionText =
    "This page sets out the predicted aircraft movements at WSI. The movements reflect predicted 2033 air traffic numbers. Actual movements may differ.​";

  return (
    <PageHeader
      addressName={addressName}
      pageTitleText={pageTitleText}
      pageDescriptionText={pageDescriptionText}
    />
  );
};

export const NoisePageHeader = ({ adressInfo }) => {
  const addressName = adressInfo.address;
  const pageTitleText = "Noise Forecasts​";
  const pageDescriptionText =
    "This page describes the aircraft noise forecasts for the WSI flight paths, based on the noise modelling undertaken for the draft Environmental Impact Statement. ​​";

  return (
    <PageHeader
      addressName={addressName}
      pageTitleText={pageTitleText}
      pageDescriptionText={pageDescriptionText}
    />
  );
};
