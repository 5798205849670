import React from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { current_track_color, proposed_track_color } from "../../../colors";
import i18next from "../../../i18n";
import diagonalArrivals from "../../../images/Map/diagonal-stripes-arrivals.png";
import diagonalDepartures from "../../../images/Map/diagonal-stripes-departures.png";
import diagonalCurrent from "../../../images/Map/diagonal-stripes-current.png";
import "../../../styles/map/Legend.css";
import LegendItem, { legendItemStyle } from "./LegendItem";
import { Trans } from "react-i18next";
import filterConfigurations from "../../../static-data/filter_data.json";
import ChangeContourLegend from "./ChangeContourLegend";

function AirportChangeLegend({
  filterSelection,
  legendDescription,
  setLegendDescription,
  selectedNonWsiSwathes,
  selectedNonWsiContour,
  contourUrl,
  currentVectoringUrl,
  proposedVectoringUrl,
}) {
  // Check if any swathe is selected
  const anySelected = selectedNonWsiSwathes.length > 0;
  const anyContourSelected = selectedNonWsiContour.track_id !== null;

  // Get the airport name
  const airport = i18next.t(
    filterConfigurations.airportChange.options[filterSelection.airportChange]
      .title
  );

  // Get the flight type
  const flightType =
    filterSelection.procedureGroup === "arrivals" ? "arrival" : "departure";

  // Get the proposed pattern
  const proposedPattern =
    filterSelection.procedureGroup === "arrivals"
      ? diagonalArrivals
      : diagonalDepartures;

  // Determine if we need to show noise contour information
  const showNoise =
    anySelected && anyContourSelected && contourUrl !== undefined;

  if (showNoise) {
    return (
      <ChangeContourLegend
        legendDescription={legendDescription}
        setLegendDescription={setLegendDescription}
        selectedNonWsiContour={selectedNonWsiContour}
        contourUrl={contourUrl}
      />
    );
  }

  // Check if only proposed vectoring area is defined
  const onlyProposedVectoring =
    currentVectoringUrl === undefined && proposedVectoringUrl !== undefined;

  return (
    <>
      <div className="d-flex align-items-center justify-content-between w-100">
        <dt id="legend-title">
          {
            <Trans
              i18nKey={"legend.airportChange.title"}
              values={{ airport: airport }}
            />
          }
        </dt>
        <AiOutlineInfoCircle
          color={"white"}
          className="legend-info-icon"
          onClick={() => {
            setLegendDescription(!legendDescription);
          }}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              setLegendDescription(!legendDescription);
            }
          }}
        />
      </div>
      {filterSelection.airportChange !== "bankstown" && (
        <>
          <LegendItem
            name={i18next.t("legend.airportChange.current")}
            color={current_track_color[flightType]}
          />
          {legendDescription && (
            <div className="legend-info-text">
              {i18next.t("legend.airportChange.current.info")}
            </div>
          )}
        </>
      )}
      <LegendItem
        name={i18next.t("legend.airportChange.proposed")}
        color={proposed_track_color[flightType]}
      />
      {legendDescription && (
        <div className="legend-info-text">
          {i18next.t("legend.airportChange.proposed.info")}
        </div>
      )}
      {!onlyProposedVectoring &&
        filterSelection.airportChange !== "bankstown" && (
          <dt className="colour-identifiers">
            <img
              src={diagonalCurrent}
              alt="the pattern for vectoring areas of the current situation"
              style={legendItemStyle}
            />
            {i18next.t("legend.airportChange.currentVectoringAreas")}
          </dt>
        )}
      {!onlyProposedVectoring &&
        legendDescription &&
        filterSelection.airportChange !== "bankstown" && (
          <div className="legend-info-text">
            {i18next.t("legend.airportChange.currentVectoringAreas.info")}
          </div>
        )}
      {!onlyProposedVectoring &&
        filterSelection.airportChange !== "bankstown" && (
          <dt className="colour-identifiers">
            <img
              src={proposedPattern}
              alt="the pattern for vectoring areas of the proposed situation"
              style={legendItemStyle}
            />
            {i18next.t("legend.airportChange.proposedVectoringAreas")}
          </dt>
        )}
      {!onlyProposedVectoring &&
        legendDescription &&
        filterSelection.airportChange !== "bankstown" && (
          <div className="legend-info-text">
            {i18next.t("legend.airportChange.proposedVectoringAreas.info")}
          </div>
        )}
      {onlyProposedVectoring && (
        <dt className="colour-identifiers">
          <img
            src={proposedPattern}
            alt="the pattern for vectoring areas of the legacy situation"
            style={legendItemStyle}
          />
          {i18next.t("legend.airportChange.legacyVectoringAreas")}
        </dt>
      )}
      {onlyProposedVectoring && legendDescription && (
        <div className="legend-info-text">
          {i18next.t("legend.airportChange.legacyVectoringAreas.info")}
        </div>
      )}
    </>
  );
}

export default AirportChangeLegend;
