import React from "react";
import { PdfContextProvider } from "./PdfContextProvider";

function ContextProvider({ children }) {
  return (
    <>
      <PdfContextProvider>{children}</PdfContextProvider>
    </>
  );
}

export default ContextProvider;
