import React from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import i18next from "../../../i18n";
import "../../../styles/map/Legend.css";
import LegendItem from "./LegendItem";

function NoiseLegend({
  currentColours,
  legendTitle,
  legendDescription,
  setLegendDescription,
}) {
  return (
    <>
      <div className="d-flex align-items-center justify-content-between w-100">
        <dt id="legend-title">{i18next.t(legendTitle)}</dt>
          <AiOutlineInfoCircle
            color={"white"}
            className="legend-info-icon"
            onClick={() => {
              setLegendDescription(!legendDescription);
            }}
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setLegendDescription(!legendDescription);
              }
            }}
          />
      </div>
      {legendDescription && (
        <div className="legend-info-text">
          {i18next.t(legendTitle + ".info")}
        </div>
      )}
      {currentColours.map((colour, index) => {
        if (index === 0) {
          return (
            <div key={index}>
              <LegendItem
                key={index}
                name={colour.properties.value}
                color={colour.properties["fill-color"]}
              />
            </div>
          );
        } else if (
          colour.properties.plotted_contour !==
          currentColours[index - 1].properties.plotted_contour
        ) {
          return (
            <div key={index}>
              <LegendItem
                key={index}
                name={colour.properties.value}
                color={colour.properties["fill-color"]}
              />
            </div>
          );
        } else {
          return null;
        }
      })}
    </>
  );
}

export default NoiseLegend;
