import React, { useRef } from "react";
import i18next from "../i18n";
import useOutsideClick from "../hooks/useOutsideClick";
// styling imports
import "../styles/tools/VideoModal.css";
import closingIcon from "../images/Map/closing-icon.svg";

function VideoModal({ videoUrl, videoAircraft, videoOpen, setVideoOpen }) {
  const outsideClick = useRef(null);
  useOutsideClick(outsideClick, setVideoOpen);

  return (
    <>
      {videoOpen && videoUrl && (
        <section className="video-wrapper">
          <div className="video-window" ref={outsideClick}>
            <div className="video-title">
              <div className="popup-invisible-title-text"></div>
              <div className="popup-title-video-title">
                {i18next.t("video.title", { aircraft: videoAircraft })}
              </div>
              <div className="video-closing-icon-wrapper">
                <img
                  src={closingIcon}
                  alt="closing icon shaped as the letter X."
                  className="video-closing-icon"
                  onClick={() => {
                    setVideoOpen(false);
                  }}
                />
              </div>
            </div>
            <div className="video-player-wrapper">
              {/* <iframe
                src="https://player.vimeo.com/video/830897368?h=84deb58f6d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
                title="RWY05 Departure West Northwest Day - B787"
                className="video-video"
              /> */}
              <iframe
                src={videoUrl}
                allow="autoplay; fullscreen; picture-in-picture"
                // allowFullScreen
                title="RWY23 Departure North Day - A320"
                className="video-video"
              />
            </div>
            <script src="https://player.vimeo.com/api/player.js" />
          </div>
        </section>
      )}
    </>
  );
}

export default VideoModal;
