import React from "react";
import { usePdfContext } from "../../../hooks/provider/usePdfContext";
import PDFDocument from "./PdfDocument";
import { PDFViewer } from "@react-pdf/renderer";
// styling imports
import closingIcon from "../../../images/Map/closing-icon.svg";
import "../../../styles/popups/PdfModal.css";
import getData from "../../../requests/getData";
import { useQueries } from "@tanstack/react-query";
import pointToLineDistance from "@turf/point-to-line-distance";
import { TRACK_SCENARIOS, NOISE_SCENARIOS } from "./scenarios";
import { altitudeSegments } from "../../../requests/register";
import booleanPointInPolygon from "@turf/boolean-point-in-polygon";
import { point, polygon, lineString } from "@turf/helpers";

function PdfDataProcessing({
  trackQueries,
  trackScenarios,
  noiseQueries,
  noiseScenarios,
  noticeabilityLayer,
  lat,
  lon,
  address,
}) {
  const addressCoordinates = point([lon, lat]);

  const getNoiseReportLAMax = () => {
    const index = noiseScenarios.findIndex((array) =>
      array[0].includes("lamax")
    );
    const noiseContour = noiseQueries[index].data;
    let valueFound = checkAddressOnContour(noiseContour, false, true);

    // Try to look for noticeability layer value if no value was found above
    if (valueFound === "-") {
      valueFound = checkAddressOnContour(noticeabilityLayer.data, true, true);
    }
    return valueFound;
  };

  const getNoiseReport = () => {
    let noiseReportContent = [];

    Object.entries(NOISE_SCENARIOS.year).forEach(([k, v]) => {
      let tableContent = getNoiseReportTable(v.url);
      let singleYearContent = {
        year: k,
        results: tableContent,
      };
      noiseReportContent = [...noiseReportContent, singleYearContent];
    });
    return noiseReportContent;
  };

  const getNoiseReportTable = (year) => {
    let tableContent = [];

    Object.entries(NOISE_SCENARIOS.metric).forEach(([k, v]) => {
      let contour = getNoiseReportRow(year, v.url);
      contour["metric"] = v.name;
      tableContent = [...tableContent, contour];
    });
    return tableContent;
  };

  const getNoiseReportRow = (year, metric) => {
    let rowContent = {};

    Object.entries(NOISE_SCENARIOS.scenario).map(([k, v]) => {
      const contours = getMatchingNoiseContour(year, metric, v.url);
      const valueFound = checkAddressOnContour(contours);
      return (rowContent[k] = valueFound);
    });
    return rowContent;
  };

  const checkAddressOnContour = (
    contours,
    noticeability = false,
    lamax = false
  ) => {
    let featureFound = null;
    contours.features.forEach((feature) => {
      const turfPolygon = polygon(feature.geometry.coordinates);
      const isInPolygon = booleanPointInPolygon(
        addressCoordinates,
        turfPolygon
      );
      if (isInPolygon === true) {
        featureFound = feature;
      }
    });
    if (featureFound) {
      return noticeability
        ? featureFound.properties.Contour
        : lamax
        ? featureFound.properties.value
        : `${featureFound.properties.value} overflight events`;
    }
    return "-";
  };

  const getTracksReport = () => {
    let tracksReportContent = {};
    let subtrackIds = [];

    Object.entries(TRACK_SCENARIOS.time).forEach(([k, v]) => {
      let tableContent = getTrackReportTable(v.url);
      tracksReportContent[k] = tableContent[0];
      subtrackIds = [...subtrackIds, ...tableContent[1]];
    });
    return [tracksReportContent, subtrackIds];
  };

  const getTrackReportTable = (time) => {
    let tableContent = {};
    let subtrackIds = [];
    Object.entries(TRACK_SCENARIOS.runway).forEach(([k, v]) => {
      // RRO is not a mode during the day, so it is skipped
      if (
        time !== TRACK_SCENARIOS.time.day.url ||
        v !== TRACK_SCENARIOS.runway.rro
      ) {
        let contour = getTrackReportFlightType(time, v.url);
        tableContent[k] = {
          tracks: contour[0],
          movements: contour[2],
          closestTrack: contour[3],
        };
        subtrackIds = [...subtrackIds, ...contour[1]];
      }
    });
    return [tableContent, subtrackIds];
  };

  const getTrackReportFlightType = (time, runway) => {
    let flightTypeContent = [];
    let subtrackIds = [];
    let movements = { arrivals: {}, departures: {} };
    let closestTrack = { tracks: [], distance: 99999 };
    Object.values(TRACK_SCENARIOS.type).forEach((type) => {
      const contours = getMatchingTrackContour(time, runway, type);
      const tracksAbove = checkTracksAboveAddress(contours, closestTrack);
      movements[type] = addAverageAndMaxMovements(tracksAbove[0]);
      flightTypeContent = [...flightTypeContent, ...tracksAbove[0]];
      subtrackIds = [...subtrackIds, ...tracksAbove[1]];
    });
    return [flightTypeContent, subtrackIds, movements, closestTrack];
  };

  const getMatchingTrackContour = (time, runway, type) => {
    const matchingString = `${time}_${runway}_${type}`;
    const index = trackScenarios.findIndex(
      (array) => array[0] === matchingString
    );
    return trackQueries[index].data;
  };

  const getMatchingNoiseContour = (year, metric, scenario) => {
    const matchingString = `${year}_${scenario}_${metric}_url`;
    const index = noiseScenarios.findIndex(
      (array) => array[0] === matchingString
    );
    return noiseQueries[index].data;
  };

  const checkTracksAboveAddress = (contours, closestTrack) => {
    let tracksAbove = [];
    let subtrackIds = [];
    contours.features.forEach((feature) => {
      const turfPolygon = feature;
      const turfLine = lineString(feature.geometry.coordinates[0]);
      const isInPolygon = booleanPointInPolygon(
        addressCoordinates,
        turfPolygon
      );
      const subtrackId = feature.properties.subtrack_id;
      const trackName = feature.properties.reference_name;
      if (isInPolygon === true) {
        tracksAbove = [
          ...tracksAbove,
          {
            trackName: trackName,
            average: feature.properties.movements_average,
            max: feature.properties.movements_max,
            nested: feature.properties.nested_track,
            flightType: feature.properties.layer.split("_")[0],
            subtrackId: subtrackId,
          },
        ];
        subtrackIds = [...subtrackIds, `subtrack_id_${subtrackId}`];
      } else {
        // Determine the distance of the selected address to the flight path
        let currentDistance = pointToLineDistance(
          addressCoordinates,
          turfLine,
          {
            units: "kilometers",
          }
        );
        // Round to 1 decimal
        currentDistance = Math.round(currentDistance * 10) / 10;
        // If it's closer than other flight paths, mark it as the closest
        if (currentDistance < closestTrack.distance) {
          closestTrack.distance = currentDistance;
          closestTrack.tracks = trackName;
          // Or add it to the list if it's as close as the other
        } else if (currentDistance === closestTrack.distance) {
          closestTrack.tracks = closestTrack.tracks + ", " + trackName;
        }
      }
    });
    return [tracksAbove, subtrackIds, closestTrack];
  };

  const addAverageAndMaxMovements = (tracksAbove) => {
    let movements = { average: 0, max: 0 };
    let addedNestedTracks = [];

    tracksAbove.forEach((track) => {
      const nestedTrack = track.nested;
      const average = track.average;
      const max = track.max;
      if (
        nestedTrack === null ||
        (nestedTrack !== null && addedNestedTracks.indexOf(nestedTrack) === -1)
      ) {
        movements.average = movements.average + average;
        movements.max = movements.max + max;
        // Add nested group to the list of added groups
        if (nestedTrack !== null) {
          addedNestedTracks.push(nestedTrack);
        }
      }
    });
    return movements;
  };

  const noiseReport = getNoiseReport();
  const singleNoiseReport = getNoiseReportLAMax();
  const tracksReportInfo = getTracksReport();
  const tracksReport = tracksReportInfo[0];
  const subtrackIds = tracksReportInfo[1];

  return (
    <PdfTrackSegmentsProcessing
      addressCoordinates={addressCoordinates}
      address={address}
      tracksReport={tracksReport}
      subtrackIds={subtrackIds}
      noiseReport={noiseReport}
      singleNoiseReport={singleNoiseReport}
    />
  );
}

function PdfTrackSegmentsProcessing({
  addressCoordinates,
  address,
  tracksReport,
  subtrackIds,
  noiseReport,
  singleNoiseReport,
}) {
  const { setShowPdfModal } = usePdfContext();

  const altitudes = useQueries({
    queries: subtrackIds.map((subtrackUrl) => {
      return {
        queryKey: ["segments", altitudeSegments[subtrackUrl]],
        queryFn: async () => {
          const data = await getData(altitudeSegments[subtrackUrl]);
          return data;
        },
        cacheTime: Infinity,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
      };
    }),
  });

  if (altitudes.some((obj) => obj.isLoading === true)) {
    return <></>;
  }

  const getAltitudeInformation = () => {
    Object.values(tracksReport).forEach((time) => {
      Object.values(time).forEach((scenario) => {
        scenario.tracks.forEach((tableRow) => {
          const trackSegments = getMatchingTrackSegments(tableRow.subtrackId);
          const trackAltitudeRange = checkClosestAltitudeSegment(trackSegments);
          tableRow["altitude"] = trackAltitudeRange;
        });
      });
    });
    return tracksReport;
  };

  const getMatchingTrackSegments = (subtrackId) => {
    const matchingString = `subtrack_id_${subtrackId}`;
    const index = subtrackIds.findIndex(
      (subtrack) => subtrack === matchingString
    );
    return altitudes[index].data;
  };

  const checkClosestAltitudeSegment = (trackAltitudeSegments) => {
    let closestDistance = 99999;
    let closestLabel = null;
    let closestLabelNext = null;
    // Check all altitude segments linked to the track
    trackAltitudeSegments.features.forEach((segment) => {
      let currentDistance = pointToLineDistance(addressCoordinates, segment, {
        units: "kilometers",
      });
      // If the distance from the selected point to the current altitude segment is shorter than
      // the current minimum distance, it is assumed as the closest distance for this track
      if (currentDistance <= closestDistance) {
        closestLabel = segment.properties.value;
        closestLabelNext = segment.properties.next_value;
        closestDistance = currentDistance;
      }
    });
    if (Number(closestLabel) === Number(closestLabelNext)) {
      return `${Number(closestLabel)}+`;
    }
    return `${Number(closestLabel)} - ${Number(closestLabelNext)}`;
  };

  const completeTracksReport = getAltitudeInformation();

  return (
    <div className="modal-background">
      <div className="pdf-modal-wrapper">
        <div className="pdf-title-section">
          PDF report
          <img
            src={closingIcon}
            alt="an icon in the shape of the letter X. Which is two lines crossing each other in the middle with a 45 degree angle where one aims left and other aims right direction."
            className="pdf-closing-icon"
            onClick={() => {
              setShowPdfModal(false);
            }}
          />
        </div>
        <section className="pdf-section">
          <PDFViewer>
            <PDFDocument
              adressInfo={address}
              completeTracksReport={completeTracksReport}
              noiseReport={noiseReport}
              singleNoiseReport={singleNoiseReport}
            />
          </PDFViewer>
        </section>
      </div>
    </div>
  );
}

export default PdfDataProcessing;
