import i18next from "../../../i18n";
import "../../../styles/map/MapComponent.css";
import NoiseSearchComponent from "./NoiseSearchComponent";

function ContourMatchComponent({
  mapType,
  noiseMetric,
  noiseMetricKey,
  feature: featureFound,
}) {
  let noiseText;
  if (mapType === "eventNoise" && featureFound.properties.aircraft_description !== undefined) {
    noiseText = i18next.t("popup.noise.lamax", {
      db: featureFound.properties.contour,
      aircraft: featureFound.properties.aircraft_description,
    });
  } else if (mapType === "eventNoise" && featureFound.properties.aircraft_description === undefined) {
    noiseText = i18next.t("popup.noise.compLamax", {
      db: featureFound.properties.contour,
    });
  } else if (mapType === "cumulativeNoise" && noiseMetricKey === "ANEF") {
    noiseText = i18next.t("popup.noise.anef", {
      value: featureFound.properties.contour,
    });
  } else if (mapType === "cumulativeNoise" && noiseMetricKey === "N60N") {
    const lowerbound = featureFound.properties.lower;
    const upperbound = featureFound.properties.upper;
    let translationCode = "popup.noise.nabove"
    if (lowerbound === upperbound) {
      translationCode = translationCode + "max"
    }
    noiseText = i18next.t(translationCode, {
      metric: noiseMetric,
      db: noiseMetric.match(/\d+/)[0],
      lowerbound: lowerbound,
      upperbound: upperbound,
      period: "during the overnight period",
    });
  } else if (
    mapType === "cumulativeNoise" &&
    (noiseMetricKey === "N60" || noiseMetricKey === "N70")
  ) {
    const lowerbound = featureFound.properties.lower;
    const upperbound = featureFound.properties.upper;
    let translationCode = "popup.noise.nabove"
    if (lowerbound === upperbound) {
      translationCode = translationCode + "max"
    }
    noiseText = i18next.t(translationCode, {
      metric: noiseMetric,
      db: noiseMetric.match(/\d+/)[0],
      lowerbound: lowerbound,
      upperbound: upperbound,
      period: "over a 24 hour period",
    });
  } else {
    noiseText = i18next.t(featureFound.properties.text);
  }

  // todo: Replace i18next.t(featureFound.properties.text) by the following translations:
  // i18next.t("popup.noise.nabove", {metric: noiseMetric, db: layer, lowerbound: 30, upperbound: 40, period: "over a 24 hour period"})

  //
  const anecFooter = noiseMetricKey === "ANEF";

  return <NoiseSearchComponent noiseText={noiseText} anecFooter={anecFooter} />;
}

export default ContourMatchComponent;
