import React from "react";
import i18next from "../../../i18n";
import closingIcon from "../../../images/Map/closing-icon.svg";
import arrivalIcon from "../../../images/icons/arrival.png";
import departureIcon from "../../../images/icons/departure.png";
import playIcon from "../../../images/popups/playIcon.svg";
import "../../../styles/popups/TracksPopup.css";

function WsiTracksPopup({
  handleClosePopupTracks,
  selectedArrivals,
  selectedDepartures,
  setVideoOpen,
  findVideoUrl,
}) {
  return (
    <>
      {(selectedArrivals !== undefined || selectedDepartures !== undefined) && (
        <div className="popup-wrapper">
          <section className="d-flex justify-content-between">
            <span className="d-flex flex-column justify-content-center w-100">
              {selectedArrivals !== undefined &&
                Object.keys(selectedArrivals).length > 0 && (
                  <span className="d-flex justify-content-between">
                    <h6 className="popup-title">{i18next.t("Arrivals")}</h6>
                    <img
                      src={closingIcon}
                      alt="closing icon"
                      className="address-loc-marker"
                      onClick={() => {
                        handleClosePopupTracks();
                      }}
                    />
                  </span>
                )}
              <div className="d-flex align-items-center">
                {selectedArrivals !== undefined &&
                  Object.keys(selectedArrivals).length > 0 && (
                    <img
                      src={arrivalIcon}
                      alt="an icon of a flight coming down."
                      className="pop-up-flight-icon"
                    />
                  )}
                <div className="track-popup-btn-wrapper">
                  {selectedArrivals !== undefined &&
                    Object.keys(selectedArrivals).map((field) => (
                      <button
                        key={field}
                        className="popup-flight-btn"
                        onClick={() => {
                          setVideoOpen(true);
                          findVideoUrl(field);
                        }}
                      >
                        <img
                          src={playIcon}
                          alt="video play icon. Basically a triangle that has been rotated to right by 90 degrees."
                          className="popup-play-icon"
                        />
                        <div className="w-100">{selectedArrivals[field]}</div>
                      </button>
                    ))}
                </div>
              </div>
              {selectedDepartures !== undefined &&
                Object.keys(selectedDepartures).length > 0 && (
                  <span className="d-flex justify-content-between">
                    <h6 className="popup-title">{i18next.t("Departures")}</h6>
                    {(selectedArrivals === undefined ||
                      Object.keys(selectedArrivals).length < 1) && (
                      <img
                        src={closingIcon}
                        alt="closing icon"
                        className="address-loc-marker"
                        onClick={() => {
                          handleClosePopupTracks();
                        }}
                      />
                    )}
                  </span>
                )}
              <div className="d-flex align-items-center">
                {selectedDepartures !== undefined &&
                  Object.keys(selectedDepartures).length > 0 && (
                    <img
                      src={departureIcon}
                      alt="an icon of a flight taking off."
                      className="pop-up-flight-icon"
                    />
                  )}
                <div className="track-popup-btn-wrapper">
                  {selectedDepartures !== undefined &&
                    Object.keys(selectedDepartures).map((field) => (
                      <button
                        key={field}
                        className="popup-flight-btn"
                        onClick={() => {
                          setVideoOpen(true);
                          findVideoUrl(field);
                        }}
                      >
                        <img
                          src={playIcon}
                          alt="video play icon. Basically a triangle that has been rotated to right by 90 degrees."
                          className="popup-play-icon"
                        />
                        <div className="w-100">{selectedDepartures[field]}</div>
                      </button>
                    ))}
                </div>
              </div>
            </span>
          </section>
          <div className="w-100 d-flex justify-content-center">
            {(selectedArrivals !== undefined ||
              selectedDepartures !== undefined) && (
              <span className="popup-text">
                {i18next.t("popup.flightpaths.underMulti.footer")}
              </span>
            )}
          </div>
          <div className="w-100 d-flex justify-content-center">
            <span className="popup-text">
              {i18next.t("popup.flightpaths.altitude.description")}
            </span>
          </div>
        </div>
      )}
    </>
  );
}

export default WsiTracksPopup;
