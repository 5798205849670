import { useEffect } from "react";

/**
 * Hook that allows us to write less code for outside click functions
 */
function useOutsideClick(ref, state) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        state("");
      }
    }

    const KeyboardClose = (e) => {
      if (e.key === "Escape") {
        state("");
      }
    };

    document.addEventListener("keydown", KeyboardClose);
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", KeyboardClose);
    };
  }, [ref, state]);
}

export default useOutsideClick;
