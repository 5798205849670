import ChangeAirportBoundaryLayer from "./ChangeAirportBoundaryLayer";
import ChangeContourLayer from "./ChangeContourLayer";
import ChangeRunwayLayer from "./ChangeRunwayLayer";
import ChangeSwatheLayer from "./ChangeSwatheLayer";
import ChangeTrackLayer from "./ChangeTrackLayer";
import ChangeVectoringLayer from "./ChangeVectoringLayer";

const layerId = {
  runways: "non-wsi-runways-layer",
  boundaries: "non-wsi-airport-boundaries-layer",
  current: {
    tracks: "current-tracks-layer",
    swathes: "current-swathes-layer",
    vectors: "current-vectoring-layer",
    contours: "current-contour-layer",
  },
  proposed: {
    tracks: "proposed-tracks-layer",
    swathes: "proposed-swathes-layer",
    vectors: "proposed-vectoring-layer",
    contours: "proposed-contour-layer",
  },
};

function AirportChangeLayers({
  airportRunwayUrl,
  airportBoundaryUrl,
  currentTrackUrl,
  currentSwatheUrl,
  currentVectoringUrl,
  currentContourUrl,
  proposedTrackUrl,
  proposedSwatheUrl,
  proposedVectoringUrl,
  proposedContourUrl,
  selectedNonWsiSwathes,
  selectedNonWsiContour,
  mapOption,
}) {
  // Check if any swathe is selected
  const anySelected = selectedNonWsiSwathes.length > 0;
  const anyContourSelected = selectedNonWsiContour.track_id !== null;

  // Check if only proposed vectoring area is defined
  const onlyProposedVectoring =
    currentVectoringUrl === undefined && proposedVectoringUrl !== undefined;

  // Create helper variables
  const hasCurrentContour = currentContourUrl !== undefined;
  const hasProposedContour = proposedContourUrl !== undefined;

  const showRunway = airportRunwayUrl !== undefined;
  const showBoundaries = airportBoundaryUrl !== undefined;
  const showCurrentTrack = currentTrackUrl !== undefined;
  const showCurrentSwathe = currentSwatheUrl !== undefined;
  const showCurrentVectoring = anySelected && currentVectoringUrl !== undefined;
  const showCurrentContour =
    anySelected && anyContourSelected && hasCurrentContour;
  const showProposedTrack = proposedTrackUrl !== undefined;
  const showProposedSwathe = proposedSwatheUrl !== undefined;
  const showProposedVectoring = proposedVectoringUrl !== undefined;
  const showProposedContour =
    anySelected && anyContourSelected && hasProposedContour;

  // Set the layer order and whether they are available or not
  let layerOrder = [
    { layer: "runway-layer", available: true },
    { layer: layerId.runways, available: showRunway },
    { layer: layerId.boundaries, available: showBoundaries },
    { layer: layerId.proposed.tracks, available: showProposedTrack },
    { layer: layerId.proposed.swathes, available: showProposedSwathe },
    { layer: layerId.proposed.vectors, available: showProposedVectoring },
    { layer: layerId.current.tracks, available: showCurrentTrack },
    { layer: layerId.current.swathes, available: showCurrentSwathe },
    { layer: layerId.current.vectors, available: showCurrentVectoring },
    { layer: layerId.proposed.contours, available: showProposedContour },
    { layer: layerId.current.contours, available: showCurrentContour },
  ];

  // Remove unavailable layers
  const availableLayers = layerOrder
    .filter((o) => o.available)
    .map((o) => o.layer);

  function getBeforeId(layerName) {
    const index = availableLayers.indexOf(layerName);
    if (index > 0) {
      return availableLayers[index - 1];
    }
    return null;
  }

  return (
    <>
      {/* Runways */}
      {showRunway && (
        <ChangeRunwayLayer
          layerId={layerId.runways}
          beforeId={getBeforeId(layerId.runways)}
          runwayUrl={airportRunwayUrl}
        />
      )}

      {/* Airport Boundaries */}
      {showBoundaries && (
        <ChangeAirportBoundaryLayer
          layerId={layerId.boundaries}
          beforeId={getBeforeId(layerId.boundaries)}
          airportBoundaryUrl={airportBoundaryUrl}
          mapOption={mapOption}
        />
      )}

      {/* Current Tracks */}
      {showCurrentTrack && (
        <ChangeTrackLayer
          layerId={layerId.current.tracks}
          beforeId={getBeforeId(layerId.current.tracks)}
          trackUrl={currentTrackUrl}
          changeType="current"
          selectedNonWsiSwathes={selectedNonWsiSwathes}
        />
      )}

      {/* Current Swathes */}
      {showCurrentSwathe && (
        <ChangeSwatheLayer
          layerId={layerId.current.swathes}
          beforeId={getBeforeId(layerId.current.swathes)}
          swatheUrl={currentSwatheUrl}
          changeType="current"
          selectedNonWsiSwathes={selectedNonWsiSwathes}
          selectedNonWsiContour={selectedNonWsiContour}
          hasContours={hasCurrentContour || hasProposedContour}
        />
      )}

      {/* Current Vectoring Areas */}
      {showCurrentVectoring && (
        <ChangeVectoringLayer
          layerId={layerId.current.vectors}
          beforeId={getBeforeId(layerId.current.vectors)}
          vectoringUrl={currentVectoringUrl}
          changeType="current"
          selectedNonWsiSwathes={selectedNonWsiSwathes}
          persistent={false}
        />
      )}

      {/* Current Noise Contours */}
      {showCurrentContour && (
        <ChangeContourLayer
          layerId={layerId.current.contours}
          beforeId={getBeforeId(layerId.current.contours)}
          contourUrl={currentContourUrl}
          changeType="current"
          selectedNonWsiContour={selectedNonWsiContour}
        />
      )}

      {/* Proposed Tracks */}
      {showProposedTrack && (
        <ChangeTrackLayer
          layerId={layerId.proposed.tracks}
          beforeId={getBeforeId(layerId.proposed.tracks)}
          trackUrl={proposedTrackUrl}
          changeType="proposed"
          selectedNonWsiSwathes={selectedNonWsiSwathes}
        />
      )}

      {/* Proposed Swathes */}
      {showProposedSwathe && (
        <ChangeSwatheLayer
          layerId={layerId.proposed.swathes}
          beforeId={getBeforeId(layerId.proposed.swathes)}
          swatheUrl={proposedSwatheUrl}
          changeType="proposed"
          selectedNonWsiSwathes={selectedNonWsiSwathes}
          selectedNonWsiContour={selectedNonWsiContour}
          hasContours={hasCurrentContour || hasProposedContour}
        />
      )}

      {/* Proposed Vectoring Areas */}
      {showProposedVectoring && (
        <ChangeVectoringLayer
          layerId={layerId.proposed.vectors}
          beforeId={getBeforeId(layerId.proposed.vectors)}
          vectoringUrl={proposedVectoringUrl}
          changeType="proposed"
          selectedNonWsiSwathes={selectedNonWsiSwathes}
          persistent={onlyProposedVectoring}
        />
      )}

      {/* Proposed Noise Contours */}
      {showProposedContour && (
        <ChangeContourLayer
          layerId={layerId.proposed.contours}
          beforeId={getBeforeId(layerId.proposed.contours)}
          contourUrl={proposedContourUrl}
          changeType="proposed"
          selectedNonWsiContour={selectedNonWsiContour}
        />
      )}
    </>
  );
}

export default AirportChangeLayers;
