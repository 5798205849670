import React from "react";
import i18next from "../../i18n";
import { Trans } from "react-i18next";
import { Tooltip } from "react-tooltip";
// styling imports
import { AiOutlineInfoCircle } from "react-icons/ai";
import "../../styles/Filters.css";

function selectedClass(className, selected) {
  if (selected) {
    return className + " button-selected";
  }
  return className;
}

function FilterItem({
  filterKey,
  configuration,
  selectValue,
  filterSelection,
  filterCombinations,
  filterCombinationsPattern,
  enabled,
  forceEnabled,
  isActive,
  toggleText,
  hideWhenDisabled,
  customClassName,
}) {
  // Extract the configuration
  const title = configuration.title;
  const subtitle = configuration.subtitle;
  const description = configuration.description;
  let options = configuration.options;

  // Create a set of the available combinations
  let availableConfigurations = new Set(Object.keys(filterCombinations));

  // Determine if button should have 2 rows
  const buttonClassName =
    "subtitle" in options[configuration.default]
      ? "filter-button-xl"
      : "filter-button-m";

  // Determine applicable button group style
  let buttonGroupClassNames = "filter-button-group ";
  if (customClassName !== undefined) {
    buttonGroupClassNames += customClassName;
  }

  // Determine if the option is available
  function optionAvailable(option) {
    if (forceEnabled) {
      return true;
    }

    // Copy the current selection
    let combination = { ...filterSelection };

    // Add the selected option
    combination[filterKey] = option;

    // Create the combination
    let combination_string = filterCombinationsPattern
      .map((key) => combination[key])
      .join("_");

    return availableConfigurations.has(combination_string);
  }

  return (
    <section className="filter-section">
      {/* Filter Header */}
      {title !== undefined && description !== undefined && (
        <div className="filter-header">
          {/* Filter Title */}
          {title !== undefined && (
            <span className="filter-title">{i18next.t(title)}</span>
          )}
          {/* Filter Info Toggle */}
          {description !== undefined && (
            <AiOutlineInfoCircle
              className="info-icon"
              onClick={() => {
                // setOpenDescription(false);
                // setOpenDescription(!openDescription);
                // // setOpenDescription(!openDescription);
                toggleText();
              }}
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  toggleText();
                }
              }}
            />
          )}
        </div>
      )}
      {/* Filter Subtitle */}
      {subtitle !== undefined && (
        <p className="filter-info-persistent">
          <Trans
            i18nKey={subtitle}
            // eslint-disable-next-line
            components={{ br: <br />, hyperlink: <a /> }}
          />
        </p>
      )}
      {/* Filter Info */}
      {isActive && (
        <p className="filter-info">
          <Trans
            i18nKey={description}
            // eslint-disable-next-line
            components={{ br: <br />, hyperlink: <a /> }}
          />
        </p>
      )}
      {/* Filter Buttons */}
      <div className={buttonGroupClassNames}>
        {Object.entries(options)
          .filter(
            ([k, v]) => !hideWhenDisabled | (optionAvailable(k) & enabled)
          )
          .map(([k, v]) => (
            <React.Fragment key={k}>
              {/* Filter Button */}
              <button
                key={k}
                className={selectedClass(
                  buttonClassName,
                  enabled && k === filterSelection[filterKey]
                )}
                onClick={() => {
                  selectValue(k);
                }}
                disabled={!optionAvailable(k) || !enabled}
                // for hover text
                data-tooltip-id="hover-text"
                // data-tooltip-content={v.hoverText}
                data-tooltip-html={`${i18next.t(v.hoverText)}`}
              >
                {i18next.t(v.title)}
                {v.subtitle && (
                  <div className="filter-button-m-subtitle" key={k}>
                    {i18next.t(v.subtitle)}
                  </div>
                )}
              </button>
              {/* Filter Button Tooltip */}
              <Tooltip
                id="hover-text"
                // anchorSelect="button-hover-text"
                style={{
                  backgroundColor: "#a1feb5",
                  color: "black",
                  zIndex: 999,
                  width: "200px",
                }}
                place="bottom"
              />
            </React.Fragment>
          ))}
      </div>
    </section>
  );
}

export default FilterItem;
