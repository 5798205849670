import { Source, Layer } from "react-map-gl";
import getData from "../../../requests/getData";
import { useQuery } from "@tanstack/react-query";
import { departure_track_selected_opacity } from "../../../colors";

function DepartureVectoringLayer({
  layerId,
  beforeId,
  departureVectoringUrl,
  setDepartureVectoring,
  selectedDepartureVectoringIndices,
}) {
  const { isError, data, error } = useQuery({
    queryKey: ["departureVectoring", departureVectoringUrl],
    queryFn: async () => {
      const data = await getData(departureVectoringUrl);
      return data;
    },
    cacheTime: "Infinity",
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    placeholderData: {
      type: "FeatureCollection",
      features: [],
    },
    onSuccess: (data) => {
      setDepartureVectoring(data);
    },
    onError: (error) => {
      console.error(error);
    },
  });

  if (isError) {
    console.error(error);
    return <></>;
  }

  return (
    <Source id="departure-vectoring-areas" type="geojson" data={data}>
      <Layer
        source="departure-vectoring-areas"
        id={layerId}
        beforeId={beforeId}
        type="fill"
        paint={{
          "fill-pattern": "shadingDepartures",
          "fill-opacity": departure_track_selected_opacity,
        }}
        filter={[
          "in",
          ["get", "index"],
          ["literal", selectedDepartureVectoringIndices],
        ]}
      />
    </Source>
  );
}

export default DepartureVectoringLayer;
