import React from "react";
import { Trans } from "react-i18next";
// styling imports
import "../../styles/Filters.css";
import FilterItem from "./FilterItem";
import {
  cumulativeNoiseCombinations,
  cumulativeNoisePattern,
  eventNoiseCombinations,
  eventNoisePattern,
} from "../../requests/register";
import i18n from "../../i18n";

function NoiseFilterGroup({
  filterConfigurations,
  filterSelection,
  setFilterSelection,
  mapType,
  setMapType,
  activeIndex,
  toggleText,
}) {
  function configureFilter(filterName, filterValue) {
    setMapType(
      filterName.startsWith("eventNoise") ? "eventNoise" : "cumulativeNoise"
    );
    const filterSelectionCopy = { ...filterSelection };
    filterSelectionCopy[filterName] = filterValue;
    if (filterName === "eventNoiseAircraft" && filterValue === "composite") {
      filterSelectionCopy["eventNoise"] = "day";
    }
    setFilterSelection(filterSelectionCopy);
  }

  return (
    <>
      <p className="info-persistent">
        <Trans
          i18nKey="filters.noise.info.persistent"
          // eslint-disable-next-line
          components={{ hyperlink: <a />, italic: <i />, bold: <strong /> }}
        />
      </p>
      <div className="noise-btn-wrapper">
        <button
          className={
            "noise-choice-btn " +
            (mapType === "cumulativeNoise"
              ? "filter-button-m button-selected"
              : "filter-button-m")
          }
          onClick={() => {
            setMapType("cumulativeNoise");
          }}
        >
          {i18n.t("filters.noise.option.cumulative.title")}
        </button>
        <button
          className={
            "noise-choice-btn " +
            (mapType === "eventNoise"
              ? "filter-button-m button-selected"
              : "filter-button-m")
          }
          onClick={() => {
            setMapType("eventNoise");
          }}
        >
          {i18n.t("filters.noise.option.event.title")}
        </button>
      </div>
      {mapType === "cumulativeNoise" && (
        <>
          <FilterItem
            filterKey={"year"}
            configuration={filterConfigurations["year"]}
            selectValue={(v) => configureFilter("year", v)}
            filterSelection={filterSelection}
            filterCombinations={cumulativeNoiseCombinations}
            filterCombinationsPattern={cumulativeNoisePattern}
            enabled={filterSelection.noiseMetric !== "LAmax"}
            isActive={activeIndex === 3}
            toggleText={() => toggleText(3)}
          />
          <FilterItem
            filterKey={"scenario"}
            configuration={filterConfigurations["scenario"]}
            selectValue={(v) => configureFilter("scenario", v)}
            filterSelection={filterSelection}
            filterCombinations={cumulativeNoiseCombinations}
            filterCombinationsPattern={cumulativeNoisePattern}
            enabled={filterSelection.noiseMetric !== "LAmax"}
            isActive={activeIndex === 4}
            toggleText={() => toggleText(4)}
          />
          <FilterItem
            filterKey={"noiseMetric"}
            configuration={filterConfigurations["noiseMetric"]}
            selectValue={(v) => configureFilter("noiseMetric", v)}
            filterSelection={filterSelection}
            filterCombinations={cumulativeNoiseCombinations}
            filterCombinationsPattern={cumulativeNoisePattern}
            enabled={mapType === "cumulativeNoise"}
            isActive={activeIndex === 5}
            toggleText={() => toggleText(5)}
          />
        </>
      )}
      {mapType === "eventNoise" && (
        <>
          <FilterItem
            filterKey={"eventNoise"}
            configuration={filterConfigurations["eventNoise"]}
            selectValue={(v) => configureFilter("eventNoise", v)}
            filterSelection={filterSelection}
            filterCombinations={eventNoiseCombinations}
            filterCombinationsPattern={eventNoisePattern}
            enabled={
              mapType === "eventNoise" &&
              filterSelection["eventNoiseAircraft"] !== "composite"
            }
            isActive={activeIndex === 6}
            toggleText={() => toggleText(6)}
          />
          <FilterItem
            filterKey={"eventNoiseAircraft"}
            configuration={filterConfigurations["eventNoiseAircraft"]}
            selectValue={(v) => configureFilter("eventNoiseAircraft", v)}
            filterSelection={filterSelection}
            filterCombinations={eventNoiseCombinations}
            filterCombinationsPattern={eventNoisePattern}
            enabled={mapType === "eventNoise"}
            isActive={activeIndex === 7}
            toggleText={() => toggleText(7)}
            customClassName={"event-noise-aircraft"}
          />
        </>
      )}
    </>
  );
}

export default NoiseFilterGroup;
