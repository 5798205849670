import React from "react";
import { Page, Text, View } from "@react-pdf/renderer";
import styles from "../../documentStyles";
import { GlossaryPageHeader } from "../sections/PageHeader";
import { GlossaryTable } from "../sections/Table";
import PageFooter from "../sections/PageFooter";

const GlossaryPage = () => {
  const tableContent = [
    {
      value: "Runway Level",
      definition:
        "Altitude information is shown with reference to runway level, which is approximately 260 feet or 80 metres above sea level.",
    },
    {
      value: "Runway 05",
      definition:
        "When Runway 05 is used all aircraft arrive on the runway from the south-west and depart on the runway to the north-east. ",
    },
    {
      value: "Runway 23",
      definition:
        "When Runway 23 is used all aircraft arrive on the runway from the north-east and depart on the runway to the south-west. ",
    },
    {
      value: "RRO",
      definition:
        "When RRO mode is used aircraft would land on Runway 05 – arriving on the runway from the south-west, and take-off on Runway 23 – departing on the runway to the south-west. The RRO runway mode may only be utilised at night, when it is safe to do so, depending on suitable meteorological conditions and aircraft traffic levels.",
    },
    {
      value: "No runway preference",
      definition:
        "This is a runway operating scenario where air traffic control will nominate the runway operating mode based on the prevailing weather conditions. ",
    },
    {
      value: "Prefer Runway 05",
      definition:
        "This is a runway operating scenario where preference is given to use Runway 05 during the day. During the overnight period only, preference is given to RRO mode. ",
    },
    {
      value: "Prefer Runway 23",
      definition:
        "This is a runway operating scenario where preference is given to use Runway 23 during the day. During the overnight period only, preference is given to RRO mode. ",
    },
    {
      value: "N70 (24 hrs)",
      definition:
        "A noise measure that shows the number of flights overhead with noise levels louder than 70 decibels that would be experienced on an average day (24-hour period)​",
    },
    {
      value: "N60 (24 hrs)​",
      definition:
        "A noise measure that shows the number of flights overhead with noise levels louder than 60 decibels that would be experienced on an average day (24-hour period)​",
    },
    {
      value: "N60 (overnight only)",
      definition:
        "A noise measure that shows the number of flights overhead with noise levels louder than 60 decibels that would be experienced during an average overnight period (11:00pm to 5:30am).",
    },
    {
      value: "ANEC",
      definition:
        "A noise measure which shows expected exposure levels from an average day’s anticipated aircraft movements, calculated over a 12-month period. ANEC charts do not show decibel measurements. While the ANEC noise contours can provide a general indication of aircraft noise impacts, and are useful for land use planning purposes near airports, they are not necessarily an indicator of individual experience of aircraft noise.  ",
    },
  ];
  return (
    <Page size="A4">
      <GlossaryPageHeader />
      <View style={{ ...styles.container, padding: "0px" }}>
        <View style={styles.sectionWrapper}>
          <View style={{ ...styles.section, width: "100%" }}>
            <View style={{ ...styles.sectionContent, width: "95%" }}>
              <Text style={styles.sectionSubtitleBody}>Glossary</Text>
              <GlossaryTable
                tableContent={tableContent}
                tableKey={`glossary`}
              />
              <Text style={styles.sectionSubtitleBody}>Disclaimer</Text>
              <Text style={styles.sectionDescription}>
                This report only reflects the preliminary flight paths for the
                single runway operations at WSI. It does not show other airport
                changes. The WSI flight paths are preliminary and may change as
                they are subject to ongoing environmental assessment and final
                detailed design. Some aircraft may fly at a lower altitude
                depending on weather and operational conditions.{" "}
              </Text>
              <Text style={styles.sectionDescription}>
                This document displays information from the Aircraft Overflight
                Noise Tool. The information is not in the nature of advice and
                should not be relied upon for personal, legal or financial
                decisions. You should make your own inquiries and seek
                independent advice in relation to any such personal, legal or
                financial decisions.{" "}
              </Text>
              <Text style={styles.sectionDescription}>
                ​As the tool is for consultation on preliminary flight paths,
                the Commonwealth accepts no legal liability for any loss or
                damage arising from the use of, or reliance upon, the
                information accessed or communicated via the tool.{" "}
              </Text>
            </View>
          </View>
        </View>
      </View>
      <PageFooter />
    </Page>
  );
};

export default GlossaryPage;
