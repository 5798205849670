import { Source, Layer } from "react-map-gl";
import getData from "../../../requests/getData";
import { useQuery } from "@tanstack/react-query";

function NoiseLayer({ noiseUrl,setNoiseContours }) {
  const { isError, data, error } = useQuery({
    queryKey: ["noise", noiseUrl],
    queryFn: async () => {
      const data = await getData(noiseUrl);
      return data;
    },
    cacheTime: "Infinity",
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    placeholderData: {
      type: "FeatureCollection",
      features: [],
    },
    onSuccess: (data) => {
      setNoiseContours(data);
    },
    onError: (error) => {
      console.error(error);
    },
  });

  if (isError) {
    console.error(error);
    return <></>;
  }

  return (
    <Source
      id="noise-contour-data"
      type="geojson"
      lineMetrics={true}
      data={data}
    >
      <Layer
        id="noise-contour-fill-layer"
        beforeId="runway-layer"
        type="fill"
        paint={{
          "fill-color": ["get", "fill-color"],
          "fill-opacity": ["get", "fill-opacity"],
        }}
      />
      <Layer
        id="noise-contour-line-layer"
        beforeId="runway-layer"
        type="line"
        paint={{
          "line-width": ["get", "outline-weight"],
          "line-color": ["get", "outline-color"],
          "line-opacity": ["get", "outline-opacity"],
        }}
      />
    </Source>
  );
}

export default NoiseLayer;
