import i18next from "i18next";
import i18tool from "../i18n";
import React, { useEffect, useRef } from "react";
import "../styles/LanguageOptions.css";

function LanguageOption({ id, name }) {
  function navigateToLanguage() {
    if (name !== i18next.t("language")) {
      window.location.href = "/?lng=" + id;
    }
  }
  return (
    <div
      className="lang-option"
      onClick={() => {
        navigateToLanguage();
      }}
    >
      <label
        className="lang-option-label"
        onClick={() => {
          navigateToLanguage();
        }}
      >
        {name} ({id.toUpperCase()})
      </label>
      <input
        name="checkbox"
        type="radio"
        className="lang-option-checkbox"
        checked={name === i18next.t("language.name")}
        onChange={() => {
          navigateToLanguage();
        }}
        tabIndex={0}
      />
    </div>
  );
}

function LanguageOptions({
  setOpenLangOptions,
  openLangOptions,
  setLanguageMenu,
}) {
  const outsideClickRef = useRef(true);

  useEffect(() => {
    const KeyboardClose = (e) => {
      if (e.key === "Escape") {
        setOpenLangOptions(false);
      }
    };

    const handleClickOutside = (e) => {
      if (!outsideClickRef.current.contains(e.target)) {
        setOpenLangOptions(false);
        setLanguageMenu("closed");
      }
    };

    document.addEventListener("keydown", KeyboardClose);

    document.addEventListener("click", handleClickOutside, true);

    return () => {
      document.removeEventListener("keydown", KeyboardClose);
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [setOpenLangOptions, openLangOptions, setLanguageMenu]);

  return (
    <div className="lang-options-container">
      <span ref={outsideClickRef} className="lang-options">
        <section className="language-section">
          <div className="lang-option-title">
            {i18tool.t("Select your desired language")}
          </div>
          <hr className="lang-opt-hr"></hr>
          <LanguageOption id="en" name="English" />
          <hr className="desktop-hr-tag"></hr>
          <LanguageOption id="hi" name="नहीं" />
          <hr className="desktop-hr-tag"></hr>
          <LanguageOption id="tl" name="Tagalog" />
          <hr className="desktop-hr-tag"></hr>
          <LanguageOption id="vi" name="Tiếng Việt" />
          <hr className="desktop-hr-tag"></hr>
          <LanguageOption id="zh" name="普通话" />
          <hr className="desktop-hr-tag"></hr>
          <LanguageOption id="ar" name="العربية" />
        </section>
        <section className="lang-button-section">
          <button
            id="lang-button"
            onClick={() => {
              setOpenLangOptions(false);
            }}
          >
            {i18tool.t("Select")}
          </button>
        </section>
      </span>
    </div>
  );
}

export default LanguageOptions;
