import i18next from "../../i18n";
import NoiseSearch from "./contents/NoiseSearch";
import FlightSearch from "./contents/FlightSearch";
import AirportChangeSearch from "./contents/AirportChangeSearch";
import { usePdfContext } from "../../hooks/provider/usePdfContext";
// styling imports
import closingIcon from "../../images/Map/closing-icon.svg";
import "../../styles/popups/AddressPopup.css";
import PdfLoader from "./contents/PdfLoader";
function AddressPopup({
  handleClosePopup,
  longitude,
  latitude,
  address,
  noiseContours,
  filterSelection,
  setVideoOpen,
  findVideoUrl,
  noticeability,
  mapType,
  selectedArrivalVectoring,
  selectedDepartureVectoring,
  setSelectedNonWsiSwathes,
  selectedNonWsiContour,
  setSelectedNonWsiContour,
}) {
  // Determine which content to show
  const showAirportChanges = mapType === "airport";
  const showNoise = mapType.endsWith("Noise");
  const showFlight = mapType === "flight";
  const { showPdfModal, setShowPdfModal } = usePdfContext();

  return (
    <div className="popup-wrapper">
      {address === null && (
        <div className="address-title">
          {i18next.t("No address result")}
          <img
            src={closingIcon}
            alt="closing icon"
            className="address-loc-marker"
            onClick={() => {
              handleClosePopup();
            }}
          />
        </div>
      )}
      {address !== null && (
        <div className="address-title">
          <b className="adress">{address["address"]}</b>
          <img
            src={closingIcon}
            alt="closing icon"
            className="address-loc-marker"
            onClick={() => {
              handleClosePopup();
            }}
          />
        </div>
      )}
      {!showAirportChanges && (
        <button
          className="pdf-report-button"
          onClick={() => {
            setShowPdfModal(true);
          }}
        >
          {showPdfModal === true ? (
            <>
              Generating report... <PdfLoader />
            </>
          ) : (
            "Generate property report"
          )}
        </button>
      )}
      {address !== null && showAirportChanges && (
        <AirportChangeSearch
          lon={longitude}
          lat={latitude}
          filterSelection={filterSelection}
          setSelectedNonWsiSwathes={setSelectedNonWsiSwathes}
          selectedNonWsiContour={selectedNonWsiContour}
          setSelectedNonWsiContour={setSelectedNonWsiContour}
        />
      )}
      {address !== null && showNoise && (
        <NoiseSearch
          lon={longitude}
          lat={latitude}
          noiseContours={noiseContours}
          filterSelection={filterSelection}
          noticeability={noticeability}
          mapType={mapType}
        />
      )}
      {address !== null && showFlight && (
        <FlightSearch
          lon={longitude}
          lat={latitude}
          filterSelection={filterSelection}
          setVideoOpen={setVideoOpen}
          findVideoUrl={findVideoUrl}
          selectedArrivalVectoring={selectedArrivalVectoring}
          selectedDepartureVectoring={selectedDepartureVectoring}
        />
      )}
    </div>
  );
}

export default AddressPopup;
