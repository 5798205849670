import React from "react";
import "../../../styles/map/Legend.css";

const legendItemStyle = {
  width: 15 + "px",
  height: 15 + "px",
  marginRight: 5 + "px",
  borderRadius: 2 + "px",
};

function LegendItem(props) {
  let style = { ...legendItemStyle };

  style.background = props.color;

  return (
    <dt className="colour-identifiers">
      <div style={style}></div>
      {props.name}
    </dt>
  );
}

export { legendItemStyle };

export default LegendItem;
