const arrival_track_color = "#FFEB77";
const arrival_track_opacity = 0.55;
const arrival_track_selected_opacity = 0.75;
const arrival_track_unselected_opacity = 0.15;
const departure_track_color = "#D5C2F0";
const departure_track_opacity = 0.55;
const departure_track_selected_opacity = 0.75;
const departure_track_unselected_opacity = 0.15;
const runway_color = "#FF0000";
const noise_color = "#F0E430";
const reduced_track_opacity = 0.3;

// Colors for airportChangeLayers
const current_track_color = {
  arrival: "#ffb4ec",
  departure: "#ffb4ec",
};
const current_track_opacity = [1, 1, 0]; // cases: no selection, group selected, not selected
const current_swathe_color = current_track_color;
const current_swathe_opacity = [0.25, 0.55, 0, 0.25, 0.65]; // cases: no selection, group selected, not selected, contour selected, single track selected
const current_vectoring_opacity = [0, 1, 0]; // cases: no selection, group selected, not selected
const proposed_track_color = {
  arrival: arrival_track_color,
  departure: departure_track_color,
};
const proposed_track_opacity = [1, 1, 0]; // cases: no selection, group selected, not selected
const proposed_swathe_color = proposed_track_color;
const proposed_swathe_opacity = [0.55, 0.75, 0, 0.1, 0.85]; // cases: no selection, group selected, not selected, contour selected, single track selected
const proposed_vectoring_opacity = [0, 1, 0, 1]; // cases: no selection, group selected, not selected, persistent

export {
  arrival_track_color,
  arrival_track_opacity,
  departure_track_color,
  departure_track_opacity,
  runway_color,
  noise_color,
  reduced_track_opacity,
  arrival_track_selected_opacity,
  arrival_track_unselected_opacity,
  departure_track_selected_opacity,
  departure_track_unselected_opacity,
  current_track_color,
  current_track_opacity,
  current_swathe_color,
  current_swathe_opacity,
  current_vectoring_opacity,
  proposed_track_color,
  proposed_track_opacity,
  proposed_swathe_color,
  proposed_swathe_opacity,
  proposed_vectoring_opacity,
};
