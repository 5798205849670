import { useEffect, useState } from 'react';

function useStringStorage(key, initialValue = '') {
  const [value, setValue] = useState(() => {
    const storedValue = sessionStorage.getItem(key);
    return storedValue !== null ? storedValue : initialValue;
  });

  useEffect(() => {
    if (value) {
      sessionStorage.setItem(key, value);
    } else {
      sessionStorage.removeItem(key);
    }
  }, [key, value]);

  return [value, setValue];
}

export default useStringStorage;
