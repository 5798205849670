import { Source, Layer } from "react-map-gl";
import getData from "../../../requests/getData";
import { useQuery } from "@tanstack/react-query";
import {
  arrival_track_color,
  arrival_track_opacity,
  arrival_track_selected_opacity,
  arrival_track_unselected_opacity,
} from "../../../colors";

function ArrivalLayer({
  layerId,
  beforeId,
  arrivalUrl,
  setArrivalPaths,
  selectedArrivalNumbers,
  selectedDepartureNumbers,
}) {
  const arrivalStyle = {
    type: "fill",
    paint: {
      "fill-color": arrival_track_color,
      "fill-opacity": arrival_track_opacity,
      "fill-selected-opacity": arrival_track_selected_opacity,
      "fill-unselected-opacity": arrival_track_unselected_opacity,
    },
    layout: {
      visibility: "visible",
    },
  };

  // Get the arrival data
  const { isError, data, error } = useQuery({
    queryKey: ["tracks", arrivalUrl],
    queryFn: async () => {
      const data = await getData(arrivalUrl);
      return data;
    },
    cacheTime: "Infinity",
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    placeholderData: {
      type: "FeatureCollection",
      features: [],
    },
    onSuccess: (data) => {
      setArrivalPaths(data);
    },
    onError: (error) => {
      console.error(error);
    },
  });

  if (isError) {
    console.error(error);
    return <></>;
  }

  // Determine the default opacity based on selected arrivals
  let baseOpacity =
    selectedArrivalNumbers.length === 0 && selectedDepartureNumbers.length === 0
      ? arrivalStyle.paint["fill-opacity"]
      : arrivalStyle.paint["fill-unselected-opacity"];

  return (
    <Source id="arrival-path-data" type="geojson" data={data}>
      <Layer
        source="arrival-path-data"
        id={layerId}
        beforeId={beforeId}
        type={arrivalStyle.type}
        paint={{
          "fill-color": arrivalStyle.paint["fill-color"],
          "fill-opacity": [
            "case",
            [
              "boolean",
              [
                "in",
                ["get", "subtrack_id"],
                ["literal", selectedArrivalNumbers],
              ],
              false,
            ],
            arrivalStyle.paint["fill-selected-opacity"],
            baseOpacity,
          ],
        }}
      />
    </Source>
  );
}

export default ArrivalLayer;
