import React from "react";
// styling imports
import closingIcon from "../../images/Map/closing-icon.svg";
import "../../styles/introduction/ToolHelpBox.css";
import version_string from "../../version";
import { Issues, Legal } from "../Legal";
import { changeLog } from "../../version";

function AboutSlide({ setShowIntro }) {
  return (
    <div
      className="helpbox-modal-wrapper legal"
      style={{ alignItems: "flex-start", overflowY: "scroll" }}
    >
      <section className="helpbox-left" style={{ padding: "30px 20px" }}>
        <h1>About the tool</h1>
        <p>
          Version: {version_string} <br />
          Developed by{" "}
          <a href="https://www.aerlabs.com" target="_blank" rel="noreferrer">
            AerLabs
          </a>
          {" in collaboration with "}
          <a href="https://www.to70.com" target="_blank" rel="noreferrer">
            To70 Aviation (Australia)
          </a>
        </p>
        <h2>Changelog</h2>
        {changeLog.map((change, changeId) => (
          <div key={changeId}>
            {change.date}:
            <ul>
              {change.text.map((message, messageId) => (
                <li key={messageId}>{message}</li>
              ))}
            </ul>
          </div>
        ))}
        <Issues />
        <Legal />
      </section>
      <div className="helpbox-slide-closing-icon-wrapper">
        <img
          src={closingIcon}
          alt="a closing icon in the shape of the letter X."
          className="helpbox-closing-icon"
          onClick={() => {
            setShowIntro(false);
          }}
          style={{
            cursor: "pointer",
          }}
        />
      </div>
    </div>
  );
}

export default AboutSlide;
