import React from "react";
import { Text, View } from "@react-pdf/renderer";
import styles from "../../documentStyles";

const TableHeader = ({ headerLabels, cellWidth, tableKey }) => {
  return (
    <View style={styles.tableRow}>
      {headerLabels.map((header, index) => (
        <Text
          key={`${tableKey}_header_${index}`}
          style={{ ...styles.tableHeaderCell, width: cellWidth[index] }}
        >
          {header}
        </Text>
      ))}
    </View>
  );
};

const TableBody = ({
  tableContent,
  bodyKeys,
  tableKey,
  cellWidth,
  lastColumnLeftAligned,
}) => {
  return (
    <>
      {tableContent.map((item, index) => (
        <View key={`${tableKey}_row_${index}`} style={styles.tableRow}>
          {bodyKeys.map((key, index2) => (
            <Text
              key={`${tableKey}_row_${index}_${index2}`}
              style={{
                ...styles.tableCell,
                width: cellWidth[index2],
                textAlign:
                  index2 === bodyKeys.length - 1 && lastColumnLeftAligned
                    ? "left"
                    : "center",
              }}
            >
              {item[key]}
            </Text>
          ))}
        </View>
      ))}
    </>
  );
};

const Table = ({
  headerLabels,
  bodyKeys,
  cellWidth,
  tableContent,
  tableKey,
  lastColumnLeftAligned = false,
}) => {
  return (
    <View style={styles.table}>
      {headerLabels && (
        <TableHeader
          headerLabels={headerLabels}
          cellWidth={cellWidth}
          tableKey={tableKey}
        />
      )}
      <TableBody
        tableContent={tableContent}
        bodyKeys={bodyKeys}
        tableKey={tableKey}
        cellWidth={cellWidth}
        lastColumnLeftAligned={lastColumnLeftAligned}
      />
    </View>
  );
};

export const TracksTable = ({ tableContent, tableKey }) => {
  const tracksTableHeadersLabels = [
    "Track name",
    "Altitude (feet) above runway level​",
  ];
  const tracksTableBodyKeys = ["trackName", "altitude"];
  const trackTableCellWidth = tracksTableHeadersLabels.map((label) => {
    return `${100 / tracksTableHeadersLabels.length}%`;
  });

  return (
    <Table
      headerLabels={tracksTableHeadersLabels}
      bodyKeys={tracksTableBodyKeys}
      cellWidth={trackTableCellWidth}
      tableContent={tableContent}
      tableKey={tableKey}
    />
  );
};

export const NoiseTable = ({ tableContent, tableKey }) => {
  const noiseTableHeadersLabels = [
    "Flights above N decibels",
    "No Preference",
    "Prefer RWY05",
    "Prefer RWY23",
  ];
  const noiseTableBodyKeys = ["metric", "noPreference", "prefer05", "prefer23"];
  const noiseTableCellWidth = noiseTableHeadersLabels.map((label) => {
    return `${100 / noiseTableHeadersLabels.length}%`;
  });

  return (
    <Table
      headerLabels={noiseTableHeadersLabels}
      bodyKeys={noiseTableBodyKeys}
      cellWidth={noiseTableCellWidth}
      tableContent={tableContent}
      tableKey={tableKey}
    />
  );
};

export const GlossaryTable = ({ tableContent, tableKey }) => {
  const glossaryTableBodyKeys = ["value", "definition"];
  const gloassaryTableCellWidth = ["20%", "80%"];

  return (
    <Table
      bodyKeys={glossaryTableBodyKeys}
      cellWidth={gloassaryTableCellWidth}
      tableContent={tableContent}
      tableKey={tableKey}
      lastColumnLeftAligned={true}
    />
  );
};

export default Table;
