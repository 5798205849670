// I have created this file to store all the context components related to management states/files

import { createContext, useState } from "react";

export const PdfContext = createContext(undefined);

export const PdfContextProvider = ({ children }) => {
  const [showPdfModal, setShowPdfModal] = useState();

  return (
    <PdfContext.Provider value={[showPdfModal, setShowPdfModal]}>
      {children}
    </PdfContext.Provider>
  );
};
