import React from "react";
import i18next from "../../i18n";
import { Trans } from "react-i18next";
// styling imports
import forwardIcon from "../../images/icons/forward-arrow-icon.png";
import slide1 from "../../images/slides/Intro Screen - 1.png";
import slide2 from "../../images/slides/Intro Screen - 2.png";
import slide3 from "../../images/slides/Intro Screen - 3.png";
import slide4 from "../../images/slides/Intro Screen - 4.png";
import "../../styles/introduction/Slide.css";

function Slide({
  setSlideSelected,
  setShowIntro,
  screenshot,
  caption,
  previousSlide,
  nextSlide,
  slideSelected,
}) {
  const hasPrevious = previousSlide !== undefined;
  const hasNext = nextSlide !== undefined;

  function visitPreviousSlide() {
    if (hasPrevious) {
      setSlideSelected(previousSlide);
    }
  }
  function visitNextSlide() {
    if (hasNext) {
      setSlideSelected(nextSlide);
    }
  }

  const renderIcon = (slide) => {
    switch (slide) {
      case "slide1":
        return (
          <img
            src={slide1}
            alt="a plane icon with an curved line next to it."
            className="modal-picture"
          />
        );
      case "slide2":
        return <img src={slide2} alt="a rectangular warning sign." />;
      case "slide3":
        return (
          <img
            src={slide3}
            alt="a line with multiple points leading to an end location/point."
          />
        );
      case "slide4":
        return (
          <img
            src={slide4}
            alt="a line with multiple points leading to an end location/point."
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="modal-wrapper">
      <div className="modal-intro-screenshot-small-screen">
        {renderIcon(slideSelected)}
      </div>
      <div className="slide-modal">
        <div
          className={
            "modal-arrow-wrapper " + (hasPrevious && "modal-arrow-active")
          }
          onClick={visitPreviousSlide}
        >
          {previousSlide && (
            <img
              src={forwardIcon}
              alt="an arrow icon that points to the left"
              className="modal-left-arrow"
            />
          )}
        </div>
        <section className="modal-content">
          <div className="modal-intro-screenshot-large-screen">
            {renderIcon(slideSelected)}
          </div>
          <div className="modal-intro-text">
            {
              <Trans
                i18nKey={caption}
                // eslint-disable-next-line
                components={{ italic: <i />, bold: <strong /> }}
              />
            }
          </div>
          <div className="modal-intro-footer">
            {nextSlide ? (
              <a
                href="#skipping-intro"
                onClick={() => {
                  setShowIntro(false);
                }}
              >
                {i18next.t("intro.skip")}
              </a>
            ) : (
              <button
                className="modal-end-button"
                onClick={() => {
                  setShowIntro(false);
                }}
              >
                {i18next.t("intro.start")}
              </button>
            )}
          </div>
        </section>
        <div
          className={"modal-arrow-wrapper " + (hasNext && "modal-arrow-active")}
          onClick={visitNextSlide}
        >
          {nextSlide && (
            <img
              src={forwardIcon}
              alt="an arrow icon that points to the right"
              className="modal-right-arrow"
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Slide;
