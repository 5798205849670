import PropTypes from "prop-types";
import React, { useState } from "react";
import { MdClose } from "react-icons/md";
import i18next from "../i18n";
import questionIcon from "../images/icons/question-mark.png";
import "../styles/Filters.css";
import AirportFilterGroup from "./filters/AirportChangeFilterGroup";
import FlightFilterGroup from "./filters/FlightFilterGroup";
import NoiseFilterGroup from "./filters/NoiseFilterGroup";

function GroupTitle({
  title,
  groupType,
  mapType,
  setMapType,
  setLegendDescription,
  setSelectedNonWsiSwathes,
  setSelectedNonWsiContour,
}) {
  // Determine if the group is active
  const active = mapType.endsWith(groupType);

  // Set the default mapType
  const defaultMapType = groupType === "Noise" ? "cumulativeNoise" : groupType;

  return (
    <span className="checkbox-container">
      <input
        type="checkbox"
        className="filter-checkboxes"
        checked={active}
        onChange={() => {
          if (!active) {
            setMapType(defaultMapType);
            setLegendDescription(false);
            setSelectedNonWsiSwathes([]);
            setSelectedNonWsiContour({ track_id: null, metric: "N60" });
          }
        }}
        onKeyDown={(e) => {
          if ((e.key === "Enter") & !active) {
            setMapType(defaultMapType);
            setLegendDescription(false);
            setSelectedNonWsiSwathes([]);
            setSelectedNonWsiContour({ track_id: null, metric: "N60" });
          }
        }}
      />
      <label
        className="checkbox-label"
        onClick={() => {
          if (!active) {
            setMapType(defaultMapType);
            setLegendDescription(false);
            setSelectedNonWsiSwathes([]);
            setSelectedNonWsiContour({ track_id: null, metric: "N60" });
          }
        }}
      >
        {title}
      </label>
    </span>
  );
}

function Filters({
  filterConfigurations,
  filterSelection,
  setFilterSelection,
  mobileFiltering,
  showMobileFiltering,
  setLegendDescription,
  mapType,
  setMapType,
  setSlideSelected,
  setShowIntro,
  setShowHelpArrow,
  setSelectedArrivals,
  setSelectedDepartures,
  setSelectedArrivalVectoring,
  setSelectedDepartureVectoring,
  setSelectedNonWsiSwathes,
  setSelectedNonWsiContour,
}) {
  // for making the info texts exclusive
  const [activeIndex, setActiveIndex] = useState(null);


  const toggleText = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  return (
    <aside id="filter-wrapper" mobilefiltering={mobileFiltering}>
      <div className="filtering-title-wrapper">
        <h1 id="application-title">{i18next.t("application.title")}</h1>
        {mobileFiltering === 0 && (
          <MdClose
            onClick={() => {
              showMobileFiltering(1);
            }}
            size="27"
            id="filter-close-logo"
          />
        )}{" "}
      </div>
      <GroupTitle
        title={i18next.t("filters.flightpaths.title")}
        groupType="flight"
        setLegendDescription={setLegendDescription}
        mapType={mapType}
        setMapType={setMapType}
        setSelectedNonWsiSwathes={setSelectedNonWsiSwathes}
        setSelectedNonWsiContour={setSelectedNonWsiContour}
      />
      <section className="filter-group-wrapper">
        {mapType === "flight" && (
          <FlightFilterGroup
            filterConfigurations={filterConfigurations}
            filterSelection={filterSelection}
            setFilterSelection={setFilterSelection}
            activeIndex={activeIndex}
            toggleText={toggleText}
            setSelectedArrivals={setSelectedArrivals}
            setSelectedDepartures={setSelectedDepartures}
            setSelectedArrivalVectoring={setSelectedArrivalVectoring}
            setSelectedDepartureVectoring={setSelectedDepartureVectoring}
          />
        )}
      </section>
      <hr className="filter-hr" />
      <GroupTitle
        title={i18next.t("filters.noise.title")}
        groupType="Noise"
        setLegendDescription={setLegendDescription}
        mapType={mapType}
        setMapType={setMapType}
        setSelectedNonWsiSwathes={setSelectedNonWsiSwathes}
        setSelectedNonWsiContour={setSelectedNonWsiContour}
      />
      <section className="filter-group-wrapper">
        {mapType.endsWith("Noise") && (
          <NoiseFilterGroup
            filterConfigurations={filterConfigurations}
            filterSelection={filterSelection}
            setFilterSelection={setFilterSelection}
            mapType={mapType}
            setMapType={setMapType}
            activeIndex={activeIndex}
            toggleText={toggleText}
          />
        )}
      </section>
      <hr className="filter-hr" />
      <GroupTitle
        title={i18next.t("filters.airportChanges.title")}
        groupType="airport"
        setLegendDescription={setLegendDescription}
        mapType={mapType}
        setMapType={setMapType}
        setSelectedNonWsiSwathes={setSelectedNonWsiSwathes}
        setSelectedNonWsiContour={setSelectedNonWsiContour}
      />
      <section className="filter-group-wrapper">
        {mapType === "airport" && (
          <AirportFilterGroup
            filterConfigurations={filterConfigurations}
            filterSelection={filterSelection}
            setFilterSelection={setFilterSelection}
            activeIndex={activeIndex}
            toggleText={toggleText}
            setSelectedNonWsiSwathes={setSelectedNonWsiSwathes}
            setSelectedNonWsiContour={setSelectedNonWsiContour}
          />
        )}
      </section>
      <hr className="filter-hr" />
      <div className="filter-footer-wrapper">
        <div className="filter-footer">
          <div className="filter-footer-help-wrapper">
            <img
              src={questionIcon}
              alt="a question mark icon in a speech bubble."
              className="filter-footer-help-icon"
            />
            <u
              className="filter-footer-help-text"
              onClick={() => {
                setShowIntro(true);
                setSlideSelected("tool-help-box");
                setShowHelpArrow(false);
              }}
            >
              {i18next.t("filters.help.text")}
            </u>
          </div>
        </div>
        {mobileFiltering === 0 && (
          <button
            className="filter-closing-btn"
            onClick={() => {
              showMobileFiltering(1);
            }}
          >
            {i18next.t("Done")}
          </button>
        )}
      </div>
    </aside>
  );
}

Filters.propTypes = {
  setFilterSelection: PropTypes.func.isRequired,
};
export default Filters;
