import React from "react";
import { VscListFilter } from "react-icons/vsc";
import i18next from "../i18n";
import "../styles/ShowMobileFiltersButton.css";

function ShowMobileFiltersButton({ showMobileFiltering }) {
  return (
    <div className="show-mobile-filters-container">
      <div className="show-mobile-filters-button-container">
        <button
          className={"show-mobile-filters-button"}
          onClick={() => {
            showMobileFiltering(0);
          }}
        >
          {i18next.t("Filters")} <VscListFilter color="white" />
        </button>
      </div>
    </div>
  );
}

export default ShowMobileFiltersButton;
