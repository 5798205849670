import { useQuery } from "@tanstack/react-query";
import { Layer, Source } from "react-map-gl";
import {
  current_track_color,
  current_track_opacity,
  proposed_track_color,
  proposed_track_opacity,
} from "../../../colors";
import getData from "../../../requests/getData";

function ChangeTrackLayer({
  layerId,
  beforeId,
  trackUrl,
  changeType,
  selectedNonWsiSwathes,
}) {
  const currentType = changeType === "current";
  const proposedType = changeType === "proposed";

  // Get the selected track ids
  const selectedTrackIds = selectedNonWsiSwathes.map((f) => f["track_id"]);

  if (!(currentType || proposedType)) {
    console.error(
      changeType +
        " (changeType) is not valid. It should be 'current' or 'proposed'."
    );
  }

  // Get the colors
  const track_color = currentType ? current_track_color : proposed_track_color;

  // Get the opacities
  const track_opacities = currentType
    ? current_track_opacity
    : proposed_track_opacity;
  const default_track_opacity =
    selectedNonWsiSwathes.length > 0 ? track_opacities[2] : track_opacities[0];

  // Get the arrival data
  const { isError, data, error } = useQuery({
    queryKey: ["changeTrack", trackUrl],
    queryFn: async () => {
      const data = await getData(trackUrl);
      return data;
    },
    cacheTime: "Infinity",
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    placeholderData: {
      type: "FeatureCollection",
      features: [],
    },
    onError: (error) => {
      console.error(error);
    },
  });

  if (isError) {
    console.error(error);
    return <></>;
  }
  // Set the source id
  const sourceId = layerId + "-data";

  return (
    <Source id={sourceId} type="geojson" lineMetrics={true} data={data}>
      <Layer
        source={sourceId}
        id={layerId}
        beforeId={beforeId}
        type="line"
        paint={{
          "line-color": [
            "case",
            ["==", ["get", "movement_type"], ["literal", "Arrival"]],
            track_color["arrival"],
            track_color["departure"],
          ],
          "line-width": 5,
          "line-opacity": [
            "case",
            [
              "boolean",
              ["in", ["get", "track_id"], ["literal", selectedTrackIds]],
              false,
            ],
            track_opacities[1],
            default_track_opacity,
          ],
        }}
        filter={["==", ["geometry-type"], "LineString"]}
      />
      <Layer
        source={sourceId}
        id={"outline-" + layerId}
        beforeId={beforeId}
        type="fill"
        paint={{
          "fill-opacity": 0,
        }}
        filter={["==", ["geometry-type"], "Polygon"]}
      />
    </Source>
  );
}

export default ChangeTrackLayer;
