import i18next from "../../../i18n";
import noiseIcon from "../../../images/popups/noiseIcon.png";
import "../../../styles/map/MapComponent.css";

function NoiseSearchComponent({ noiseText, anecFooter }) {
  return (
    <>
      <div className="popup-icon-text-wrapper">
        {" "}
        <img src={noiseIcon} alt="noise wave." className="pop-up-flight-icon" />
        {noiseText}
      </div>
      {anecFooter ? (
        <>
          <span className="popup-text">
            {i18next.t("popup.noise.anef.comment")}
          </span>
          <br />
          <br />
          <span className="popup-text">
            {i18next.t("popup.noise.anef.footer")}
          </span>
        </>
      ) : (
        <span className="popup-text">{i18next.t("popup.noise.footer")}</span>
      )}
    </>
  );
}

export default NoiseSearchComponent;
