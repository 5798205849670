import React, { useEffect, useState } from "react";
import i18next from "../../i18n";
import lightOption from "../../images/Legend/light-map-option.png";
import satelliteOption from "../../images/Legend/satellite-map-option.jpg";
import "../../styles/map/Legend.css";
import FlightLegend from "./legend/FlightLegend";
import NoiseLegend from "./legend/NoiseLegend";
import AirportChangeLegend from "./legend/AirportChangeLegend";

function Legend({
  mapOption,
  setMapOption,
  mapType,
  filterSelection,
  noiseContours,
  legendDescription,
  setLegendDescription,
  selectedNonWsiSwathes,
  selectedNonWsiContour,
  contourUrl,
  currentVectoringUrl,
  proposedVectoringUrl,
}) {
  const [currentColours, setCurrentColours] = useState(null);

  // Determine which content to show
  const showAirportChanges = mapType === "airport";
  const showNoise = mapType.endsWith("Noise");
  const showFlight = mapType === "flight";

  useEffect(() => {
    if (noiseContours !== null) {
      let colours = noiseContours.features;
      setCurrentColours(colours);
    }
  }, [filterSelection, noiseContours]);

  // Construct the legend title (key to the translation file)
  let legendTitle = "legend." + mapType;
  if (mapType === "cumulativeNoise") {
    legendTitle =
      "legend.noise." + filterSelection["noiseMetric"].toLowerCase();
  } else if (mapType === "eventNoise") {
    legendTitle =
      "legend.eventNoise." +
      filterSelection["eventNoiseAircraft"].toLowerCase();
  }

  return (
    <>
      {(showFlight || (showNoise && currentColours) || showAirportChanges) && (
        <div className="legend-container">
          <div className="legend">
            {showFlight && (
              <FlightLegend
                legendDescription={legendDescription}
                setLegendDescription={setLegendDescription}
              />
            )}
            {showNoise && (
              <NoiseLegend
                currentColours={currentColours}
                legendTitle={legendTitle}
                legendDescription={legendDescription}
                setLegendDescription={setLegendDescription}
              />
            )}
            {showAirportChanges && (
              <AirportChangeLegend
                filterSelection={filterSelection}
                legendDescription={legendDescription}
                setLegendDescription={setLegendDescription}
                selectedNonWsiSwathes={selectedNonWsiSwathes}
                selectedNonWsiContour={selectedNonWsiContour}
                contourUrl={contourUrl}
                currentVectoringUrl={currentVectoringUrl}
                proposedVectoringUrl={proposedVectoringUrl}
              />
            )}
          </div>
          <section className="map-option-container">
            <span id="option-overlay-text">
              {mapOption === "Light"
                ? i18next.t("Satellite")
                : i18next.t("Light")}
            </span>
            <div className="map-option-wrapper">
              {mapOption === "Light" ? (
                <img
                  src={satelliteOption}
                  alt="a map displayed in satellite view."
                  className="map-option-img"
                  tabIndex={0}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      setMapOption("Satellite");
                    }
                  }}
                  onClick={() => {
                    setMapOption("Satellite");
                  }}
                />
              ) : (
                <img
                  src={lightOption}
                  alt="a map displayed in light mode."
                  className="map-option-img"
                  tabIndex={0}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      setMapOption("Light");
                    }
                  }}
                  onClick={() => {
                    setMapOption("Light");
                  }}
                />
              )}
            </div>
          </section>
        </div>
      )}
    </>
  );
}

export default Legend;
