import i18next from "i18next";
import usageIcon from "../../../images/popups/usage-icon.png";

export function UsageSummary({ trackId }) {
  return (
    <div className="popup-icon-summary-text-wrapper">
      <img
        src={usageIcon}
        alt="an icon where two planes are going around in circles."
        className="pop-up-flight-icon"
      />
      <p className="popup-paragraph">
        <strong>{i18next.t("popup.airportChange.summary.frequency")}</strong> -{" "}
        {i18next.t("popup.airportChange." + trackId + ".frequency")}
      </p>
    </div>
  );
}
