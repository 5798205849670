import React, { useState, useEffect, useRef } from "react";
import Map, {
  FullscreenControl,
  NavigationControl,
  Marker,
} from "react-map-gl";
import mapboxgl from "mapbox-gl";
// component imports
import Legend from "./Legend";
import ShowMobileFiltersButton from "../ShowMobileFiltersButton";
import shadingArrivals from "../../images/Map/diagonal-stripes-arrivals.png";
import shadingDepartures from "../../images/Map/diagonal-stripes-departures.png";
import shadingCurrent from "../../images/Map/diagonal-stripes-current.png";

// request imports
import getData from "../../requests/getData";
import {
  airportPattern,
  cumulativeNoisePattern,
  eventNoisePattern,
  trackPattern,
  altitudeSegments,
} from "../../requests/register";
import { getNoticeability, findElectorate } from "../../requests/getData";
// auxiliary functions to calculate address search results
import useOutsideClick from "../../hooks/useOutsideClick";

// styling imports
import "../../styles/map/MapComponent.css";
import "mapbox-gl/dist/mapbox-gl.css";
import arrowImage from "../../images/Map/track_arrow.png";
import blackLocationIcon from "../../images/popups/location-icon.png";
import blueLocationIcon from "../../images/popups/location-icon-white+outline-outer.png";

import ReactGA from "react-ga4";
import AddressPopup from "../popups/AddressPopup";
import TracksPopup from "../popups/TracksPopup";

import {
  airportCombinations,
  cumulativeNoiseCombinations,
  eventNoiseCombinations,
  trackCombinations,
} from "../../requests/register";

import AirportBoundaryLayer from "./layers/AirportBoundaryLayer";
import RunwayLayer from "./layers/RunwayLayer";
import RunwayLabelLayer from "./layers/RunwayLabelLayer";
import NoiseLayer from "./layers/NoiseLayer";
import FlightLayers from "./layers/FlightLayers";
import AirportChangeLayers from "./layers/AirportChangeLayers";

const TOKEN = process.env.REACT_APP_MAP_TOKEN;

const initialPosition = {
  longitude: 150.730124833877,
  latitude: -33.8748525983074,
  zoom: 8,
};

function replaceStartOfString(inputString, searchString, replacementString) {
  if (inputString.startsWith(searchString)) {
    return replacementString + inputString.slice(searchString.length);
  }
  return inputString;
}

function MapComponent({
  lat,
  lon,
  setLat,
  setLon,
  address,
  setAddress,
  madeSearch,
  showMobileFiltering,
  filterSelection,
  legendDescription,
  setLegendDescription,
  mapType,
  setVideoOpen,
  setVideoUrl,
  setVideoAircraft,
  openTracksPopup,
  arrivalPaths,
  departurePaths,
  setArrivalPaths,
  setDeparturePaths,
  flightPathAltitudeIds,
  setFlightPathAltitudeIds,
  setArrivalVectoring,
  setDepartureVectoring,
  selectedArrivals,
  selectedDepartures,
  setSelectedArrivals,
  setSelectedDepartures,
  selectedArrivalVectoring,
  selectedDepartureVectoring,
  setSelectedArrivalVectoring,
  setSelectedDepartureVectoring,
  setSelectedArrivalVectoringIndices,
  setSelectedDepartureVectoringIndices,
  selectedArrivalVectoringIndices,
  selectedDepartureVectoringIndices,
  setCleanSearchBar,
  selectedNonWsiSwathes,
  setSelectedNonWsiSwathes,
  setSelectedNonWsiPoint,
  selectedNonWsiContour,
  setSelectedNonWsiContour,
}) {
  // Set the data for the layers
  const [noticeability, setNoticeability] = useState(null);
  const [noiseContours, setNoiseContours] = useState(null);

  const [mapOption, setMapOption] = useState("Light");
  const [viewState, setViewState] = useState(initialPosition);

  const outsideClick = useRef(null);
  const mapRef = useRef();
  useOutsideClick(outsideClick, setVideoOpen);

  // Get the appropriate register
  let register, registerPattern;
  if (mapType === "flight") {
    register = trackCombinations;
    registerPattern = trackPattern;
  } else if (mapType === "cumulativeNoise") {
    register = cumulativeNoiseCombinations;
    registerPattern = cumulativeNoisePattern;
  } else if (mapType === "eventNoise") {
    register = eventNoiseCombinations;
    registerPattern = eventNoisePattern;
  } else if (mapType === "airport") {
    register = airportCombinations;
    registerPattern = airportPattern;
  }

  // Create the combination id
  let combination_id = registerPattern
    .map((key) => filterSelection[key])
    .join("_");

  // Get the combination
  let combination = register[combination_id];

  // Get the urls
  let noiseUrl,
    arrivalUrl,
    departureUrl,
    arrivalBackboneUrl,
    departureBackboneUrl,
    arrivalVectoringUrl,
    departureVectoringUrl,
    airportRunwayUrl,
    airportBoundaryUrl,
    currentTrackUrl,
    currentVectoringUrl,
    currentContourUrl,
    currentSwatheUrl,
    proposedTrackUrl,
    proposedVectoringUrl,
    proposedContourUrl,
    proposedSwatheUrl;
  if (mapType === "cumulativeNoise") {
    noiseUrl =
      combination[filterSelection["noiseMetric"].toLowerCase() + "_url"];
  } else if (mapType === "eventNoise") {
    noiseUrl = combination;
  } else if (mapType === "flight") {
    arrivalUrl = combination["arrivals"];
    departureUrl = combination["departures"];
    arrivalBackboneUrl = combination["arrivalsBackbones"];
    departureBackboneUrl = combination["departuresBackbones"];
    arrivalVectoringUrl = combination["arrivalVectoring"];
    departureVectoringUrl = combination["departureVectoring"];
  } else if (mapType === "airport") {
    airportRunwayUrl = combination["runway"];
    airportBoundaryUrl = combination["airportBoundaries"];
    currentTrackUrl = combination["currentTrack"];
    currentSwatheUrl = combination["currentSwathe"];
    currentVectoringUrl = combination["currentVectoring"];
    currentContourUrl = combination["currentContours"];
    proposedTrackUrl = combination["proposedTrack"];
    proposedSwatheUrl = combination["proposedSwathe"];
    proposedVectoringUrl = combination["proposedVectoring"];
    proposedContourUrl = combination["proposedContours"];
  }

  useEffect(() => {
    // Set the flight path and noise data to visualise
    async function setDataLayers() {
      // Get the noticeability contours once
      if (noticeability === null) {
        await getNoticeability().then((data) => setNoticeability(data));
      }

      // Move to address on search
      if (lat !== null && lon !== null && madeSearch) {
        setViewState({
          longitude: lon,
          latitude: lat,
        });
      }
    }
    setDataLayers();

    if (lat !== null && lon !== null) {
      openTracksPopup([lon, lat], true);
    }
  }, [filterSelection, lat, lon, madeSearch, noticeability, openTracksPopup]);

  const southWest = new mapboxgl.LngLat(140.899268, -37.528015);
  const northEast = new mapboxgl.LngLat(159.339035, -28.141525);
  const boundingBox = new mapboxgl.LngLatBounds(southWest, northEast);

  const findVideoUrl = async (subtrackId) => {
    const contour = await getData(
      altitudeSegments[`subtrack_id_${subtrackId}`]
    );

    // Get video url and aircrfat name
    let url = contour.features[0].properties.video_url;
    let aircraft = contour.features[0].properties.aircraft;

    setVideoUrl(url);
    setVideoAircraft(aircraft);
  };

  const onMove = (event) => {
    setViewState({
      longitude: event.viewState.longitude,
      latitude: event.viewState.latitude,
      zoom: event.viewState.zoom,
    });
  };

  const fetchCoordinatesFromMapbox = async (lon, lat) => {
    try {
      const response = await fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${lon},${lat}.json?country=au&limit=1&access_token=${TOKEN}&types=address%2Cplace%2Cpostcode`
      );

      const data = await response.json();

      // Get the address
      const searchResult = data.features[0];

      if (searchResult === undefined) {
        setAddress(null);
      } else {
        // Get the placename
        let placename = searchResult.place_name;

        // Get the postcode
        let postcode;
        if (searchResult.place_type[0] === "postcode") {
          postcode = searchResult.text;

          // Check if a placename is available
          const placeObj = searchResult.context.find((item) =>
            item.id.startsWith("place.")
          );

          // Replace the postcode with the actual placename in the display
          if (placeObj) {
            placename = replaceStartOfString(
              placename,
              searchResult.text,
              placeObj.text
            );
          }
        } else {
          // Get the a postcode if it is available in the context
          const postcodeObj = searchResult.context.find((item) =>
            item.id.startsWith("postcode.")
          );

          // Set the postcode if possible
          postcode = postcodeObj ? postcodeObj.text : null;
        }

        // Set the state with the selected address
        setAddress({
          type: searchResult.place_type[0],
          name: searchResult.text,
          address: placename,
        });

        // Get the electorate
        await findElectorate(lon, lat).then(function (electorate) {
          // Send search event to Google Analytics
          ReactGA.event("select_address", {
            postcode: postcode,
            electorate: electorate,
          });
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const openAddressPopup = (event) => {
    event.preventDefault();

    const lonlat = event.lngLat.toArray();

    setLat(lonlat[1]);
    setLon(lonlat[0]);
    fetchCoordinatesFromMapbox(lonlat[0], lonlat[1]);
  };

  const handleCloseAddressPopup = (event) => {
    setSelectedNonWsiSwathes([]);
    setSelectedNonWsiContour({ track_id: null, metric: "N60" });
    setSelectedArrivals([]);
    setSelectedDepartures([]);
    setSelectedArrivalVectoring(null);
    setSelectedDepartureVectoring(null);
    setSelectedArrivalVectoringIndices(null);
    setSelectedDepartureVectoringIndices(null);
    setFlightPathAltitudeIds([]);
    setLat(null);
    setLon(null);
    setAddress(null);
    setCleanSearchBar(true);
  };

  const handleClosePopupTracks = (event) => {
    setSelectedNonWsiSwathes([]);
    setSelectedNonWsiContour({ track_id: null, metric: "N60" });
    setSelectedArrivals([]);
    setSelectedDepartures([]);
    setSelectedArrivalVectoring(null);
    setSelectedDepartureVectoring(null);
    setSelectedArrivalVectoringIndices(null);
    setSelectedDepartureVectoringIndices(null);
    setFlightPathAltitudeIds([]);
  };

  // Get the selected arrivals and departures as numbers
  let selectedArrivalNumbers = Object.keys(selectedArrivals).map(Number);
  let selectedDepartureNumbers = Object.keys(selectedDepartures).map(Number);

  // Get the location marker
  let styledLocationIcon =
    mapOption === "Light" ? blackLocationIcon : blueLocationIcon;

  // Determine which map content to show
  const showAirportChanges = mapType === "airport";
  const showNoise = mapType.endsWith("Noise");
  const showFlight = mapType === "flight";

  // Determine which popup to show
  const addressSelected = lat && lon;
  const arrivalsSelected =
    typeof selectedArrivals === "object" &&
    Object.keys(selectedArrivals).length > 0;
  const departuresSelected =
    typeof selectedDepartures === "object" &&
    Object.keys(selectedDepartures).length > 0;
  const showTracksPopup =
    !addressSelected &&
    (arrivalsSelected || departuresSelected || showAirportChanges);
  const showAddressPopup =
    addressSelected && (noiseContours || arrivalPaths || departurePaths);

  return (
    <section className="map-section">
      <Map
        {...viewState}
        ref={mapRef}
        onMove={onMove}
        onLoad={(map) => {
          map.target.on(
            "click",
            [
              "current-swathes-layer",
              "proposed-swathes-layer",
              "outline-current-tracks-layer",
              "outline-proposed-tracks-layer",
            ],
            (e) => {
              // Extract the track properties
              const trackProperties = e.features.flatMap((f) => f.properties);
              // Update the states
              setSelectedNonWsiSwathes(trackProperties);
              setSelectedNonWsiPoint(e.lngLat.toArray());
            }
          );
          map.target.doubleClickZoom.disable();
        }}
        onStyleData={(map) => {
          map.target.loadImage(arrowImage, (error, image) => {
            if (error) throw error;
            // Only add the image if it is not there yet
            if (!map.target.hasImage("arrow")) {
              map.target.addImage("arrow", image);
            }
          });
          map.target.loadImage(shadingArrivals, (error, image) => {
            if (error) throw error;
            // Only add the image if it is not there yet
            if (!map.target.hasImage("shadingArrivals")) {
              map.target.addImage("shadingArrivals", image, {
                pixelRatio: 8,
              });
            }
          });
          map.target.loadImage(shadingDepartures, (error, image) => {
            if (error) throw error;
            // Only add the image if it is not there yet
            if (!map.target.hasImage("shadingDepartures")) {
              map.target.addImage("shadingDepartures", image, {
                pixelRatio: 8,
              });
            }
          });
          map.target.loadImage(shadingCurrent, (error, image) => {
            if (error) throw error;
            // Only add the image if it is not there yet
            if (!map.target.hasImage("shadingCurrent")) {
              map.target.addImage("shadingCurrent", image, {
                pixelRatio: 8,
              });
            }
          });
        }}
        mapboxAccessToken={TOKEN}
        initialViewState={initialPosition}
        style={{
          width: "100%",
          height: "100%",
        }}
        mapStyle={
          mapOption === "Light"
            ? "mapbox://styles/rpfk/cllc2exrs00tk01pbgwgubyd8"
            : "mapbox://styles/mapbox/satellite-streets-v12"
        }
        maxBounds={boundingBox}
        onDblClick={(event) => {
          openAddressPopup(event);
          openTracksPopup(event.lngLat.toArray(), true);
        }}
        onClick={(event) => openTracksPopup(event.lngLat.toArray())}
      >
        {/* react map gl components */}
        <FullscreenControl
          position="top-right"
          className="nav-component-wrapper"
        />
        <NavigationControl
          position="top-right"
          className="nav-component-wrapper"
        />

        {/* Runway data layer */}
        <RunwayLayer />
        <RunwayLabelLayer />

        {/* Airport boundary data layer */}
        <AirportBoundaryLayer mapOption={mapOption} />

        {/* Airport flight path changes data layers */}
        {showAirportChanges && (
          <AirportChangeLayers
            airportRunwayUrl={airportRunwayUrl}
            airportBoundaryUrl={airportBoundaryUrl}
            currentTrackUrl={currentTrackUrl}
            currentSwatheUrl={currentSwatheUrl}
            currentVectoringUrl={currentVectoringUrl}
            currentContourUrl={currentContourUrl}
            proposedTrackUrl={proposedTrackUrl}
            proposedSwatheUrl={proposedSwatheUrl}
            proposedVectoringUrl={proposedVectoringUrl}
            proposedContourUrl={proposedContourUrl}
            selectedNonWsiSwathes={selectedNonWsiSwathes}
            selectedNonWsiContour={selectedNonWsiContour}
            mapOption={mapOption}
          />
        )}

        {/* Flight path data layers */}
        {showFlight && (
          <FlightLayers
            arrivalUrl={arrivalUrl}
            departureUrl={departureUrl}
            setArrivalPaths={setArrivalPaths}
            setDeparturePaths={setDeparturePaths}
            arrivalArrowUrl={arrivalBackboneUrl}
            departureArrowUrl={departureBackboneUrl}
            flightPathAltitudeIds={flightPathAltitudeIds}
            selectedArrivalNumbers={selectedArrivalNumbers}
            selectedDepartureNumbers={selectedDepartureNumbers}
            arrivalVectoringUrl={arrivalVectoringUrl}
            setArrivalVectoring={setArrivalVectoring}
            selectedArrivalVectoringIndices={selectedArrivalVectoringIndices}
            departureVectoringUrl={departureVectoringUrl}
            setDepartureVectoring={setDepartureVectoring}
            selectedDepartureVectoringIndices={
              selectedDepartureVectoringIndices
            }
          />
        )}

        {/* Noise data layer */}
        {showNoise && noiseUrl && (
          <NoiseLayer noiseUrl={noiseUrl} setNoiseContours={setNoiseContours} />
        )}
        {/* custom components */}
        <ShowMobileFiltersButton showMobileFiltering={showMobileFiltering} />
        <Legend
          mapOption={mapOption}
          setMapOption={setMapOption}
          mapType={mapType}
          filterSelection={filterSelection}
          noiseContours={noiseContours}
          legendDescription={legendDescription}
          setLegendDescription={setLegendDescription}
          selectedNonWsiSwathes={selectedNonWsiSwathes}
          selectedNonWsiContour={selectedNonWsiContour}
          contourUrl={proposedContourUrl}
          currentVectoringUrl={currentVectoringUrl}
          proposedVectoringUrl={proposedVectoringUrl}
        />
        {showTracksPopup && (
          <TracksPopup
            handleClosePopupTracks={handleClosePopupTracks}
            selectedArrivals={selectedArrivals}
            selectedDepartures={selectedDepartures}
            setVideoOpen={setVideoOpen}
            findVideoUrl={findVideoUrl}
            mapType={mapType}
            selectedNonWsiSwathes={selectedNonWsiSwathes}
            selectedNonWsiContour={selectedNonWsiContour}
            setSelectedNonWsiContour={setSelectedNonWsiContour}
          />
        )}
        {showAddressPopup && (
          <AddressPopup
            handleClosePopup={handleCloseAddressPopup}
            longitude={lon}
            latitude={lat}
            address={address}
            noiseContours={noiseContours}
            filterSelection={filterSelection}
            setVideoOpen={setVideoOpen}
            findVideoUrl={findVideoUrl}
            noticeability={noticeability}
            mapType={mapType}
            selectedArrivalVectoring={selectedArrivalVectoring}
            selectedDepartureVectoring={selectedDepartureVectoring}
            setSelectedNonWsiSwathes={setSelectedNonWsiSwathes}
            selectedNonWsiContour={selectedNonWsiContour}
            setSelectedNonWsiContour={setSelectedNonWsiContour}
          />
        )}
        <Marker longitude={lon} latitude={lat} anchor="bottom">
          <img
            src={styledLocationIcon}
            alt="location icon"
            className="map-location-icon"
          />
        </Marker>
      </Map>
    </section>
  );
}

export default MapComponent;
