import booleanPointInPolygon from "@turf/boolean-point-in-polygon";
import { point, polygon } from "@turf/helpers";
import i18next from "../../../i18n";
import filterConfigurations from "../../../static-data/filter_data.json";
import "../../../styles/map/MapComponent.css";
import ContourMatchComponent from "./ContourMatchComponent";
import NoticeabilityComponent from "./NoticeabilityComponent";

function NoiseSearch({
  lon,
  lat,
  noiseContours,
  filterSelection,
  noticeability,
  mapType,
}) {
  // Supports five cases:
  // 1. Location under a Nabove noise contour​
  // 2. Location under a Lamax contour​ (single event)
  // 3. Location under an ANEC contour
  // 4. Location not under a noise contour, but under noticeability layer​
  // 5. Location not under a noise contour, and not under noticeability layer​

  // Get the selected noise metric
  let noiseMetricKey, noiseMetric;
  if (mapType === "eventNoise") {
    noiseMetric = i18next.t("LAmax");
  } else {
    noiseMetricKey = filterSelection.noiseMetric;
    noiseMetric = i18next.t(
      filterConfigurations.noiseMetric.options[noiseMetricKey].title
    );
  }
  const turfPoint = point([lon, lat]);

  // If there are no visible contours, return a noticeability check
  if (noiseContours === null) {
    return (
      <NoticeabilityComponent
        filterSelection={filterSelection}
        noticeability={noticeability}
        noiseMetric={noiseMetric}
        turfPoint={turfPoint}
      />
    );
  }

  // Get the last contour that overlaps with turfPoint
  let featureFound = null;
  noiseContours.features.forEach((feature) => {
    const turfPolygon = polygon(feature.geometry.coordinates);
    const isInPolygon = booleanPointInPolygon(turfPoint, turfPolygon);
    if (isInPolygon === true) {
      featureFound = feature;
    }
  });

  // If one of the contours overlaps with turfPoint, return which feature
  if (featureFound !== null) {
    return (
      <ContourMatchComponent
        mapType={mapType}
        noiseMetric={noiseMetric}
        noiseMetricKey={noiseMetricKey}
        feature={featureFound}
      />
    );
  }

  // If no matches with visible contours, return a noticeability check
  return (
    <NoticeabilityComponent
      filterSelection={filterSelection}
      noticeability={noticeability}
      noiseMetric={noiseMetric}
      turfPoint={turfPoint}
    />
  );
}

export default NoiseSearch;
