import React, { useState, useCallback } from "react";
// custom hook import
import { useSessionStorage } from "../hooks/useSessionStorage";
import useStringSessionStorage from "../hooks/useStringStorage";
import { usePdfContext } from "../hooks/provider/usePdfContext";
// component imports
import VideoModal from "../tools/VideoModal";
import Filters from "./Filters";
import TopBar from "./TopBar";
import IntroductionSlideHolder from "./introduction/IntroductionSlideHolder";
import MapComponent from "./map/MapComponent";
import "../styles/AppContainer.css";
import filterConfigurations from "../static-data/filter_data.json";
import { findSelectedTracksAndVectoringAreas } from "../tools/GeospatialAnalysis";
import PdfModal from "./popups/pdf_generator/PdfModal";

function AppContainer() {
  // To show the user the introduction slides when the user first visits the webpage
  const [showIntro, setShowIntro] = useSessionStorage("showIntro", true);
  // To show the user the same slide after page refresh (by default start with slide 1)
  const [slideSelected, setSlideSelected] = useStringSessionStorage(
    "slideSelected",
    "slide1"
  );
  // I have created this state for design purposes. The help box is displayed in two different positions 1- by itself(when accessed through filters) 2- the introduction slides.
  // when it is in the introduction slides it gets an extra arrow to navigate between the slides. This ruins the design so we are going to do conditional rendering on them.
  const [showHelpArrow, setShowHelpArrow] = useSessionStorage(
    "slide-design",
    true
  );
  // pdf generator modal
  const { showPdfModal } = usePdfContext();

  // Configure the selections based on the default values set in filter_data.json
  const [filterSelection, setFilterSelection] = useState(
    Object.keys(filterConfigurations).reduce(function (result, key) {
      result[key] = filterConfigurations[key].default;
      return result;
    }, {})
  );

  // Configure the type of map to be shown (flight, cumulativeNoise, or eventNoise)
  const [mapType, setMapType] = useState("flight");

  // this state will be used to open up the filter function of the application for mobile.
  const [mobileFiltering, showMobileFiltering] = useState(1);

  // this state is used to store the map object
  const [lat, setLat] = useState(null);
  const [lon, setLon] = useState(null);
  const [address, setAddress] = useState(null);
  const [madeSearch, setMadeSearch] = useState(false);

  // to clean up the search bar
  const [cleanSearchBar, setCleanSearchBar] = useState(false);

  // to reset the displayed info when the user changes from noise to flight and vice versa
  const [legendDescription, setLegendDescription] = useState(false);

  // for showing videos
  const [videoOpen, setVideoOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [videoAircraft, setVideoAircraft] = useState("");

  const [arrivalPaths, setArrivalPaths] = useState(null);
  const [departurePaths, setDeparturePaths] = useState(null);
  const [flightPathAltitudeIds, setFlightPathAltitudeIds] = useState([]);
  const [arrivalVectoring, setArrivalVectoring] = useState(null);
  const [departureVectoring, setDepartureVectoring] = useState(null);
  const [selectedArrivals, setSelectedArrivals] = useState([]);
  const [selectedPoint, setSelectedPoint] = useState(null);
  const [selectedNonWsiPoint, setSelectedNonWsiPoint] = useState(null);
  const [selectedNonWsiSwathes, setSelectedNonWsiSwathes] = useState([]);
  const [selectedNonWsiContour, setSelectedNonWsiContour] = useState({
    track_id: null,
    metric: "N60",
  });
  const [selectedDepartures, setSelectedDepartures] = useState([]);
  const [selectedArrivalVectoring, setSelectedArrivalVectoring] =
    useState(null);
  const [selectedDepartureVectoring, setSelectedDepartureVectoring] =
    useState(null);
  const [selectedArrivalVectoringIndices, setSelectedArrivalVectoringIndices] =
    useState(null);
  const [
    selectedDepartureVectoringIndices,
    setSelectedDepartureVectoringIndices,
  ] = useState(null);

  const openTracksPopup = useCallback(
    (lonlat, forceVectoring) => {
      if (mapType === "flight") {
        // Find the selected tracks and vectoring areas
        let [
          arrs,
          deps,
          vectorArrs,
          vectorDeps,
          vectorArrsIds,
          vectorDepsIds,
          flightPathSegmentIds,
        ] = findSelectedTracksAndVectoringAreas(
          lonlat,
          arrivalPaths,
          departurePaths,
          arrivalVectoring,
          departureVectoring
        );

        // Check if an address is selected or if it's being called by onDblClick (using forceVectoring)
        if ((forceVectoring === true) | ((lon === null) & (lat === null))) {
          // Update the selected indices
          setSelectedArrivalVectoringIndices(vectorArrsIds);
          setSelectedDepartureVectoringIndices(vectorDepsIds);
          setSelectedArrivalVectoring(vectorArrs);
          setSelectedDepartureVectoring(vectorDeps);
        }

        // Update the selected tracks
        setSelectedArrivals(arrs);
        setSelectedDepartures(deps);

        // Update the altitude labels
        setFlightPathAltitudeIds(flightPathSegmentIds);
      }
      setSelectedPoint(lonlat);
    },
    [
      lat,
      lon,
      mapType,
      arrivalPaths,
      departurePaths,
      arrivalVectoring,
      departureVectoring,
    ]
  );

  // Create an object or Map to track unique IDs
  const uniqueNonWsiTrackIds = {};

  const filteredNonWsiSwathes = selectedNonWsiSwathes
    .filter(
      (f) =>
        (lon !== null && lat !== null) |
        (selectedNonWsiPoint !== null &&
          selectedPoint[0] === selectedNonWsiPoint[0] &&
          selectedPoint[1] === selectedNonWsiPoint[1])
    )
    .filter((obj) => {
      if (!uniqueNonWsiTrackIds[obj.track_id]) {
        uniqueNonWsiTrackIds[obj.track_id] = true;
        return true;
      }
      return false;
    });

  return (
    <div className="app-container">
      <TopBar
        setLat={setLat}
        setLon={setLon}
        setAddress={setAddress}
        setMadeSearch={setMadeSearch}
        cleanSearchBar={cleanSearchBar}
        setCleanSearchBar={setCleanSearchBar}
        arrivalPaths={arrivalPaths}
        departurePaths={departurePaths}
        arrivalVectoring={arrivalVectoring}
        departureVectoring={departureVectoring}
        setSelectedArrivalVectoring={setSelectedArrivalVectoring}
        setSelectedDepartureVectoring={setSelectedDepartureVectoring}
        setSelectedArrivalVectoringIndices={setSelectedArrivalVectoringIndices}
        setSelectedDepartureVectoringIndices={
          setSelectedDepartureVectoringIndices
        }
        setFlightPathAltitudeIds={setFlightPathAltitudeIds}
      />
      <div className="map-container">
        <Filters
          // for filter configuration
          filterConfigurations={filterConfigurations}
          filterSelection={filterSelection}
          setFilterSelection={setFilterSelection}
          // for showing contours or flight paths
          mapType={mapType}
          setMapType={setMapType}
          // for mobile filtering menu
          mobileFiltering={mobileFiltering}
          showMobileFiltering={showMobileFiltering}
          // legend info
          setLegendDescription={setLegendDescription}
          // slides
          setShowIntro={setShowIntro}
          setSlideSelected={setSlideSelected}
          setShowHelpArrow={setShowHelpArrow}
          setSelectedArrivals={setSelectedArrivals}
          setSelectedDepartures={setSelectedDepartures}
          setSelectedArrivalVectoring={setSelectedArrivalVectoring}
          setSelectedDepartureVectoring={setSelectedDepartureVectoring}
          // for resetting the popup
          setSelectedNonWsiSwathes={setSelectedNonWsiSwathes}
          setSelectedNonWsiContour={setSelectedNonWsiContour}
        />
        <MapComponent
          // for setting up the map
          lat={lat}
          lon={lon}
          setLat={setLat}
          setLon={setLon}
          address={address}
          setAddress={setAddress}
          madeSearch={madeSearch}
          // for filter configuration
          filterSelection={filterSelection}
          // for showing noise contours and flight paths
          mapType={mapType}
          // for mobile filtering
          showMobileFiltering={showMobileFiltering}
          // legend info
          legendDescription={legendDescription}
          setLegendDescription={setLegendDescription}
          // videos
          setVideoOpen={setVideoOpen}
          setVideoUrl={setVideoUrl}
          setVideoAircraft={setVideoAircraft}
          // Parameters for tracks pop-up
          openTracksPopup={openTracksPopup}
          arrivalPaths={arrivalPaths}
          departurePaths={departurePaths}
          setArrivalPaths={setArrivalPaths}
          setDeparturePaths={setDeparturePaths}
          flightPathAltitudeIds={flightPathAltitudeIds}
          setFlightPathAltitudeIds={setFlightPathAltitudeIds}
          setArrivalVectoring={setArrivalVectoring}
          setDepartureVectoring={setDepartureVectoring}
          selectedArrivals={selectedArrivals}
          selectedDepartures={selectedDepartures}
          setSelectedArrivals={setSelectedArrivals}
          setSelectedDepartures={setSelectedDepartures}
          selectedArrivalVectoring={selectedArrivalVectoring}
          selectedDepartureVectoring={selectedDepartureVectoring}
          selectedArrivalVectoringIndices={selectedArrivalVectoringIndices}
          selectedDepartureVectoringIndices={selectedDepartureVectoringIndices}
          setSelectedArrivalVectoring={setSelectedArrivalVectoring}
          setSelectedDepartureVectoring={setSelectedDepartureVectoring}
          setSelectedArrivalVectoringIndices={
            setSelectedArrivalVectoringIndices
          }
          setSelectedDepartureVectoringIndices={
            setSelectedDepartureVectoringIndices
          }
          // to clean up the search bar
          setCleanSearchBar={setCleanSearchBar}
          // data for non-WSI tracks popup
          selectedNonWsiSwathes={filteredNonWsiSwathes}
          setSelectedNonWsiSwathes={setSelectedNonWsiSwathes}
          setSelectedNonWsiPoint={setSelectedNonWsiPoint}
          selectedNonWsiContour={selectedNonWsiContour}
          setSelectedNonWsiContour={setSelectedNonWsiContour}
        />

        {/* for videos */}
        {videoOpen && videoUrl && (
          <VideoModal
            videoUrl={videoUrl}
            videoAircraft={videoAircraft}
            videoOpen={videoOpen}
            setVideoOpen={setVideoOpen}
          />
        )}

        {/* Introduction Slides */}
        {showIntro && (
          <IntroductionSlideHolder
            setShowIntro={setShowIntro}
            slideSelected={slideSelected}
            setSlideSelected={setSlideSelected}
            showHelpArrow={showHelpArrow}
            setShowHelpArrow={setShowHelpArrow}
          />
        )}

        {showPdfModal && <PdfModal lat={lat} lon={lon} address={address} />}
      </div>
    </div>
  );
}

export default AppContainer;
