import React, { useState, useEffect } from "react";
import ContextProvider from "./context/ContextProvider";
// I have used a package to see if the user is using light or dark mode. More information here https://github.com/rfoel/use-prefers-color-scheme
import usePrefersColorScheme from "use-prefers-color-scheme";
// component imports
// import PrivacyNotice from "./components/PrivacyNotice";
import LandingPage from "./components/LandingPage";
import AppContainer from "./components/AppContainer";
// hook import
import { useSessionStorage } from "./hooks/useSessionStorage";
// styling imports
import "./App.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ReactGA from "react-ga4";

ReactGA.initialize(process.env.REACT_APP_GA_ID);
const queryClient = new QueryClient();

function App() {
  const [termsAccepted, setTermsAccepted] = useSessionStorage("terms", false);
  // for light and dark mode
  const prefersColorScheme = usePrefersColorScheme();
  // to pass the value to other components
  const [lightMode, setLightMode] = useState();

  useEffect(() => {
    setLightMode(prefersColorScheme === "light");
  }, [prefersColorScheme]);

  return (
    <div className={lightMode ? "App light-mode" : "App"}>
      {termsAccepted ? (
        <QueryClientProvider client={queryClient}>
          <ContextProvider>
            <AppContainer />
          </ContextProvider>
        </QueryClientProvider>
      ) : (
        <LandingPage setTermsAccepted={setTermsAccepted} />
      )}
    </div>
  );
}

export default App;
