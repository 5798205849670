import React from "react";
import { Page, Text, View } from "@react-pdf/renderer";
import { NOISE_SCENARIOS } from "../../scenarios";
import styles from "../../documentStyles";
import { NoisePageHeader } from "../sections/PageHeader";
import { NoiseTable } from "../sections/Table";
import PageFooter from "../sections/PageFooter";

const NoisePage = ({ adressInfo, noiseReport, singleNoiseReport }) => {
  return (
    <Page size="A4">
      <NoisePageHeader adressInfo={adressInfo} />
      <View style={{ ...styles.container, padding: "0px" }}>
        <View style={styles.sectionWrapper}>
          <View style={{ ...styles.section, width: "100%" }}>
            <View style={{ ...styles.sectionContent, width: "95%" }}>
              <Text style={styles.sectionSubtitleBody}>
                Single Event Noise Prediction​
              </Text>
              <Text style={styles.sectionDescription}>
                {parseInt(singleNoiseReport) > 42
                  ? NOISE_SCENARIOS.lamax.withinContour.replace(
                      "{db}",
                      singleNoiseReport
                    )
                  : NOISE_SCENARIOS.lamax.outsideContour}
              </Text>
              <Text style={styles.sectionSubtitleBody}>
                Cumulative Noise Prediction​
              </Text>
              <Text style={styles.sectionDescription}>
                Cumulative noise results have been modelled for three different
                scenarios for using the runway. ​
              </Text>
              {noiseReport.map((yearData) => (
                <View key={`noise_${yearData.year}`}>
                  <Text style={styles.tableTitle}>
                    {NOISE_SCENARIOS.year[yearData.year].sectionTitle}
                  </Text>
                  <NoiseTable
                    tableContent={yearData.results}
                    tableKey={`noise_${yearData.year}`}
                  />
                </View>
              ))}
            </View>
          </View>
        </View>
      </View>
      <PageFooter />
    </Page>
  );
};

export default NoisePage;
