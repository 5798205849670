import React from "react";
// styling imports
import arrowIcon from "../../images/icons/forward-arrow-icon.png";
import closingIcon from "../../images/Map/closing-icon.svg";
import noiseDiagramVertical from "../../images/slides/noise-level-diagram-vertical.png";
import noiseDiagramHorizontal from "../../images/slides/noise-level-diagram-horizontal.png";
import cautionIcon from "../../images/icons/caution-icon.png";
import clickIcon from "../../images/icons/click-icon.png";
import infoIcon from "../../images/icons/info-icon.svg";
import questionIcon from "../../images/icons/question-mark.png";
import volumeIcon from "../../images/icons/volume-icon.png";
import "../../styles/introduction/ToolHelpBox.css";
import { Trans } from "react-i18next";
import i18next from "../../i18n";

function ToolHelpBox({
  setSlideSelected,
  setShowIntro,
  showHelpArrow,
  setShowHelpArrow,
}) {
  return (
    <div className="helpbox-modal-wrapper">
      {showHelpArrow && (
        <img
          src={arrowIcon}
          alt="an arrow icon that facing the right"
          className="modal-left-arrow"
          onClick={() => {
            setSlideSelected("slide2");
          }}
        />
      )}
      <div className="d-flex flex-column">
        {showHelpArrow === false && (
          <div className="helpbox-closing-icon-wrapper">
            <img
              src={closingIcon}
              alt="a closing icon in the shape of the letter X."
              className="helpbox-closing-icon"
              onClick={() => {
                setShowIntro(false);
              }}
            />
          </div>
        )}
        <div className="helpbox-wrapper">
          <section className="helpbox-left">
            <div className="helpbox-left-text-wrapper">
              <img
                src={cautionIcon}
                alt="a plane icon with an curved line next to it."
                className="helpbox-icons"
              />
              <div className="helpbox-text">
                {i18next.t("helpbox.text.caution")}
              </div>
            </div>

            <div className="helpbox-left-text-wrapper">
              <img
                src={questionIcon}
                alt="a line with multiple points leading to an end location/point."
                className="helpbox-icons"
              />
              <div className="helpbox-text">
                <Trans
                  i18nKey="helpbox.text.question"
                  // eslint-disable-next-line
                  components={{ hyperlink: <a /> }}
                />
              </div>
            </div>

            <div className="helpbox-left-text-wrapper">
              <img
                src={clickIcon}
                alt="a rectangular warning sign."
                className="helpbox-icons"
              />
              <div className="helpbox-text">
                <u
                  onClick={() => {
                    setSlideSelected("slide1");
                    setShowHelpArrow(true);
                  }}
                >
                  {i18next.t("helpbox.text.relaunchIntro")}
                </u>
              </div>
            </div>

            <div className="helpbox-left-text-wrapper">
              <img
                src={infoIcon}
                alt="a circle with an i."
                className="helpbox-icons"
              />
              <div className="helpbox-text">
                <u
                  onClick={() => {
                    setSlideSelected("about");
                    setShowHelpArrow(true);
                  }}
                >
                  {i18next.t("helpbox.text.openAbout")}
                </u>
              </div>
            </div>

            <div className="helpbox-left-text-wrapper">
              <img
                src={volumeIcon}
                alt="a rectangular warning sign."
                className="helpbox-icons"
              />
              <div className="helpbox-text">
                {i18next.t("helpbox.text.noiseScale")}
              </div>
            </div>

            <div className="helpbox-left-text-wrapper">
              <img
                alt={i18next.t("helpbox.noiseScale.alt")}
                className="helpbox-noise-diagram-horizontal"
                src={noiseDiagramHorizontal}
              />
            </div>
          </section>
          <img
            alt={i18next.t("helpbox.noiseScale.alt")}
            className="helpbox-noise-diagram-vertical"
            src={noiseDiagramVertical}
          />
        </div>
      </div>
      {showHelpArrow && (
        <div className="helpbox-slide-closing-icon-wrapper">
          <img
            src={closingIcon}
            alt="a closing icon in the shape of the letter X."
            className="helpbox-closing-icon"
            onClick={() => {
              setShowIntro(false);
            }}
            style={{
              cursor: "pointer",
            }}
          />
        </div>
      )}
    </div>
  );
}

export default ToolHelpBox;
