import { useContext } from "react";
import { PdfContext } from "../../context/PdfContextProvider";

export const usePdfContext = () => {
  const context = useContext(PdfContext);
  if (!context) {
    throw new Error(
      "usePdfContext must be used within PdfContextContextProvider"
    );
  }

  return {
    showPdfModal: context[0],
    setShowPdfModal: context[1],
  };
};
