import { Oval } from "react-loader-spinner";

function Loader() {
  return (
    <div>
      <Oval
        height={40}
        width={40}
        color="#4fa94d"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel="oval-loading"
        secondaryColor="#4fa94d"
        strokeWidth={4}
        strokeWidthSecondary={4}
      />
      Crunching the numbers
    </div>
  );
}

export default Loader;
