import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import mapboxgl from "mapbox-gl";
import React, { useEffect, useRef } from "react";
import i18n from "../i18n";
import { findElectorate } from "../requests/getData";
import "../styles/SearchBar.css";
import { findSelectedTracksAndVectoringAreas } from "../tools/GeospatialAnalysis";
import AboriginalPlacesGeoJson from "../static-data/WSI_AP.geojson";

import ReactGA from "react-ga4";
import { useQuery } from "@tanstack/react-query";

function calculateSimilarity(item, query) {
  let commonCharacters = 0;

  for (let i = 0; i < Math.min(item.length, query.length); i++) {
    if (item[i] === query[i]) {
      commonCharacters++;
    }
  }

  return commonCharacters / Math.max(item.length, query.length);
}

function findPlace(e, aboriginalPlaces) {
  const query = e.toLowerCase();

  let selectedPlaces = [];

  for (let feature of aboriginalPlaces.features) {
    let placeName = feature.properties["Name"];
    if (!!feature.properties["AP Name"]) {
      placeName += ", " + feature.properties["AP Name"];
    }

    const item = feature.properties["Name"].toLowerCase();

    // Calculate whether auto complete is possible
    const placeAutoComplete = item.startsWith(e.toLowerCase());

    // Calculate whether auto complete is possible
    const placeIncludes = item.includes(query);

    // Calculate the similarity of the search term and the place name
    const placeScore =
      calculateSimilarity(item, query) + placeIncludes + placeAutoComplete;

    if (placeScore >= 1) {
      let selectedPlace = {
        type: "Feature",
        text: feature.properties["Name"],
        place_name: placeName,
        place_type: ["poi"],
        center: feature.geometry.coordinates,
        context: [],
        geometry: feature.geometry,
        properties: {
          title: feature.properties["Name"],
          score: placeScore,
        },
      };
      selectedPlaces.push(selectedPlace);
    }
  }

  // Sort the selected places
  const sortedPlaces = selectedPlaces.sort(
    (a, b) => b.properties.score - a.properties.score
  );

  return sortedPlaces;
}

const TOKEN = process.env.REACT_APP_MAP_TOKEN;

const proximity = {
  longitude: 150.730124833877,
  latitude: -33.8748525983074,
};

function SearchBar({
  setLat,
  setLon,
  setAddress,
  setMadeSearch,
  cleanSearchBar,
  setCleanSearchBar,
  arrivalPaths,
  departurePaths,
  arrivalVectoring,
  departureVectoring,
  setSelectedArrivalVectoring,
  setSelectedDepartureVectoring,
  setSelectedArrivalVectoringIndices,
  setSelectedDepartureVectoringIndices,
  setFlightPathAltitudeIds,
}) {
  const searchBarRef = useRef(null);
  const geocoderRef = useRef(null);

  const { data: aboriginalPlaces } = useQuery(["AboriginalPlacesGeoJson"], () =>
    fetch(AboriginalPlacesGeoJson).then((res) => res.json())
  );

  useEffect(() => {
    const findTracksAndVectoringAreas = (lonlat) => {
      // Find the selected tracks and vectoring areas
      let [
        ,
        ,
        vectorArrs,
        vectorDeps,
        vectorArrsIds,
        vectorDepsIds,
        flightPathSegmentIds,
      ] = findSelectedTracksAndVectoringAreas(
        lonlat,
        arrivalPaths,
        departurePaths,
        arrivalVectoring,
        departureVectoring,
        true
      );

      // Update the selected indices
      setSelectedArrivalVectoringIndices(vectorArrsIds);
      setSelectedDepartureVectoringIndices(vectorDepsIds);
      setSelectedArrivalVectoring(vectorArrs);
      setSelectedDepartureVectoring(vectorDeps);

      // Update the altitude labels
      setFlightPathAltitudeIds(flightPathSegmentIds);
    };

    const geocoder = new MapboxGeocoder({
      accessToken: TOKEN,
      mapboxgl: mapboxgl,
      container: searchBarRef,
      countries: "au",
      proximity: proximity,
      placeholder: i18n.t(
        "Type here or double-click on the map to select an address"
      ),
      localGeocoder: (e) => findPlace(e, aboriginalPlaces || []),
      localGeocoderOnly: false,
    });

    geocoder.on("result", (event) => {
      const lon = event.result.center[0];
      const lat = event.result.center[1];

      setLon(lon);
      setLat(lat);

      // Find and select the applicable tracks and vectoring areas
      findTracksAndVectoringAreas(event.result.center);

      // Set the state with the selected address
      setAddress({
        type: event.result.place_type[0],
        name: event.result.text,
        address: event.result.place_name,
      });

      setMadeSearch(true);

      // Get the postal code
      let postcode;

      if (event.result.place_type.includes("postcode")) {
        postcode = event.result.text;
      } else {
        // Find the postcode
        let obj = event.result.context.find((o) => o.id.startsWith("postcode"));

        if (obj !== undefined) {
          // Set the postcode
          postcode = obj.text;
        }
      }

      // Get the electorate
      findElectorate(lon, lat).then(function (electorate) {
        // Send search event to Google Analytics
        ReactGA.event("select_address", {
          postcode: postcode,
          electorate: electorate,
        });
      });
    });

    geocoder.on("clear", (event) => {
      setLat(null);
      setLon(null);
      setAddress(null);
    });

    // Update the DOM
    if (searchBarRef.current.firstChild) {
      searchBarRef.current.replaceChild(
        geocoder.onAdd(),
        searchBarRef.current.firstChild
      );
    } else {
      searchBarRef.current.appendChild(geocoder.onAdd());
    }

    geocoderRef.current = geocoder;

    // Allow other elements to clear the search bar
    if (cleanSearchBar === true) {
      geocoderRef.current.clear();
      setCleanSearchBar(null);
    }
  }, [
    aboriginalPlaces,
    setLat,
    setLon,
    setMadeSearch,
    setAddress,
    cleanSearchBar,
    setCleanSearchBar,
    arrivalPaths,
    departurePaths,
    arrivalVectoring,
    departureVectoring,
    setSelectedArrivalVectoring,
    setSelectedDepartureVectoring,
    setSelectedArrivalVectoringIndices,
    setSelectedDepartureVectoringIndices,
    setFlightPathAltitudeIds,
  ]);

  return (
    <div className="search-input-wrapper">
      <div ref={searchBarRef} className="search-input" />
    </div>
  );
}

export default SearchBar;
