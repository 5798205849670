import React from "react";
import {
  trackCombinations,
  noticeability_url,
} from "../../../requests/register";
// styling imports
import "../../../styles/popups/PdfModal.css";
import { cumulativeNoiseCombinations } from "../../../requests/register";
import getData, { getNoticeability } from "../../../requests/getData";
import { useQueries, useQuery } from "@tanstack/react-query";
import PdfDataProcessing from "./PdfDataProcessing";

function PdfModal({ lat, lon, address }) {
  const noiseScenarios = Object.entries(cumulativeNoiseCombinations).flatMap(
    ([scenarioKey, metrics]) =>
      Object.entries(metrics).map(([metricKey, url]) => {
        return [`${scenarioKey}_${metricKey}`, url];
      })
  );
  const noiseQueries = useQueries({
    queries: noiseScenarios.map((noiseScenario) => {
      return {
        queryKey: ["noise", noiseScenario[1]],
        queryFn: async () => {
          const data = await getData(noiseScenario[1]);
          return data;
        },
        cacheTime: Infinity,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
      };
    }),
  });
  const noticeabilityLayer = useQuery({
    queryKey: ["noticeability", noticeability_url],
    queryFn: async () => {
      const data = await getNoticeability();
      return data;
    },
    cacheTime: "Infinity",
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    placeholderData: {
      type: "FeatureCollection",
      features: [],
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const trackScenarios = Object.entries(trackCombinations).flatMap(
    ([scenarioKey, values]) =>
      Object.entries(values)
        .filter(([value]) => value === "arrivals" || value === "departures")
        .map(([value, url]) => {
          return [`${scenarioKey}_${value}`, url];
        })
  );
  const trackQueries = useQueries({
    queries: trackScenarios.map((trackScenario) => {
      return {
        queryKey: ["tracks", trackScenario[1]],
        queryFn: async () => {
          const data = await getData(trackScenario[1]);
          return data;
        },
        cacheTime: Infinity,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
      };
    }),
  });

  if (
    noiseQueries.some((obj) => obj.isLoading === true) ||
    trackQueries.some((obj) => obj.isLoading === true) ||
    noticeabilityLayer.isLoading === true
  ) {
    return <></>;
  }

  return (
    <PdfDataProcessing
      trackQueries={trackQueries}
      trackScenarios={trackScenarios}
      noiseQueries={noiseQueries}
      noiseScenarios={noiseScenarios}
      noticeabilityLayer={noticeabilityLayer}
      lat={lat}
      lon={lon}
      address={address}
    />
  );
}

export default PdfModal;
