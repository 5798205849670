export function HighlightTrackButton({
  trackId,
  trackName,
  selectedNonWsiContour,
  setSelectedNonWsiContour,
}) {
  // Get the selected contour
  const contourId = selectedNonWsiContour["track_id"];

  return (
    <button
      className={
        trackId === contourId
          ? "popup-flight-btn popup-flight-btn-selected"
          : "popup-flight-btn"
      }
      onClick={() => {
        setSelectedNonWsiContour({
          ...selectedNonWsiContour,
          track_id: trackId === contourId ? null : trackId,
        });
      }}
    >
      <div className="w-100">{trackName}</div>
    </button>
  );
}
