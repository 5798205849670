import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import English from "./static-data/translations/english.json";
import Hindi from "./static-data/translations/hindi.json";
import Tagalog from "./static-data/translations/tagalog.json";
import Vietnamese from "./static-data/translations/vietnamese.json";
import Chinese from "./static-data/translations/chinese.json";
import Arabic from "./static-data/translations/arabic.json";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
// I am going design this in a way that it will go through the components filter from top to bottom. In this case the map/Filter will be the first.
const resources = {
  en: English,
  hi: Hindi,
  tl: Tagalog,
  vi: Vietnamese,
  zh: Chinese,
  ar: Arabic,
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    // lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    nonExplicitSupportedLngs: true,
    supportedLngs: ["en", "hi", "tl", "vi", "zh", "ar"],
  });

export default i18n;
