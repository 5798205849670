export function getUniqueTracks(objects) {
  const uniqueTracks = {};
  for (const obj of objects) {
    uniqueTracks[obj.track_id] ={
      id: obj.track_id,
      name: obj["Reference Name"],
    };
  }
  return uniqueTracks;
}
