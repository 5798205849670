import React from "react";
import { Document } from "@react-pdf/renderer";
import NoisePage from "./pdf_components/pages/NoisePage";
import TracksPage from "./pdf_components/pages/TracksPage";
import GlossaryPage from "./pdf_components/pages/GlossaryPage";
import styles from "./documentStyles";

const PDFDocument = ({
  adressInfo,
  completeTracksReport,
  noiseReport,
  singleNoiseReport,
}) => {
  return (
    <Document style={styles.container}>
      <TracksPage
        adressInfo={adressInfo}
        completeTracksReport={completeTracksReport}
      />
      <NoisePage
        adressInfo={adressInfo}
        noiseReport={noiseReport}
        singleNoiseReport={singleNoiseReport}
      />
      <GlossaryPage />
    </Document>
  );
};

export default PDFDocument;
