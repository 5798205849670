import { Source, Layer } from "react-map-gl";
import getData from "../../../requests/getData";
import { runway_color } from "../../../colors";

import { useQuery } from "@tanstack/react-query";
import { runway_label_url } from "../../../requests/register";

function RunwayLabelLayer() {
  const { isError, data, error } = useQuery({
    queryKey: ["runwayLabels"],
    queryFn: async () => {
      const data = await getData(runway_label_url);
      return data;
    },
    cacheTime: "Infinity",
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    placeholderData: {
      type: "FeatureCollection",
      features: [],
    },
  });

  if (isError) {
    console.error(error);
    return <></>;
  }

  return (
    <Source id="runway-label-data" type="geojson" data={data}>
      <Layer
        id="runway-label-z1-layer"
        type="line"
        paint={{
          "line-width": 1,
          "line-color": runway_color,
        }}
        minzoom={10.5}
        maxzoom={11.5}
      />
      <Layer
        id="runway-label-z2-layer"
        type="line"
        paint={{
          "line-width": 2,
          "line-color": runway_color,
        }}
        minzoom={11.5}
      />
    </Source>
  );
}

export default RunwayLabelLayer;
