import React, { useEffect, useState } from "react";
// component imports
import LanguageOptions from "./LanguageOptions";
import SearchBar from "./SearchBar";
// styling imports
import "../styles/TopBar.css";
import stackedLogo from "../images/logos/stacked-no-background.png";
import whiteLogo from "../images/logos/DoITRDCA_Inline_reversed.png";
import dropdownIcon from "../images/Topbar/dropdown-icon.svg";
import { RiArrowDropDownLine } from "react-icons/ri";
import i18next from "i18next";

function TopBar({
  setLat,
  setLon,
  setAddress,
  setMadeSearch,
  cleanSearchBar,
  setCleanSearchBar,
  arrivalPaths,
  departurePaths,
  arrivalVectoring,
  departureVectoring,
  setSelectedArrivalVectoring,
  setSelectedDepartureVectoring,
  setSelectedArrivalVectoringIndices,
  setSelectedDepartureVectoringIndices,
  setFlightPathAltitudeIds,
}) {
  const [openLangOptions, setOpenLangOptions] = useState(false);
  const [languageMenu, setLanguageMenu] = useState("");

  useEffect(() => {
    if (languageMenu === "closed") {
      setLanguageMenu("");
    }
  }, [openLangOptions, languageMenu]);
  return (
    <header id="top-bar">
      <nav className="top-navbar">
        <div className="top-logo-wrapper">
          <a
            href="https://www.wsiflightpaths.gov.au"
            target="_blank"
            rel="noreferrer"
            className="top-bar-link"
          >
            <img
              src={whiteLogo}
              id="top-bar-government-logo"
              alt="Australia Department of Infrastructure, Transport, Regional Development, Communications and the Arts government logo."
            />
            <img
              src={stackedLogo}
              alt="Australia Department of Infrastructure, Transport, Regional Development, Communications and the Arts government logo."
              className="stacked-logo"
            />
          </a>
        </div>
        <div className="search-bar-wrapper">
          <SearchBar
            setLat={setLat}
            setLon={setLon}
            setAddress={setAddress}
            setMadeSearch={setMadeSearch}
            cleanSearchBar={cleanSearchBar}
            setCleanSearchBar={setCleanSearchBar}
            arrivalPaths={arrivalPaths}
            departurePaths={departurePaths}
            arrivalVectoring={arrivalVectoring}
            departureVectoring={departureVectoring}
            setSelectedArrivalVectoring={setSelectedArrivalVectoring}
            setSelectedDepartureVectoring={setSelectedDepartureVectoring}
            setSelectedArrivalVectoringIndices={
              setSelectedArrivalVectoringIndices
            }
            setSelectedDepartureVectoringIndices={
              setSelectedDepartureVectoringIndices
            }
            setFlightPathAltitudeIds={setFlightPathAltitudeIds}
          />
        </div>
        <div className="top-bar-button-wrapper">
          <a
            href={i18next.t("navbar.button.url")}
            target="_blank"
            rel="noopener noreferrer"
          >
            <button> {i18next.t("navbar.button.text")} </button>
          </a>
        </div>
        <span
          id="top-bar-menu"
          onClick={() => {
            if (languageMenu === "") {
              setOpenLangOptions(!openLangOptions);
            } else if (languageMenu === "closed") {
              setOpenLangOptions(false);
            }
          }}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              setOpenLangOptions(!openLangOptions);
            }
          }}
          style={{ cursor: "pointer" }}
        >
          <RiArrowDropDownLine size={40} className="desktop-dropdown-logo" />
          <img
            src={dropdownIcon}
            alt="an icon shaped as a downward looking arrow."
            className="mobile-dropdown-logo"
          />
          <div id="top-bar-language-option">{i18next.t("language.id")}</div>
          <div id="topbar-desktop-lang-opt">
            {i18next.t("language.name")} ({i18next.t("language.id")})
          </div>
        </span>
      </nav>
      {openLangOptions === true && (
        <LanguageOptions
          setOpenLangOptions={setOpenLangOptions}
          openLangOptions={openLangOptions}
          setLanguageMenu={setLanguageMenu}
        />
      )}
    </header>
  );
}

export default TopBar;
