import React from "react";
import version_string from "../version";
import ReactGA from "react-ga4";
// data import
import landingPageText from "../static-data/landing_page_text.json";
// styling imports
import govLogo from "../images/LandingPage/landing-page-gov-logo.png";
import featureIcon from "../images/LandingPage/featureIcon.png";
import limitationsIcon from "../images/LandingPage/limitationsIcon.png";
import ObjectivesIcon from "../images/LandingPage/objectivesIcon.png";
import departureIcon from "../images/icons/departure.png";
import "../styles/LandingPage.css";
import i18next from "../i18n";
import { Legal } from "./Legal";

// going to use abbreviation for some words for the sake of shorter class names
// landing-page = lp
function LandingPage({ setTermsAccepted }) {
  // to show the related image with the right title
  const renderIcon = (title) => {
    switch (title) {
      case "landing.features.title":
        return (
          <img
            src={featureIcon}
            alt="a plane icon with an curved line next to it."
            className="lp-title-icon"
          />
        );
      case "landing.objectives.title":
        return (
          <img
            src={ObjectivesIcon}
            alt="a rectangular warning sign."
            className="lp-title-icon"
          />
        );
      case "landing.limitations.title":
        return (
          <img
            src={limitationsIcon}
            alt="a line with multiple points leading to an end location/point."
            className="lp-title-icon"
          />
        );
      default:
        return null;
    }
  };
  return (
    <div className="landing-page-wrapper">
      <div className="landing-page-gov-logo-wrapper">
        <img
          src={govLogo}
          alt="the logo of the Australian infrastructure, transport, regional development communications and the arts department"
          className="landing-page-gov-logo"
        />
      </div>
      <section className="d-flex flex-column justify-content-center align-items-center">
        <h4 className="lp-title">{i18next.t("landing.title")}</h4>
        <b className="lp-title-bold-text">{i18next.t("landing.subtitle")}</b>
      </section>
      <section className="lp-middle-section">
        {landingPageText.map((object, index) => {
          return (
            <div className="lp-middle-wrapper" key={index}>
              <div className="d-flex flex-column justify-content-center align-items-center mt-4">
                {renderIcon(object.title)}
                <h3 className="lp-middle-title">{i18next.t(object.title)}</h3>
              </div>
              <div className="lp-middle-text-wrapper">
                {object.body.map((text, index) => {
                  return (
                    <div className="lp-middle-text" key={index}>
                      {i18next.t(text.text)}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </section>
      <section className="lp-end-wrapper">
        <div className="lp-legal legal">
          <Legal />
        </div>
        <button
          className="lp-end-button"
          onClick={() => {
            setTermsAccepted(true);
            ReactGA.event("accept_terms_of_service", {
              git_sha: version_string,
            });
          }}
        >
          <b className="lp-end-button-text">{i18next.t("landing.accept")}</b>
          <img
            src={departureIcon}
            alt="an icon of a flight taking off."
            className="lp-button-icon"
          />
        </button>
      </section>
    </div>
  );
}

export default LandingPage;
