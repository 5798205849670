import booleanPointInPolygon from "@turf/boolean-point-in-polygon";
import { point, polygon } from "@turf/helpers";

const findSelectedTracks = (tracks, turfPoint) => {
  let selected = {};

  tracks.features.forEach((feature, index) => {
    const turfPolygon = polygon(feature.geometry.coordinates);
    const isInPolygon = booleanPointInPolygon(turfPoint, turfPolygon);
    if (isInPolygon === true) {
      selected[feature.properties.subtrack_id] =
        feature.properties.reference_name;
    }
  });
  return selected;
};

const addSelectedTracksFromVectoringAreas = (tracks, trackIds, vector) => {
  if (trackIds === null) {
    return vector;
  }
  tracks.features.forEach((feature, index) => {
    if (trackIds.indexOf(feature.properties.subtrack_id.toString()) > -1) {
      vector[feature.properties.subtrack_id] =
        feature.properties.reference_name;
    }
  });
  return vector;
};

const findSelectedVectoringAreas = (selectedTracks, areas, turfPoint) => {
  let features = [];
  let features_subtrack_ids = [];

  if (areas === null) {
    return [null, []];
  }

  areas.features.forEach((feature, index) => {
    const turfPolygon = polygon(feature.geometry.coordinates);
    const isInPolygon = booleanPointInPolygon(turfPoint, turfPolygon);

    if (selectedTracks.indexOf(feature.properties.subtrack_id) > -1) {
      features.push(feature);
    } else if (isInPolygon === true) {
      features.push(feature);
      features_subtrack_ids.push(feature.properties.subtrack_id);
    }
  });

  if (features.length === 0) {
    return [null, []];
  }

  return [
    {
      crs: areas.crs,
      type: areas.type,
      features: features,
    },
    features_subtrack_ids,
  ];
};

const findSelectedTracksAndVectoringAreas = (
  lonlat,
  arrivalPaths,
  departurePaths,
  arrivalVectoring,
  departureVectoring
) => {
  // Create a turfpoint for selected tracks
  const turfPoint = point([lonlat[0], lonlat[1]]);

  // Find selected tracks
  let arrs = findSelectedTracks(arrivalPaths, turfPoint);
  let deps = findSelectedTracks(departurePaths, turfPoint);

  let vectorArrsIds = null;
  let vectorDepsIds = null;

  // Find vectoring areas and add new selected tracks if a vectoring area from a non-selected
  // track is clicked
  let results = findSelectedVectoringAreas(
    Object.keys(arrs),
    arrivalVectoring,
    turfPoint
  );
  const vectorArrs = results[0];
  const newIdsArrs = results[1];
  arrs = addSelectedTracksFromVectoringAreas(arrivalPaths, newIdsArrs, arrs);

  results = findSelectedVectoringAreas(
    Object.keys(deps),
    departureVectoring,
    turfPoint
  );
  const vectorDeps = results[0];
  const newIdsDeps = results[1];
  deps = addSelectedTracksFromVectoringAreas(departurePaths, newIdsDeps, deps);

  if (vectorArrs) {
    vectorArrsIds = vectorArrs.features.map((f) => f.properties.index);
  }
  if (vectorDeps) {
    vectorDepsIds = vectorDeps.features.map((f) => f.properties.index);
  }

  let flightPathSegmentIds = Object.entries(arrs)
  .concat(Object.entries(deps))
  .map(([key]) => {
    return key;
  });

  return [arrs, deps, vectorArrs, vectorDeps, vectorArrsIds, vectorDepsIds, flightPathSegmentIds];
};

export {
  addSelectedTracksFromVectoringAreas,
  findSelectedTracks,
  findSelectedTracksAndVectoringAreas,
  findSelectedVectoringAreas,
};
